import { consentTypes } from "../Common/CommonHelper";




export class ConsentPickerHelper {

    /**
     * 
     * @param {*} consentType 
     * @param {*} freezeButton 
     * @param {*} workingArea 
     * @returns 
     */
    static approvedButton(consentType, freezeButton, workingArea) {

        let retVal = false;  // I'm not lucky

        // avoid double click 
        if (freezeButton) return retVal;

        const { access, dedicatedAccess } = workingArea;
        /**
         * B/L appove button 2nd step
         */
        switch (consentType) {

            case consentTypes.DEDICATED:

                if (dedicatedAccess.accounts.length === 0 && dedicatedAccess.balances.length === 0 && dedicatedAccess.transactions.length === 0)
                    retVal = false;
                else
                    retVal = true;
                break;
            case consentTypes.AVAILABLE_ACCOUNTS_WITH_BALANCE:
                retVal = true;
                break;
            case consentTypes.BANK_OFFER:
                if (access.accounts === false && access.balances === false && access.transactions === false)
                    retVal = false;
                else
                    retVal = true;
                break;
            case consentTypes.AVAILABLE_ACCOUNTS:
                retVal = true;
                break;

            default:
                break;
        }


        return retVal;
    }


    /**
     * 
     * TODAY
     * @returns 
     */
    static minConsentCalendarDate() {

        // return new Date(new Date().setFullYear(
        //     new Date().getFullYear() + 1)).toISOString().split('T')[0];

        return new Date(new Date().setMonth(
            new Date().getMonth() + 1)).toISOString().split('T')[0];
    }


    /**
     * 
     * + 1 year
     * @returns 
     */
    static maxConsentCalendarDate() {

        return new Date(new Date().setFullYear(new Date().getFullYear() + 3)).toISOString().split('T')[0];
    }

}