/*
 * upload and validate certificates
 * upload and validate certificates
 *
 * OpenAPI spec version: 1.0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The CertificateItem model module.
 * @module model/CertificateItem
 * @version 1.0.3
 */
export class CertificateItem {
  /**
   * Constructs a new <code>CertificateItem</code>.
   * @alias module:model/CertificateItem
   * @class
   * @param typeCert {module:model/CertificateItem.TypeCertEnum} hashCertificate of certificate
   * @param stream {String} upload stream of the certificate
   */
  constructor(typeCert, stream) {
    this.typeCert = typeCert;
    this.stream = stream;
  }

  /**
   * Constructs a <code>CertificateItem</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CertificateItem} obj Optional instance to populate.
   * @return {module:model/CertificateItem} The populated <code>CertificateItem</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CertificateItem();
      if (data.hasOwnProperty('typeCert'))
        obj.typeCert = ApiClient.convertToType(data['typeCert'], 'String');
      if (data.hasOwnProperty('stream'))
        obj.stream = ApiClient.convertToType(data['stream'], 'String');
    }
    return obj;
  }
}

/**
 * Allowed values for the <code>typeCert</code> property.
 * @enum {String}
 * @readonly
 */
CertificateItem.TypeCertEnum = {
  /**
   * value: "QSEAL_PUBLIC"
   * @const
   */
  QSEAL_PUBLIC: "QSEAL_PUBLIC",

  /**
   * value: "QSEAL_PRIVATE"
   * @const
   */
  QSEAL_PRIVATE: "QSEAL_PRIVATE",

  /**
   * value: "QWAC_PUBLIC"
   * @const
   */
  QWAC_PUBLIC: "QWAC_PUBLIC",

  /**
   * value: "QWAC_PRIVATE"
   * @const
   */
  QWAC_PRIVATE: "QWAC_PRIVATE"
};
/**
 * hashCertificate of certificate
 * @member {module:model/CertificateItem.TypeCertEnum} typeCert
 */
CertificateItem.prototype.typeCert = undefined;

/**
 * upload stream of the certificate
 * @member {String} stream
 */
CertificateItem.prototype.stream = undefined;

