import React from 'react';
import PropTypes from "prop-types"
import { RowContainer } from "./Row.style"

const Row = ({
    children,
    alignContent = "flex-start",
    alignItems = "center",
    justifyContent = "space-around",
    width = "100%",
    height,
    marginTop = "0px",
    marginBottom = "0px",
    backgroundColor = "transparent",
    padding
}) => {

    return (
        <RowContainer
            alignContent={alignContent}
            alignItems={alignItems}
            justifyContent={justifyContent}
            width={width}
            height={height}
            backgroundColor={backgroundColor}
            marginTop={marginTop}
            marginBottom={marginBottom}
            padding={padding}
        >
            {children}
        </RowContainer>
    )
}

Row.propTypes = {
    alignContent: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'space-between', 'space-around', 'stretch']),
    alignItems: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'stretch', 'baseline']),
    width: PropTypes.string,
    height: PropTypes.string,
    children: PropTypes.node.isRequired,
    justifyContent: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'space-between', 'space-around', 'space-evenly']),
    backgroundColor: PropTypes.string,
    marginTop: PropTypes.string,
    marginBottom: PropTypes.string,
    padding: PropTypes.string
}

export default Row