import React from 'react';

import {SelectOptionStyle} from './SelectOption.Style'

const SelectOption = props => {
    const options = props.options.map(option => {
        return <option key={option.value} value={option.value}>{option.name}</option>
    });
    
    return (
            <SelectOptionStyle direction={props.direction} onChange={props.change}>
                <option key={0} value={props.defaultValue}>{props.defaultTitle}</option>
                {options}
            </SelectOptionStyle>
    );
};

export default SelectOption;