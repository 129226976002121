import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AuthService } from '../../services/auth/AuthService';
import { MINUTES_FOR_AUTO_SIGNOUT } from '../../resources/constants/EnvironmentVariables';

export const signOut = createAsyncThunk(
  'loggedUser/signOut', async () => {
    const authService = new AuthService({ env: process.env.NODE_ENV });
    try {
      return await authService.signOut()
    }
    catch (err) {
      return err.message
    }
  });

export const signIn = createAsyncThunk(
  'loggedUser/signIn', async (payload) => {
    const authService = new AuthService({ env: process.env.NODE_ENV });
    try {
      const response = await authService.signIn(payload.signInForm);
      if (response.attributes) {
        payload.linkToFirstPage();
        const email = response.attributes.email;
        const expiry = createTs(response);
        payload.doGetTheme();
        return { email, expiry };
      }
      else {
        payload.AlertError();
        payload.unFreeze();
      }
    }
    catch (err) {
      return err.message
    }
  });

const createTs = (response, session = false) => {

  if (session) {
    return response.accessToken.payload.exp * 1000;

  }
  return response.signInUserSession.accessToken.payload.exp * 1000;
}
const getAutoExpiry = () => {

  return new Date().setMinutes(new Date().getMinutes() + MINUTES_FOR_AUTO_SIGNOUT);
}

/**
 * Initializing Cognito expire + 5 min 
 */
export const signOutOrRefresh = createAsyncThunk(
  'loggedUser/signOutOrRefresh', async (payload) => {
    const authService = new AuthService({ env: process.env.NODE_ENV });
    try {
      const response = await authService.currentSession();

      if (response) {
        const expiry = createTs(response, true);// Cognito expiry
        const autoLogoutexpiry = getAutoExpiry();  // + 5 min to Cognito expiry

        return { expiry, autoLogoutexpiry };
      } else {
        return { error: "Error" };
      }

    }
    catch (err) {
      return err.message
    }
  });

const initialState = {
  user: '',
  error: '',
  expiry: '',
  autoLogoutexpiry: '',
  refreshFailed: false,
  isLoading: false,
}


const loggedUser = createSlice({
  name: 'loggedUser',
  initialState: initialState,
  reducers: {
    setUser: (state, action) => { state.user = action.payload; },
    setExpired: (state, action) => { state = { ...initialState }; },


  },
  extraReducers: {
    [signOut.pending]: (state) => {
      state.isLoading = true;
    },
    [signOut.fulfilled]: (state, action) => {
      // The service is not returning anything, so action.payload is undefined
      state.user = '';
      state.isLoading = false;
      state.expiry = '';
      state.autoLogoutexpiry = '';
    },
    [signOut.rejected]: (state) => {
      state.isLoading = false;
    },

    [signIn.pending]: (state) => {
      state.isLoading = true;
    },
    [signIn.fulfilled]: (state, action) => {
      state.user = action.payload.email;
      state.expiry = action.payload.expiry;
      state.isLoading = false;
    },
    [signIn.rejected]: (state) => {
      state.isLoading = false;
    },

    [signOutOrRefresh.pending]: (state) => {
      state.isLoading = true;
    },
    [signOutOrRefresh.fulfilled]: (state, action) => {
      state.expiry = action.payload.expiry;
      state.isLoading = false;
      state.refreshFailed = false;
      state.autoLogoutexpiry = action.payload.autoLogoutexpiry;

    },
    [signOutOrRefresh.rejected]: (state) => {
      state.isLoading = false;
      state.refreshFailed = true;
    },

  }
});

export const { setUser } = loggedUser.actions;

export default loggedUser.reducer;