import Domains from './Routes/stage-variables.json';
import Timeouts from './Routes/utils-variables.json';

const isProduction = process.env.NODE_ENV === 'production';
const API_URL = isProduction ? Domains['PROD_SERVER'] : Domains['DEV_SERVER'];

const MINUTES_FOR_AUTO_SIGNOUT = 15;
const SECONDS_FOR_POPUP_BEFORE_AUTO_SIGNOUT = 60;

const ENV = process.env.NODE_ENV === 'production' ? process.env.NODE_ENV : process.env.NODE_ENV;

const REST_TIMEOUT = isProduction ? Timeouts['PROD_TIMEOUT'] : Timeouts['DEV_TIMEOUT']
const EC2_ENV = false;

const validateCertificatePublicFormat = ["-----BEGIN CERTIFICATE-----", "-----END CERTIFICATE-----\n"]
const validateCertificatePrivateFormat = ["-----BEGIN PRIVATE KEY-----", "-----END PRIVATE KEY-----\n"]


const MAX_LOGO_SIZE = 1000000;
const LOGO_SIZE_ALERT = "Logo size should be less than 1MB";

// white label : accomplish consent page ( get pre-requisite cookies : psuID, fintechId, redirect suffix URL  )
const CONSENT_COOKIE_TTL = (15 * 60 * 1000); // 15 minutes

// SIMULATE ( UNITEST ) environments 
const TESTER_DATA = [
    {
        name: "EC2",
        tls: '',
        //url: "http://34.245.156.115",
        url: "http://ec2-34-245-156-115.eu-west-1.compute.amazonaws.com",
    },
    {
        name: "LOCALHOST",
        tls: '',
        //url: "http://34.245.156.115",
        url: "http://localhost:8080",
    },
    {
        name: "Amplify-DEV 29/2 14:08",
        tls: "amplify-dev",
        url: "https://dev-api.obhub-matrix-dev.link/tpp-api"
    },
    {
        name: "Amplify-QA",
        tls: "amplify-dev",
        url: "https://dev-api.obhub-matrix-dev.link/tpp-api"
    },
    {
        name: "Amplify-PROD",
        tls: "amplify-dev",
        url: "https://dev-api.obhub-matrix-dev.link/tpp-api"
    },
]

export {
    API_URL,
    ENV,
    REST_TIMEOUT,
    EC2_ENV,
    validateCertificatePublicFormat,
    validateCertificatePrivateFormat,
    SECONDS_FOR_POPUP_BEFORE_AUTO_SIGNOUT,
    MINUTES_FOR_AUTO_SIGNOUT,
    MAX_LOGO_SIZE,
    LOGO_SIZE_ALERT,
    CONSENT_COOKIE_TTL,
    TESTER_DATA
};