import React from 'react';
import PropTypes from 'prop-types';

const InputValidationInfo = props => {
    return (
        <div style={{fontSize:"10px",margin:"2px 0px", color: props.isValid ? "lightgrey": '#00aaff'}}>
                {props.text}
        </div>
    );
};

InputValidationInfo.propTypes = {
    isValid: PropTypes.bool,
    text: PropTypes.string
};

export default InputValidationInfo;