import { Auth, API } from 'aws-amplify'
import RestApi from "../../utils/libs/RestApi";
import { API_URL, REST_TIMEOUT, ENV, EC2_ENV } from "../../resources/constants/EnvironmentVariables.js";
import adminRoutes from "../../resources/constants/Routes/api-routes-fintech-admin.json"
import BaseServices from "../BaseServices";
import { ErrorDetailsResponse } from "../../swagger/codeGen/upload-validate-certificate-by-type/src/index.js";
import Uuid from "../../utils/libs/Uuid";
import { FintechUserCreateRequest, FintechUserUpdateRequest } from '../../swagger/codeGen/fintech-admin-hub/src';


export class AuthService extends BaseServices {

    constructor({ env }) {
        super(env);
        this.restApi = new RestApi({ env: ENV, timeout: REST_TIMEOUT });
        this.cognitoApiRoutePrefix = "/monoapp";
        this.lambdaName = "app";
    }

    /**
     * 
     * @param {*} userObj 
     * @returns 
     */
    async signIn(userObj) {
        const logFunc = `${this.serviceName} signIn: `;
        const startTime = new Date();
        this.log.debug(logFunc, 'start');

        try {
            const { userMail, password } = userObj;

            // validate request
            const retVal = this.#authValidation(userObj);
            if (retVal instanceof ErrorDetailsResponse) return retVal;
            const user = await Auth.signIn(userMail, password);
            sessionStorage.setItem("Start", new Date().toISOString());
            sessionStorage.setItem("OBH user", user.attributes.email);

            this.log.debug(logFunc, 'end');
            this.log.audit(logFunc, startTime);
            return user;

        } catch (error) {
            return this.#generalError(logFunc, error);
        }
    }

    async signOut() {
        const logFunc = `${this.serviceName} signOut: `;
        const startTime = new Date();
        this.log.debug(logFunc, 'start');
        try {
            const user = await Auth.signOut();
            this.log.debug(logFunc, 'end');
            this.log.audit(logFunc, startTime);
            sessionStorage.removeItem("OBH user");
            sessionStorage.removeItem("Start");
            return user;

        } catch (error) {
            return this.#generalError(logFunc, error);
        }
    }

    /**
     * 
     * @returns 
     */
    async getUsersList(fintechId) {
        const logFunc = `${this.serviceName} getUsersList: `;
        const startTime = new Date();
        this.log.debug(logFunc, 'start');

        try {
            const templateUrl = adminRoutes.get.get_users_list;
            const parameters = { "domain-server": API_URL };
            const transactionId = Uuid.uuid();

            let users;
            if (EC2_ENV) {
                users = await this.restApi.get(templateUrl, parameters, { transactionId, fintechId });

            } else {
                const url = templateUrl.replace('#domain-server#', this.cognitoApiRoutePrefix);


                users = await API.get(this.lambdaName, url,
                    {
                        response: true,
                        headers: {
                            "Accept": "application/json", "Content-Type": "application/json",
                            'X-Transaction-Id': transactionId, 'X-Fintech-Id': fintechId
                        }
                    });
            }

            this.log.debug(logFunc, 'end');
            this.log.audit(logFunc, startTime);
            return users;

        } catch (error) {
            return this.#generalError(logFunc, error);
        }
    }

    /**
    * 
    * @returns 
    */
    async updateUser({ transactionId, fintechId }, { userName, userItem }) {
        const logFunc = `${this.serviceName} UpdateUser: `;
        const startTime = new Date();
        this.log.debug(logFunc, 'start');
        try {
            const templateUrl = adminRoutes.put.update_user;
            const parameters = { "domain-server": API_URL };

            const request = new FintechUserUpdateRequest(userName, userItem);
            let userUpdateResponse = await this.restApi.put(templateUrl, parameters, request, { transactionId, fintechId });

            if (EC2_ENV) {
                userUpdateResponse = await this.restApi.put(templateUrl, parameters, request, { transactionId, fintechId });

            } else {
                const url = templateUrl.replace('#domain-server#', this.cognitoApiRoutePrefix);


                userUpdateResponse = await API.put(this.lambdaName, url,
                    {
                        response: true,
                        headers: {
                            "Accept": "application/json", "Content-Type": "application/json",
                            'X-Transaction-Id': transactionId, 'X-Fintech-Id': fintechId
                        },
                        body: request
                    });
            }
            let response = { error: true, errorMessage: "" }
            if (userUpdateResponse.status === 204) {
                response = { ...response, error: false };
            } else {
                const error = new ErrorDetailsResponse();
                error.message = response.data.message;
                response = { ...response, errorMessage: error.message };

            }
            this.log.debug(logFunc, 'end');
            this.log.audit(logFunc, startTime);
            return response;

        } catch (error) {
            return this.#generalError(logFunc, error);
        }
    }

    /**
     * 
     * @param {*} param0 
     * @param {*} param1 
     * @returns 
     */
    async createUser({ transactionId, fintechId }, { userName, userItem }) {
        const logFunc = `${this.serviceName} createUser: `;
        const startTime = new Date();
        this.log.debug(logFunc, 'start');
        try {
            const templateUrl = adminRoutes.put.update_user;
            const parameters = { "domain-server": API_URL };

            const request = new FintechUserCreateRequest(userName, userItem);
            let userUpdateResponse;
            if (EC2_ENV) {
                userUpdateResponse = await this.restApi.post(templateUrl, parameters, request, { transactionId, fintechId });

            } else {
                const url = templateUrl.replace('#domain-server#', this.cognitoApiRoutePrefix);


                userUpdateResponse = await API.post(this.lambdaName, url,
                    {
                        response: true,
                        headers: {
                            "Accept": "application/json", "Content-Type": "application/json",
                            'X-Transaction-Id': transactionId, 'X-Fintech-Id': fintechId
                        },
                        body: request
                    });
            }
            let response = { error: true, errorMessage: "" }
            if (userUpdateResponse.status === 204) {
                response = { ...response, error: false };
            } else {
                const error = new ErrorDetailsResponse();
                error.message = response.data.message;
                response = { ...response, errorMessage: error.message };

            }
            this.log.debug(logFunc, 'end');
            this.log.audit(logFunc, startTime);
            return response;

        } catch (error) {
            return this.#generalError(logFunc, error);
        }
    }

    /**
     * 
     * @returns 
     */
    async deleteUser({ transactionId, fintechId }, { userName }) {
        const logFunc = `${this.serviceName} deleteUser: `;
        const startTime = new Date();
        this.log.debug(logFunc, 'start');
        try {
            const templateUrl = adminRoutes.put.update_user;
            const parameters = { "domain-server": API_URL };
            const params = { "fintech-user-email": userName };
            let userDeleteResponse;

            if (EC2_ENV) {
                userDeleteResponse = await this.restApi.delete(templateUrl, parameters, { transactionId, fintechId, params });

            } else {
                let url = templateUrl.replace('#domain-server#', this.cognitoApiRoutePrefix);

                userDeleteResponse = await API.del(this.lambdaName, url,
                    {
                        response: true,
                        headers: {
                            "Accept": "application/json",
                            //  "Content-Type": "application/json",
                            'X-Transaction-Id': transactionId, 'X-Fintech-Id': fintechId
                        },
                        body: "",
                        queryStringParameters: params


                    });
            }

            let response = { error: true, errorMessage: "" }
            if (userDeleteResponse.status === 204) {
                response = { ...response, error: false };
            } else {
                const error = new ErrorDetailsResponse();
                error.message = response.data.message;
                response = { ...response, errorMessage: error.message };

            }
            this.log.debug(logFunc, 'end');
            this.log.audit(logFunc, startTime);
            return response;


        } catch (error) {
            return this.#generalError(logFunc, error);
        }
    }

    async confirmUser({ transactionId, fintechId }, { userName }) {
        const logFunc = `${this.serviceName} confirmUser: `;
        const startTime = new Date();
        this.log.debug(logFunc, 'start');
        try {
            const templateUrl = adminRoutes.post.user_confirm;
            const parameters = { "domain-server": API_URL };
            const params = { "fintech-user-email": userName };
            let userConfirmResponse;

            if (EC2_ENV) {
                userConfirmResponse = await this.restApi.post(templateUrl, parameters, { transactionId, fintechId, params });

            } else {
                let url = templateUrl.replace('#domain-server#', this.cognitoApiRoutePrefix);

                userConfirmResponse = await API.post(this.lambdaName, url,
                    {
                        response: true,
                        headers: {
                            "Accept": "application/json",
                            //  "Content-Type": "application/json",
                            'X-Transaction-Id': transactionId, 'X-Fintech-Id': fintechId
                        },
                        body: "",
                        queryStringParameters: params
                    });
            }

            let response = { error: true, errorMessage: "" }
            if (userConfirmResponse.status === 204) {
                response = { ...response, error: false };
            } else {
                const error = new ErrorDetailsResponse();
                error.message = response.data.message;
                response = { ...response, errorMessage: error.message };

            }
            this.log.debug(logFunc, 'end');
            this.log.audit(logFunc, startTime);
            return response;

        } catch (error) {
            return this.#generalError(logFunc, error);
        }
    }

    /**
     * 
     * @returns 
     */
    async getUserGroups() {
        const user = await Auth.currentAuthenticatedUser();

        // the array of groups that the user belongs to
        const res = user.signInUserSession.accessToken.payload["cognito:groups"]
        return res;
    }

    /**
     * 
     * @returns 
     */
    async currentAuthenticatedUser() {
        const logFunc = `${this.serviceName} currentAuthenticatedUser: `;
        const startTime = new Date();
        this.log.debug(logFunc, 'start');
        try {
            const user = await Auth.currentAuthenticatedUser();
            this.log.debug(logFunc, 'end');
            this.log.audit(logFunc, startTime);
            return user;
        } catch (error) {
            return this.#generalError(logFunc, error);
        }
    }

    /**
     * 
     * @returns 
     */
    async currentSession() {
        const logFunc = `${this.serviceName} currentSession: `;
        const startTime = new Date();
        this.log.debug(logFunc, 'start');
        try {
            const user = await Auth.currentSession();
            this.log.debug(logFunc, 'end');
            this.log.audit(logFunc, startTime);
            return user;
        } catch (error) {
            return this.#generalError(logFunc, error);
        }
    }

    /**
     * 
     * @returns 
     */
    async forgotPasswordSubmit(userMail, verificationCode, newPassword) {
        const logFunc = `${this.serviceName} forgotPasswordSubmit: `;
        const startTime = new Date();
        this.log.debug(logFunc, 'start');

        try {

            // validate request 
            const retVal = this.#authValidation({ userMail: userMail, password: newPassword });
            if (retVal instanceof ErrorDetailsResponse) return retVal;

            const user = await Auth.forgotPasswordSubmit(userMail, verificationCode, newPassword);

            this.log.debug(logFunc, 'end');
            this.log.audit(logFunc, startTime);

            return user;
        } catch (error) {
            return this.#generalError(logFunc, error);
        }
    }

    /**
     * 
     * @param {*} userMail 
     * @returns 
     */
    async forgotPassword(userMail) {
        const logFunc = `${this.serviceName} forgotPassword: `;
        const startTime = new Date();
        this.log.debug(logFunc, 'start');

        try {
            // validate request 
            const retVal = this.#authValidation({ userMail: userMail, password: "no-need-to-validate" });
            if (retVal instanceof ErrorDetailsResponse) return retVal;
            const user = await Auth.forgotPassword(userMail);
            this.log.debug(logFunc, 'end');
            this.log.audit(logFunc, startTime);
            return user;

        } catch (error) {
            return this.#generalError(logFunc, error);
        }
    }

    /**
     * 
     * @param {*} userObj 
     * @returns 
     */
    #authValidation(userObj) {

        const logFunc = `${this.serviceName} #authValidation: `;
        this.log.debug(logFunc, 'start');
        try {
            const { userMail, password } = userObj;
            if (!userMail || (userMail && userMail.length < 5)) {
                this.log.error(logFunc, "userMail is missing or not valid");
                return ErrorDetailsResponse.constructFromObject(`${logFunc}  userMail is missing or not valid `, null);
            }

            if (!password || (password && password.length < 6)) {
                this.log.error(logFunc, "password is missing or not valid");
                return ErrorDetailsResponse.constructFromObject(`${logFunc}  password is missing or not valid `, null);
            }

        } catch (error) {
            return this.#generalError(logFunc, error);
        }
        return {};
    }

    /**
     * 
     * @param {*} logFunc 
     * @param {*} error 
     * @returns 
     */
    #generalError(logFunc, error) {

        try {
            const errorObj = ErrorDetailsResponse.constructFromObject(
                { message: `${error}` }, null);
            this.log.error(logFunc, JSON.stringify(errorObj));
            return errorObj;
        } catch (err) {
            console.error(err);
        }
    }
}
