import React from 'react';
import PropTypes from 'prop-types';
import { ButtonStyle } from './Button.style';


const Button = props => {

    return (
        <ButtonStyle {...props} disabled={props.disable}
            onClick={props.click} >
            {props.text}
        </ButtonStyle>

    );
};

Button.propTypes = {
    click: PropTypes.func,
    text: PropTypes.string,
    disable: PropTypes.bool
};

export default Button;