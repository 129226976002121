import styled from 'styled-components';

export const IMGContainer = styled.div`
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    border-radius: ${({ rounded }) => (rounded ? '50px' : '0')};
    background-color: ${({ backgroundColor }) => backgroundColor};
    display: flex;
    justify-content: ${({ justifyContent }) => justifyContent};
    overflow: hidden;
    margin-left: ${({ marginLeft }) => marginLeft};
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;
