import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Container } from '../../../';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  scales: {
    y: {
      title: {
        text: 'Number of API calls',
        display: true,
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
    },
  },
};

const BarChart = ({ triggered, billed, failed, barLabels }) => {
  const labels = barLabels;
  const data = {
    labels,
    datasets: [
      {
        label: 'API triggered',
        data: triggered.map((it) => it),
        backgroundColor: '#00ae34',
      },
      {
        label: 'Api Billed',
        data: billed.map((it) => it),
        backgroundColor: '#f9a732',
      },
      {
        label: 'API Failed',
        data: failed.map((it) => it),
        backgroundColor: '#ff554b',
      },
    ],
  };

  return (
    <Container height="400px">
      <Bar options={options} data={data} />
    </Container>
  );
};

export default BarChart;