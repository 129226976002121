/*
 * billing Reports API 
 * Fintech billing reports
 *
 * OpenAPI spec version: 1.0.5
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.37
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The ReportsDetailsRecord model module.
 * @module model/ReportsDetailsRecord
 * @version 1.0.5
 */
export class ReportsDetailsRecord {
  /**
   * Constructs a new <code>ReportsDetailsRecord</code>.
   * @alias module:model/ReportsDetailsRecord
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ReportsDetailsRecord</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ReportsDetailsRecord} obj Optional instance to populate.
   * @return {module:model/ReportsDetailsRecord} The populated <code>ReportsDetailsRecord</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ReportsDetailsRecord();
      if (data.hasOwnProperty('recordId'))
        obj.recordId = ApiClient.convertToType(data['recordId'], 'Number');
      if (data.hasOwnProperty('createdAt'))
        obj.createdAt = ApiClient.convertToType(data['createdAt'], 'Date');
      if (data.hasOwnProperty('apiDisplayName'))
        obj.apiDisplayName = ApiClient.convertToType(data['apiDisplayName'], 'String');
      if (data.hasOwnProperty('organizationName'))
        obj.organizationName = ApiClient.convertToType(data['organizationName'], 'String');
      if (data.hasOwnProperty('billingIndication'))
        obj.billingIndication = ApiClient.convertToType(data['billingIndication'], 'Boolean');
      if (data.hasOwnProperty('billingRate'))
        obj.billingRate = ApiClient.convertToType(data['billingRate'], 'Number');
      if (data.hasOwnProperty('httpStatus'))
        obj.httpStatus = ApiClient.convertToType(data['httpStatus'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {Number} recordId
 */
ReportsDetailsRecord.prototype.recordId = undefined;

/**
 * @member {Date} createdAt
 */
ReportsDetailsRecord.prototype.createdAt = undefined;

/**
 * Allowed values for the <code>apiDisplayName</code> property.
 * @enum {String}
 * @readonly
 */
ReportsDetailsRecord.ApiDisplayNameEnum = {
  /**
   * value: "get-consent"
   * @const
   */
  getConsent: "get-consent",

  /**
   * value: "get-consent-status"
   * @const
   */
  getConsentStatus: "get-consent-status",

  /**
   * value: "delete-consent"
   * @const
   */
  deleteConsent: "delete-consent",

  /**
   * value: "create-consent"
   * @const
   */
  createConsent: "create-consent",

  /**
   * value: "refresh-token"
   * @const
   */
  refreshToken: "refresh-token",

  /**
   * value: "access-token"
   * @const
   */
  accessToken: "access-token",

  /**
   * value: "accounts-list"
   * @const
   */
  accountsList: "accounts-list",

  /**
   * value: "accounts-details"
   * @const
   */
  accountsDetails: "accounts-details",

  /**
   * value: "account-balances"
   * @const
   */
  accountBalances: "account-balances",

  /**
   * value: "transaction-list"
   * @const
   */
  transactionList: "transaction-list",

  /**
   * value: "transaction-details"
   * @const
   */
  transactionDetails: "transaction-details"
};
/**
 * @member {module:model/ReportsDetailsRecord.ApiDisplayNameEnum} apiDisplayName
 */
ReportsDetailsRecord.prototype.apiDisplayName = undefined;

/**
 * @member {String} organizationName
 */
ReportsDetailsRecord.prototype.organizationName = undefined;

/**
 * @member {Boolean} billingIndication
 */
ReportsDetailsRecord.prototype.billingIndication = undefined;

/**
 * @member {Number} billingRate
 */
ReportsDetailsRecord.prototype.billingRate = undefined;

/**
 * @member {Number} httpStatus
 */
ReportsDetailsRecord.prototype.httpStatus = undefined;

