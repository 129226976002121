import styled from 'styled-components';

export const RowContainer = styled.div`
  align-content: ${({ alignContent }) => alignContent};
  align-items: ${({ alignItems }) => alignItems};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifyContent }) => justifyContent};
  background-color: ${({ backgroundColor }) => backgroundColor};
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  padding: ${({ padding }) => padding};
`;
