export const banksList2 =
    [
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        'בנק לאומי לישראל',
        'בנק דיסקונט בע"מ',
        'בנק הפועלים בע"מ',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        'בנק מזרחי טפחות בע"מ',
    ]

export const banksList =
    [
        { name: 'בנק מזרחי טפחות בע"מ', value: 20, authorized: true },
        { name: 'בנק לאומי לישראל בע"מ', value: 10, authorized: true },
        { name: 'בנק הפועלים בע"מ', value: 12, authorized: true },
        { name: 'בנק דיסקונט בע"מ', value: 11, authorized: true },
        // { name: 'בנק מסד בע"מ', value: 14, authorized: false },
        // { name: 'בנק פועלי אגודת ישראל בע"מ', value: 16, authorized: false },
        // { name: 'בנק יהב לעובדי המדינה בע"מ', value: 4, authorized: false },
        // { name: 'הבנק הדיגיטלי הראשון בע"מ', value: 17, authorized: false },
        // { name: 'יובנק בע"מ', value: 18, authorized: false },
        // { name: 'מוניציפל בנק בע"מ', value: 19, authorized: false },
        // { name: 'מרכז סליקה בנקאי בע"מ', value: 22, authorized: false }
    ];

export const restrictedTo = {
    "CASH": "CACC",
    "SAVINGS": "SVGS",
    "CARD": "CARD",
    "LOAN": "LOAN",
}


export const permissions = {
    neededPermissions: {
        header: 'המידע שאתה נדרש לשתף',
        items: [
            { icon: "suitcase", text: 'מידע על החשבון שלך', answer: 'מידע כגון...'},
            { icon: "suitcase", text: 'תשלומים שוטפים', answer: '' },
            { icon: "suitcase", text: 'תנועות בחשבון שלך', answer: 'פירוט התנועות לניתוח המלצות התנהלות ...' },
            { icon: "suitcase", text: 'יתרות', answer: 'פירוט יתרות לקבל המלצות' },
            { icon: "suitcase", text: 'פעולות והטבות בחשבון', answer: '' },
            { icon: "suitcase", text: 'פרטים אישיים', answer: '' }
        ]
    },
    info: {
        header: "מידע על מתן הרשאות",
        items: [
            { icon: "", text: "מדוע אני נדרש לשתף את המידע שלי", answer: "כדי לנהל טוב יותר את הכסף יש להציג את המידע באופן שקוף וברר באופן מרוכז" },
            { icon: "", text: "מה אקבל בתמורה לשיתוף המידע", answer: "ליהנות משימוש במוצרים ושירותים חדשניים" }
        ]
    },
    consentTime: "שיתוף המידע יהיה תקף במשך 12 חודשים",
    consentDate: "הגישה למידע שלך תבוצע עד תאריך 10.09.2022"
};
