/*
 * Fintech Admin Hub API 
 * Fintech Admin
 *
 * OpenAPI spec version: 1.0.0.1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.36
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {UsersItem} from './UsersItem';

/**
 * The FintechUserUpdateRequest model module.
 * @module model/FintechUserUpdateRequest
 * @version 1.0.0.1
 */
export class FintechUserUpdateRequest {
  /**
   * Constructs a new <code>FintechUserUpdateRequest</code>.
   * @alias module:model/FintechUserUpdateRequest
   * @class
   * @param userName {String} 
   * @param userItem {module:model/UsersItem} 
   */
  constructor(userName, userItem) {
    this.userName = userName;
    this.userItem = userItem;
  }

  /**
   * Constructs a <code>FintechUserUpdateRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/FintechUserUpdateRequest} obj Optional instance to populate.
   * @return {module:model/FintechUserUpdateRequest} The populated <code>FintechUserUpdateRequest</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new FintechUserUpdateRequest();
      if (data.hasOwnProperty('userName'))
        obj.userName = ApiClient.convertToType(data['userName'], 'String');
      if (data.hasOwnProperty('userItem'))
        obj.userItem = UsersItem.constructFromObject(data['userItem']);
    }
    return obj;
  }
}

/**
 * @member {String} userName
 */
FintechUserUpdateRequest.prototype.userName = undefined;

/**
 * @member {module:model/UsersItem} userItem
 */
FintechUserUpdateRequest.prototype.userItem = undefined;

