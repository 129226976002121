import styled from 'styled-components'

export const InputTextStyle = styled.input`

    webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    font-family: var(--font-family-main);
    font-size: 18px;
    width: 100%;
    margin: 18px 0;
    margin-bottom: ${({ marginBottom }) => marginBottom};
    padding: 7px;
    border: 1px solid var(--frame-border-color);
    border-radius: 6px;
    font-color: "#5B5D62";
    :focus{
        border: 1px solid var(--frame-border-color);
        outline: 0px solid black;
        background-color: var(--light-grey);
    }

`;
