import { ENV } from '../resources/constants/EnvironmentVariables';
import Logger from '../utils/libs/Logger';
export default class BaseServices {

    /**
     * 
     * @param {*} param0 
     */
    constructor(env) {
        this.env = env ? env : ENV;
        this.log = new Logger({ env });
        this.serviceName = this.constructor.name;
    }
}