import styled from 'styled-components';

export const UserCompContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    width: 300px;
    height: 50px;
    margin: 0 30px;


    p {
        color: #5B5D62;
        margin: 0;
        font-size: 16px;
        font-family: 'Assistant', sans-serif;
        font-weight: 300;
    }
`;
