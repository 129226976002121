const psuIdArray = [
    {
        value: "245938880",
        name: "User with multiple accounts.PsuID:245938880 UserName:102718538"
    },
    {
        value: "UA0324046596",
        name: "Foreign Id. PsuID:UA0324046596 UserName:773315566"
    },
    {
        value: "011462580",
        name: "Multi Currency Account.PsuID:011462580 UserName: 153400597"
    },
    {
        value: "759158321",
        name: "Account with UserName:741416837. UserName: 153400597"
    },
    {
        value: "755733188",
        name: "Account with UserName:153400597. UserName:741416837"
    },
    {
        value: "204102792",
        name: "Account Is Blocked. UserName:465563331"
    },
];

export default  psuIdArray ;
