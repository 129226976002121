/*
 * billing Reports API 
 * Fintech billing reports
 *
 * OpenAPI spec version: 1.0.5
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.37
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {BillingReportsCompareResponse} from '../model/BillingReportsCompareResponse';
import {ErrorDetailsResponse} from '../model/ErrorDetailsResponse';
import {RequestCompareKeys} from '../model/RequestCompareKeys';

/**
* BillingReports service.
* @module api/BillingReportsApi
* @version 1.0.5
*/
export class BillingReportsApi {

    /**
    * Constructs a new BillingReportsApi. 
    * @alias module:api/BillingReportsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the billingReports operation.
     * @callback moduleapi/BillingReportsApi~billingReportsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BillingReportsCompareResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * billing Reports
     * Fintech billing Reports
     * @param {String} X_TRANSACTION_ID 
     * @param {module:model/RequestCompareKeys} compareParams 
     * @param {module:api/BillingReportsApi~billingReportsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    billingReports(X_TRANSACTION_ID, compareParams, callback) {
      
      let postBody = null;
      // verify the required parameter 'X_TRANSACTION_ID' is set
      if (X_TRANSACTION_ID === undefined || X_TRANSACTION_ID === null) {
        throw new Error("Missing the required parameter 'X_TRANSACTION_ID' when calling billingReports");
      }
      // verify the required parameter 'compareParams' is set
      if (compareParams === undefined || compareParams === null) {
        throw new Error("Missing the required parameter 'compareParams' when calling billingReports");
      }

      let pathParams = {
        
      };
      let queryParams = {
        'compare-params': compareParams
      };
      let headerParams = {
        'X-TRANSACTION-ID': X_TRANSACTION_ID
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = BillingReportsCompareResponse;

      return this.apiClient.callApi(
        '/v1/obh/billingReports/compare', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}