import React from 'react'
import PropTypes from "prop-types"
import { Container } from "./SingleToggle.style"

export default function SingleToggle({
    isSelect,
    onClick,
    text
}) {
    return (
        <Container selected={isSelect} onClick={onClick}>
            {text}
        </ Container>
    )
}

SingleToggle.propTypes = {
    isSelect: PropTypes.bool,
    onClick: PropTypes.func,
    text: PropTypes.string
}
