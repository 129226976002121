import { createSlice } from '@reduxjs/toolkit';

const webNav = createSlice({
  name: 'webNav',
  initialState: {
    page: 'init',
  },
  reducers: {
    setPage: (state, action) => { state.page = action.payload; },
  },
});

export const { setPage } = webNav.actions;

export default webNav.reducer;