/*
 * billing Reports API 
 * Fintech billing reports
 *
 * OpenAPI spec version: 1.0.5
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.37
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {ReportsDetailsRecord} from './ReportsDetailsRecord';

/**
 * The ReportsDetailsRecords model module.
 * @module model/ReportsDetailsRecords
 * @version 1.0.5
 */
export class ReportsDetailsRecords extends Array {
  /**
   * Constructs a new <code>ReportsDetailsRecords</code>.
   * @alias module:model/ReportsDetailsRecords
   * @class
   * @extends Array
   */
  constructor() {
    super();
  }

  /**
   * Constructs a <code>ReportsDetailsRecords</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ReportsDetailsRecords} obj Optional instance to populate.
   * @return {module:model/ReportsDetailsRecords} The populated <code>ReportsDetailsRecords</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ReportsDetailsRecords();
      ApiClient.constructFromObject(data, obj, 'ReportsDetailsRecord');
    }
    return obj;
  }
}
