/*
 * Consents API 
 * Manage consents for Fintech
 *
 * OpenAPI spec version: 1.0.7
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {OrganizationDetailsAccountInputPattern} from './OrganizationDetailsAccountInputPattern';

/**
 * The OrganizationDetails model module.
 * @module model/OrganizationDetails
 * @version 1.0.7
 */
export class OrganizationDetails {
  /**
   * Constructs a new <code>OrganizationDetails</code>.
   * @alias module:model/OrganizationDetails
   * @class
   * @param name {String} consents Obj structure is taken from PSD2-XS2AFramework-1.4.2.yaml
   * @param dbName {String} consents Obj structure is taken from PSD2-XS2AFramework-1.4.2.yaml
   * @param orgID {String} fintechId - fintech unique id
   */
  constructor(name, dbName, orgID) {
    this.name = name;
    this.dbName = dbName;
    this.orgID = orgID;
  }

  /**
   * Constructs a <code>OrganizationDetails</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/OrganizationDetails} obj Optional instance to populate.
   * @return {module:model/OrganizationDetails} The populated <code>OrganizationDetails</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new OrganizationDetails();
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('dbName'))
        obj.dbName = ApiClient.convertToType(data['dbName'], 'String');
      if (data.hasOwnProperty('orgID'))
        obj.orgID = ApiClient.convertToType(data['orgID'], 'String');
      if (data.hasOwnProperty('organizationType'))
        obj.organizationType = ApiClient.convertToType(data['organizationType'], 'String');
      if (data.hasOwnProperty('psdVersion'))
        obj.psdVersion = ApiClient.convertToType(data['psdVersion'], 'String');
      if (data.hasOwnProperty('accountInputPattern'))
        obj.accountInputPattern = ApiClient.convertToType(data['accountInputPattern'], [OrganizationDetailsAccountInputPattern]);
    }
    return obj;
  }
}

/**
 * consents Obj structure is taken from PSD2-XS2AFramework-1.4.2.yaml
 * @member {String} name
 */
OrganizationDetails.prototype.name = undefined;

/**
 * consents Obj structure is taken from PSD2-XS2AFramework-1.4.2.yaml
 * @member {String} dbName
 */
OrganizationDetails.prototype.dbName = undefined;

/**
 * fintechId - fintech unique id
 * @member {String} orgID
 */
OrganizationDetails.prototype.orgID = undefined;

/**
 * Allowed values for the <code>organizationType</code> property.
 * @enum {String}
 * @readonly
 */
OrganizationDetails.OrganizationTypeEnum = {
  /**
   * value: "Bank"
   * @const
   */
  bank: "Bank",

  /**
   * value: "CC"
   * @const
   */
  CC: "CC",

  /**
   * value: "Bureau"
   * @const
   */
  bureau: "Bureau"
};
/**
 * @member {module:model/OrganizationDetails.OrganizationTypeEnum} organizationType
 */
OrganizationDetails.prototype.organizationType = undefined;

/**
 * psd2 version
 * @member {String} psdVersion
 */
OrganizationDetails.prototype.psdVersion = undefined;

/**
 * @member {Array.<module:model/OrganizationDetailsAccountInputPattern>} accountInputPattern
 */
OrganizationDetails.prototype.accountInputPattern = undefined;

