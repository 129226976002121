/*
 * billing Reports API 
 * Fintech billing reports
 *
 * OpenAPI spec version: 1.0.5
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.37
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The MonthlyReport model module.
 * @module model/MonthlyReport
 * @version 1.0.5
 */
export class MonthlyReport {
  /**
   * Constructs a new <code>MonthlyReport</code>.
   * @alias module:model/MonthlyReport
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>MonthlyReport</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MonthlyReport} obj Optional instance to populate.
   * @return {module:model/MonthlyReport} The populated <code>MonthlyReport</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MonthlyReport();
      if (data.hasOwnProperty('overallCharge'))
        obj.overallCharge = ApiClient.convertToType(data['overallCharge'], 'String');
      if (data.hasOwnProperty('overallRequests'))
        obj.overallRequests = ApiClient.convertToType(data['overallRequests'], 'String');
      if (data.hasOwnProperty('overallBilledRequests'))
        obj.overallBilledRequests = ApiClient.convertToType(data['overallBilledRequests'], 'String');
      if (data.hasOwnProperty('overallErrorRequests'))
        obj.overallErrorRequests = ApiClient.convertToType(data['overallErrorRequests'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} overallCharge
 */
MonthlyReport.prototype.overallCharge = undefined;

/**
 * @member {String} overallRequests
 */
MonthlyReport.prototype.overallRequests = undefined;

/**
 * @member {String} overallBilledRequests
 */
MonthlyReport.prototype.overallBilledRequests = undefined;

/**
 * @member {String} overallErrorRequests
 */
MonthlyReport.prototype.overallErrorRequests = undefined;

