import React from 'react';
import { SpinnerContainer, SpinnerLoader } from './RollingSpinner.Style';

const RollingSpinner = () => {
    return (
        <SpinnerContainer>
            <SpinnerLoader />
        </SpinnerContainer>
    );
};

export default RollingSpinner;