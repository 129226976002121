import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LineSeparator, ButtonGroup, QNA, Row, SelectOption, Button2, ProgressBar, ContainerResponsive, IMG, RollingSpinner, Col } from '../../../components';
import { permissions } from './const'
import { setPage } from '../../../store/webNavigation/webNavSlice';
import ConsentPicker from '../../../components/ConsentPicker/ConsentPicker';
import { FintechAdminService } from '../../../services/fintechAdmin/finetechAdmin/FintechAdminService';
import { getCurrentDate } from '../../../componentsTools/commonFunction/commonFunctions';
import { banksList } from '../../../views/consent/CreateAndRedirectToBank/const';
import { EstablishConsentService } from '../../../services/consent/establishConsent/EstablishConsentService';
import { ENV } from '../../../resources/constants/EnvironmentVariables';
import { fetchAdvancedSearchReportsDetails } from '../../../store/management/managementActions';
import { OrganizationDetails } from '../../../swagger/codeGen/consent-routes/src';

const establishConsentService = new EstablishConsentService(ENV);

 // === === ===| URL that loads this component : /consent
const CreateAndRedirectToBank = () => {
    const dispatch = useDispatch();
    dispatch(setPage('ignore'));
    const key = new URLSearchParams(window.location.search).get('key')
    const [step, setStep] = useState(1);
    const [linkToBank, setLinkToBank] = useState('');
    // const mapItems = (items) => items.map((x) => (<div key={`${x.text}key`} >{x.text}</div>));
    const [orgId, setOrgId] = useState(null);
    const [orgList, setOrgList] = useState([]);
    const [orgDetails, setOrgDetails] = useState({});
    const { transactionId, fintechId } = useSelector((state) => state.management);
    const [stylesObject, setStylesObject] = useState({ fontColor: "", backgroundColor: "", logoUrl: "" });
    const [isLoading, setIsLoading] = useState(false);
    const consent = { validUntil: getCurrentDate('-'), orgId: 0, key: key };

    async function fetchStyleObject() {
        try {
            const service = new FintechAdminService();
            const response = await service.getStyle({ transactionId, fintechId });
            setStylesObject({ ...response });
        } catch (error) {
        } finally { }
    }

    async function fetchOrganizationsArray() {
        try {
            const response = await establishConsentService.getOrganizations();
            const orgList = [];
            response.forEach((item) => {
                orgList.push({
                    key: item.orgID,
                    value: item.orgID,
                    name: item.name
                })
            })
            setOrgList(orgList);
        } catch (error) {
        } finally { setIsLoading(false) }
    }

    useEffect(() => {
        setIsLoading(true);
        if (transactionId && fintechId) { fetchStyleObject({ transactionId, fintechId }) }
        fetchOrganizationsArray();
    }, []);

    async function fetchDetailsForConsentPicker() {
        try {
            const response = await establishConsentService.getOrganizationDetails(orgId);
            if (response instanceof OrganizationDetails) {
                setOrgDetails({ ...response });
                setStep(2);
            }
        } catch (error) {
        }
    }

    const moveStepTwoAndFetchInfo = () => { if (orgId) { fetchDetailsForConsentPicker() } }

    if (isLoading) return <Col height="50vh"><RollingSpinner /></Col>
    return (
        <Row >
            <ContainerResponsive padding='20px' width="768px" justifyContent="center">
                <Row><IMG src={stylesObject.logoUrl} alt='Logo' width="200px" justifyContent='space-around' /></Row>
                <div style={{ direction: 'rtl' }}>
                    <ProgressBar bgcolor={stylesObject.backgroundColor} completed={(step)} all={3} />
                    {step === 1 &&
                        <>
                            <h2 style={{ color: stylesObject.fontColor }}><b>{permissions.info.header}</b></h2>
                            {permissions.info.items.map(
                                (item, idx) => <QNA key={idx} q={item.text} a={item.answer} />
                            )}

                            <LineSeparator />

                            <h2 style={{ color: stylesObject.fontColor }}><b>{permissions.neededPermissions.header}</b></h2>
                            {permissions.neededPermissions.items.map(
                                (item, idx) => <QNA key={idx} q={item.text} a={item.answer} />
                            )}

                            <SelectOption keyParam={key} direction={'rtl'} value={consent.orgId} change={(e) => setOrgId(e.target.value)}
                                defaultValue={''}
                                defaultTitle={'בחר את הארגון עבורו אתה מוכן לשתף את המידע'}
                                options={orgList}
                            />
                            <ButtonGroup>
                                <Button2 size="medium" disable={!!!orgId} onClick={moveStepTwoAndFetchInfo}
                                    label="המשך" />
                            </ButtonGroup>
                        </>

                    }
                    {step === 2 && <ConsentPicker orgDetails={orgDetails} setOrgId={setOrgId} keyParam={key} setStep={setStep} setLinkToBank={setLinkToBank} bank={orgId} />}
                    {step === 3 &&
                        <>
                            <h3>הבקשה להסכמה נוצרה בהצלחה, אבל עוד לא סיימנו:</h3>
                            <h2><a href={linkToBank} target='_blank' rel="noreferrer">כדי להשלים את הבקשה אנא אשר את ההסכמה בבנק</a></h2>
                        </>
                    }
                </div>
            </ContainerResponsive>
        </Row>
    );
};

export default CreateAndRedirectToBank;