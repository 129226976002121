import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { setPage } from '../../store/webNavigation/webNavSlice';
import { H1, Container, RollingSpinner, Button2, Card01, Col, Grid, Row } from '../../components';
import { EstablishConsentService } from '../../services/consent/establishConsent/EstablishConsentService';

const TppAccomplishConsent = () => {
    const dispatch = useDispatch();
    dispatch(setPage('ignore'));
    document.title = "Fintech Demo Page"
    const getAccessToken = new EstablishConsentService();
    const [freeze, setFreeze] = useState(false);

    const [consentId, setConsentId] = useState(null);
    const [consentId2, setConsentId2] = useState(null);
    const [consentStatus, setConsentStatus] = useState(null);
    const [accountList, setAccountList] = useState(null);
    const [accountDetails, setAccountDetails] = useState(null);
    const [accountBalances, setAccountBalances] = useState(null);
    const [accountTransactions, setAccountTransactions] = useState(null);

    const [accountDetailsInput, setAccountDetailsInput] = useState(null);
    const [accountBalancesInput, setAccountBalancesInput] = useState(null);
    const [accountTransactionsInput, setAccountTransactionsInput] = useState(null);
    const code = new URLSearchParams(window.location.search).get('code')
    const orgId = new URLSearchParams(window.location.search).get('orgId')

    const getCookie = (name) => {
        let value = "; " + document.cookie;
        let parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const request = {
        code,
        psuId: getCookie('psuId'),
        orgId,
        tppRedirectUri: getCookie('redirectUrl'),
        fintechId: getCookie('finId'),
    }

    useEffect(() => {
        if (!localStorage.getItem('wasRequestSent')) {
            localStorage.setItem('wasRequestSent', true);
            if(!orgId || !code){
                setConsentId("Bad Query Params. orgId or code are not set");
                return;
            }
            getAccessToken.getAccessToken(request).then((res) => {
                if (!res.data || res.data.length === 0) { alert('Server error, try again later') }
                else if (typeof (res) === 'object' && res.data && res.data.code) {
                    setConsentId("Error in Accomplish consent");
                }
                else {
                    setConsentId(JSON.parse(res.data).consentId)
                    setConsentId2(JSON.parse(res.data).consentId)
                }
            })
        }
    }, [])


    const doGetConsent = () => {
        setFreeze(true);
        getAccessToken.getConsent(consentId2, request.psuId, request.fintechId).then((res) => {
            setFreeze(false);
            if (res.data && res.data.code) { alert('Error in getConsent') }
            else {
                setConsentStatus(JSON.stringify(res))
            }
        }
        )
    }

    const doGetAccountList = () => {
        setFreeze(true);
        getAccessToken.getAccountList(consentId2, request.psuId, request.fintechId).then((res) => {
            setFreeze(false);
            if (res.data && res.data.code) { alert('Error in getConsent') }
            else {
                setAccountList(JSON.stringify(res))
            }
        }
        )
    }

    const doGetAccountDetails = () => {
        setFreeze(true);
        getAccessToken.getAccountDetails(consentId2, request.psuId, request.fintechId, accountDetailsInput).then((res) => {
            setFreeze(false);
            if (res.data && res.data.code) { alert('Error in doGetAccountDetails') }
            else {
                setAccountDetails(JSON.stringify(res))
            }
        }
        )
    }

    const doGetAccountBalances = () => {
        setFreeze(true);
        getAccessToken.getAccountBalances(consentId2, request.psuId, request.fintechId, accountBalancesInput).then((res) => {
            setFreeze(false);
            if (res.data && res.data.code) { alert('Error in doGetAccountBalances') }
            else {
                setAccountBalances(JSON.stringify(res))
            }
        }
        )
    }

    const doGetAccountTransactions = () => {
        setFreeze(true);
        getAccessToken.getAccountTransactions(consentId2, request.psuId, request.fintechId, accountTransactionsInput).then((res) => {
            setFreeze(false);
            if (res.data && res.data.code) { alert('Error in doGetAccountTransactions') }
            else {
                setAccountTransactions(JSON.stringify(res))
            }
        }
        )
    }

    // 1: getConsent - Simulate getConsent
    const getConsentDemo = () => {
        return (
            <>
                <Col justifyContent='flex-start'>
                    <Button2 onClick={doGetConsent} size="small" rounded width="200px" label="Simulate getConsent" disable={freeze} />
                    {consentStatus && <p><b>Consent status: </b>{consentStatus}</p>}
                </Col>
            </>
        )
    }

    // 2: getAccountList - Simulate getAccountList
    const getAccountList = () => {
        return (
            <>
                <Col justifyContent='flex-start'>
                    <Button2 onClick={doGetAccountList} size="small" rounded width="250px" label="Simulate getAccountList" disable={freeze} />
                    {consentStatus && <p><b>List: </b>{accountList}</p>}
                </Col>
            </>
        )
    }

    // 3: getAccountDetails - Simulate getAccountDetails
    const getAccountDetailsDemo = () => {
        return (
            <>
                <Col justifyContent='flex-start'>
                    <input type="text" onChange={(e) => setAccountDetailsInput(e.target.value)} value={accountDetailsInput} />
                    <Button2 onClick={doGetAccountDetails} size="small" rounded width="250px" label="Simulate getAccountDetails" disable={freeze} />
                    {consentStatus && <p><b>Details: </b>{accountDetails}</p>}
                </Col>
            </>
        )
    }

    // 4: getAccountBalances - Simulate getAccountBalances
    const getAccountBalancesDemo = () => {
        return (
            <>
                <Col justifyContent='flex-start'>
                    <input type="text" onChange={(e) => setAccountBalancesInput(e.target.value)} value={accountBalancesInput} />
                    <Button2 onClick={doGetAccountBalances} size="small" rounded width="250px" label="Simulate getAccountBalances" disable={freeze} />
                    {consentStatus && <p><b>Balances: </b>{accountBalances}</p>}
                </Col>
            </>
        )
    }

    // 5: getAccountTransactions - Simulate getAccountTransactions
    const getAccountTransactionsDemo = () => {
        return (
            <>
                <Col justifyContent='flex-start'>
                    <input type="text" onChange={(e) => setAccountTransactionsInput(e.target.value)} value={accountTransactionsInput} />
                    <Button2 onClick={doGetAccountTransactions} size="small" rounded width="300px" label="Simulate getAccountTransactions" disable={freeze} />
                    {consentStatus && <p><b>Transactions: </b>{accountTransactions}</p>}
                </Col>
            </>
        )
    }

    return (
        <Container padding='10px'>
            <H1 size="large">Finish Consent Process</H1>
            <Card01 padding="20px" paddingTop="20px" dropshadow marginBottom="30px">
                <h2><b>I'm calling the organization with this data:</b></h2>
                <p><b>code: </b>{code}</p>
                <p><b>orgId: </b>{orgId}</p>
                <p><b>psuId: </b>{getCookie('psuId')}</p>
                <p><b>tppRedirectUri: </b>{getCookie('redirectUrl')}</p>
                <p><b>fintechId: </b>{getCookie('finId')}</p>

                <Row alignItems="center" backgroundColor='#eeeeee'>
                    <p className='no-break-line'><b>And I'm getting the consentId: </b></p>
                    <h2 className='no-break-line'>{consentId ? consentId : <RollingSpinner />}</h2>
                </Row>
            </Card01>

            <Card01 padding="20px" paddingTop="20px" dropshadow marginBottom="30px">
                <h2><b>Simulate calls to organization</b></h2>
                <br />
                <input className='tpp-field-25' type="text" onChange={(e) => setConsentId2(e.target.value)} value={consentId2} />
                {consentId && getConsentDemo()}
                {consentStatus && getAccountList()}
                {accountList && getAccountDetailsDemo()}
                {accountList && getAccountBalancesDemo()}
                {accountList && getAccountTransactionsDemo()}
            </Card01>
        </Container >
    )
}

export default TppAccomplishConsent