import { managementActions } from "./managementSlice";
import { ENV } from "../../resources/constants/EnvironmentVariables.js";
import { FinetechReportsService } from "../../services/fintechAdmin/finetechReports/FinetechReportsService";
import { ErrorDetailsResponse, BillingReportsResponse, BillingReportsDetailsResponse, BillingReportsCompareResponse } from "../../swagger/codeGen/billing-reports/src";
import { EstablishConsentService } from "../../services/consent/establishConsent/EstablishConsentService";

const fintechReportsService = new FinetechReportsService({ env: ENV });
const establishConsentService = new EstablishConsentService({ env: ENV });

/**
 * 
 * @param {*} certificateType 
 * @param {*} certificateStream 
 * @returns 
 */
export const fetchCurrentMonthSummary = ({ fintechId, transactionId }) => {
    return async (dispatch) => {
        const response = await fintechReportsService.getMonthlySummary({ fintechId, transactionId });
        if (response instanceof BillingReportsResponse) {
            dispatch(managementActions.fetchCurrentMonthSummarySuccess(
                { monthlyReport: { ...response.monthlyReport } }
            ));
        } else if (response instanceof ErrorDetailsResponse) {
            dispatch(managementActions.fetchCurrentMonthSummarySuccess(
                { errorMessage: response.message }
            ));
        } else {
            dispatch(managementActions.fetchCurrentMonthSummarySuccess(
                { errorMessage: response.message }
            ));
        }
    };
};

/**
 * 
 * @param {*} certificateType 
 * @param {*} certificateStream 
 * @returns 
 */
export const fetchOrganizations = () => {
    return async (dispatch) => {
        const response = await establishConsentService.getOrganizations();
        if (response instanceof ErrorDetailsResponse) {
            dispatch(managementActions.fetchOrganizationsFailed(
                { errorMessage: response.message }
            ));
        }  else {
            dispatch(managementActions.fetchOrganizationsSuccess(
                { organizations: response  }
            ));
        }
    };
};

export const fetchPreviousMonthSummary = ({ fintechId, transactionId }) => {
    return async (dispatch) => {
        const response = await fintechReportsService.getMonthlySummary({ fintechId, transactionId, monthParam: 1 });
        if (response instanceof BillingReportsResponse) {

            dispatch(managementActions.fetchPreviousMonthSummarySuccess(
                { monthlyReport: { ...response.monthlyReport } }
            ));
        } else if (response instanceof ErrorDetailsResponse) {
            dispatch(managementActions.fetchPreviousMonthSummaryFailed(
                { errorMessage: response.message }
            ));
        } else {
            dispatch(managementActions.fetchPreviousMonthSummaryFailed(
                { errorMessage: response.message }
            ));
        }
    };
};

export const fetchMonthlyCompare = ({ fintechId, transactionId, firstMonth,secondMonth }) => {
    return async (dispatch) => {
        
        const response = await fintechReportsService.getMonthlyComparison({
                                fintechId,
                                transactionId, firstMonth,secondMonth});
        if (response instanceof BillingReportsCompareResponse) {

            dispatch(managementActions.fetchMonthlyCompareSuccess(
                { data: { 
                    firstMonth:     {...response.firstMonth},
                    secondMonth:    {...response.secondMonth},
                } }
            ));
        } else if (response instanceof ErrorDetailsResponse) {
            dispatch(managementActions.fetchMonthlyCompareFailed(
                { errorMessage: response.message }
            ));
        } else {
            dispatch(managementActions.fetchMonthlyCompareFailed(
                { errorMessage: response.message }
            ));
        }
    };
};

export const fetchAdvancedSearchReportsDetails = ({ fintechId, transactionId, searchParams }) => {
    return async (dispatch) => {
        const response = await fintechReportsService.getReportsDetailsWithAdvancedSearch({ fintechId, transactionId, searchParams });
        if (response instanceof BillingReportsDetailsResponse) {
            dispatch(managementActions.fetchAdvancedSearchReportsDetailsSuccess(
                { data: { ...response } }
            ));
        } else if (response instanceof ErrorDetailsResponse) {
            dispatch(managementActions.fetchAdvancedSearchReportsDetailsFailed(
                { errorMessage: response.message }
            ));
        } else {
            dispatch(managementActions.fetchAdvancedSearchReportsDetailsFailed(
                { errorMessage: response.message }
            ));
        }
    };
};
