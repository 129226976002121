import React from 'react';
import PropTypes from 'prop-types';

const ViewTitle = props => {
    const fontColor = props.warning ? 'red' : '';

    return (
        <div>
            <h3 style={{
                fontSize: '1.1rem',
                margin: "15px 0px 0px 0px",
                fontWeight: "500", color: `${fontColor}`
            }}>{props.title}</h3>

            

        </div>
    );
};

ViewTitle.propTypes = {
    title: PropTypes.string,
    sub: PropTypes.string,
    warning: PropTypes.bool
};

export default ViewTitle;