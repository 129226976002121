//import { Consents,AccountAccess } from "../../swagger/codeGen/PSD2-XS2AFramework-1.4.2/src/index";
// import { Consents as ConsentsOneFourZero ,AccountAccess as AccountAccessOneFourZero } from "../../swagger/codeGen/PSD2-BOI-1.4.0/src/index";
// import { Consents as ConsentsOneFourTwo ,AccountAccess as AccountAccessOneFourTwo } from "../../swagger/codeGen/PSD2-BOI-1.4.2/src/index";



export const consentTypes = {
    DEDICATED: "dedicated",
    AVAILABLE_ACCOUNTS_WITH_BALANCE: "availableAccountsWithBalance",
    AVAILABLE_ACCOUNTS: "availableAccounts",
    BANK_OFFER: "bankOffer",
};

export class CommonHelper {

    /**
     * 
     * @param {*} psd2Version 
     */
    constructor(psd2Version) {

        this.psd2Version = psd2Version

    }

    // static psdBuilder(psd2Version){
    //     if(psd2Version ==='1.4.0'){
    //         return { ConsentsModel: ConsentsOneFourZero, AccountAccessModel : AccountAccessOneFourZero }
    //     }else{
    //         return { ConsentsModel: ConsentsOneFourTwo, AccountAccessModel : AccountAccessOneFourTwo }
    //     }
    // }
    /**
     * 
     * @param {*} type 
     * @param {*} request 
     * @returns 
     */
    static async initializeConsentObjByType(type, psdVersion, request) {
        const { Consents, AccountAccess } = await import(`../../swagger/codeGen/PSD2-BOI-${psdVersion}/src/index`);
        const consents = new Consents();

        consents.combinedServiceIndicator = false; // TODO: configuration  REACT 
        consents.frequencyPerDay = 100; // TODO: configuration  REACT 
        consents.recurringIndicator = true; // TODO: configuration  REACT 
        consents.validUntil = request.validUntil;

        switch (type) {
            case consentTypes.AVAILABLE_ACCOUNTS_WITH_BALANCE:
                consents.access = AccountAccess.constructFromObject({
                    [type]: AccountAccess.AvailableAccountsEnum.allAccounts,
                    restrictedTo: request.restrictedTo
                });
                break;
            case consentTypes.AVAILABLE_ACCOUNTS:

                consents.access = AccountAccess.constructFromObject({
                    [type]: AccountAccess.AvailableAccountsEnum.allAccounts,
                    restrictedTo: request.restrictedTo
                });
                break;

            default:
                consents.access = AccountAccess.constructFromObject({
                    ...request.access
                });
        }
        return consents;
    }
}
