import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { FintechAdminService } from '../../services/fintechAdmin/finetechAdmin/FintechAdminService';

export const getTheme = createAsyncThunk(
  'theme/getTheme', async ({ transactionId, fintechId }) => {
    const service = new FintechAdminService();
    const response = await service.getStyle({ transactionId, fintechId });
    if (response === null)  {
      alert("There is an issue with your fintech registration. Please contact support.");
      window.location.replace("/");
    }
    return JSON.stringify(response);
  }
);

const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    isLoading: false,
    theme: {
      fontColor: "",
      backgroundColor: "",
      logoUrl: ""
    },
  },
  extraReducers: {
    // getTheme
    [getTheme.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getTheme.fulfilled]: (state, action) => {
      state.theme = JSON.parse(action.payload);
      state.isLoading = false;
    },
    [getTheme.rejected]: (state) => {
      state.isLoading = false;
    },
  }
})

export default themeSlice.reducer;