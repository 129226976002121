import React, { useState } from 'react';
import { Grid, Col, Row, Trash, Button2, Container } from '../';
import AccountInput from '../AccountInput/AccountInput';

const DedicatedBuilder = ({ accountInputPattern, orgInputName, dedicatedAccess, setDedicatedAccess }) => {
    const [dedicatedState, setDedicatedState] = useState({
        accounts: { active: true },
        balances: { active: true },
        transactions: { active: true }
    });

    const [accountNumber, setAccountNumber] = useState("");
    const [currency, setCurrency] = useState("ILS");
    const onChangeActive = e => setDedicatedState({ ...dedicatedState, [e.target.value]: { active: e.target.checked } });
    const changeCurrency = val => setCurrency(val);
    const [isValidAccountNumber, setIsValidAccountNumber] = useState(false);

    const onAddAccount = (e) => {
        e.preventDefault();
        if (dedicatedAccess.balances.length < 10 && dedicatedAccess.accounts.length < 10 && dedicatedAccess.transactions.length < 10 && (dedicatedState.accounts.active || dedicatedState.balances.active || dedicatedState.transactions.active)) {
            let temp = { ...dedicatedAccess };
            if (isValidAccountNumber) {
                for (const key in dedicatedState) {
                    if (dedicatedState[key].active) {
                        const filtered = dedicatedAccess[key].filter(val => {
                            return val[orgInputName] === accountNumber
                        }
                        );
                        if (filtered.length < 1) {
                            if (currency === "ILS") {
                                temp[key].push({ currency, [orgInputName]: accountNumber });
                            }
                            else {
                                temp[key].push({ [orgInputName]: accountNumber });
                            }
                        }
                    }
                }
            }
            setDedicatedAccess({ ...temp });
        }
    }

    const InfoList = ({ accounts, infoKey, infoTitle }) => {
        if (accounts[infoKey].length > 0) {
            return (
                <Container backgroundColor='#eeeeee' marginTop='15px' padding="10px">
                    <h2 className='margin-top-20  no-margin-top rtl'>{infoTitle}</h2>
                    {accounts[infoKey].map((account, index) => {
                        return (
                            <div key={index}>
                                <Grid columns={3}>
                                    <p>{account[orgInputName]}</p>
                                    <p>{!account.currency ? "All" : "ILS"}</p>
                                    <button
                                        className="naked-button"

                                        onClick={() => {
                                            setDedicatedAccess({
                                                ...accounts,
                                                [infoKey]: accounts[infoKey].filter((item) => item[orgInputName] !== account[orgInputName])
                                            })
                                        }
                                        }

                                    ><Trash size="18px" /></button>
                                </Grid>
                            </div>
                        )
                    })}
                </Container>
            )
        }
    }

    return (
        <div className='ltr'>
            <h2 className="consent-h2">{orgInputName === 'maskedPan' ? 'הוספת כרטיסים:' : 'הוספת חשבונות:'}</h2>

            <AccountInput
                disabled={dedicatedAccess.accounts.length > 9 || dedicatedAccess.balances.length > 9 || dedicatedAccess.transactions.length > 9}
                accountNumber={accountNumber}
                maskPattern={accountInputPattern}
                accountInputPattern={accountInputPattern}
                setIsValidAccountNumber={setIsValidAccountNumber}
                setAccountNumber={setAccountNumber}
                orgInputName={orgInputName} />
            {/* <InputText  placeholder={inputsDictionary[orgInputName].name} required change={e => { inputsDictionary[orgInputName].validate(e.target.value) }} value={accountNumber} type="text" /> */}
            <p>ניתן להכניס עד 10 {orgInputName === 'maskedPan' ? 'כרטיסים' : ' חשבונות'}</p>
            <Grid columns={2} marginBottom="20px" height="100px" backgroundColor={"#dddddd"} paddingRight="10px">
                <Col columns={3} alignItems="flex-end">
                    <label>
                        <Row>
                            <p className="consent-p">חשבונות</p>
                            <input value={"accounts"} onChange={onChangeActive} checked={dedicatedState.accounts.active} type="checkbox" />
                        </Row>
                    </label>

                    <label>
                        <Row>
                            <p className="consent-p">יתרות</p>
                            <input value={"balances"} onChange={onChangeActive} checked={dedicatedState.balances.active} type="checkbox" />
                        </Row>
                    </label>

                    <label>
                        <Row>
                            <p className="consent-p">תנועות</p>
                            <input value={"transactions"} onChange={onChangeActive} checked={dedicatedState.transactions.active} type="checkbox" />
                        </Row>
                    </label>
                </Col>

                <Col columns={3} alignItems="flex-end">
                    <label>
                        <Row>
                            <div className="consent-p">שקלים</div>
                            <input value={"ILS"} onChange={() => changeCurrency("ILS")} type="radio" name="currency" checked={currency === "ILS"} />
                        </Row>
                    </label>

                    <label>
                        <Row>
                            <div className="consent-p">כל סוגי המטבעות</div>
                            <input value={"All"} onChange={() => changeCurrency("All")} type="radio" name="currency" checked={currency === "All"} />
                        </Row>
                    </label>

                </Col>

            </Grid>
            <Row justifyContent="flex-end">
                <Button2
                    onClick={onAddAccount}
                    label="הוסף לרשימה"
                    disable={!isValidAccountNumber || dedicatedAccess.accounts.length > 9 || dedicatedAccess.balances.length > 9 || dedicatedAccess.transactions.length > 9 || (!dedicatedState.accounts.active && !dedicatedState.balances.active && !dedicatedState.transactions.active) }
                    rounded
                />
            </Row>
            <InfoList infoTitle={"חשבונות שנבחרו:"} infoKey='accounts' accounts={dedicatedAccess}></InfoList>
            <InfoList infoTitle={"יתרות שנבחרו:"} infoKey='balances' accounts={dedicatedAccess}></InfoList>
            <InfoList infoTitle={"תנועות שנבחרו:"} infoKey='transactions' accounts={dedicatedAccess}></InfoList>
        </div>
    );
};

export default DedicatedBuilder;