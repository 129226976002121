import styled from 'styled-components';

export const AlertContainer = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: ${({ rounded }) => (rounded ? '50px' : '0')};
  background-color: ${({ backgroundColor, disable }) => (disable ? '#f2f2f2' : backgroundColor)};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ disable, color }) => (disable ? '#bebebe' : color)};
  border: 1px solid ${({ disable, color }) => (disable ? '#f2f2f2' : color)};
  cursor: pointer;
  margin-top: ${({ marginTop }) => marginTop};
  padding-right: 20px;
  padding-left: 20px;
  font-weight: 600;
  border-radius: 5px;
`;
