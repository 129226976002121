import React from 'react'
import PropTypes from "prop-types"
import Icon from './Reports.svg'
import { IMG } from './Reports.style'

export default function Reports({
  size = "50px",
  color = "red",
}) {
  return (
    <IMG
      color={color}
      src={Icon}
      alt="v"
      size={size}
    />
  )
}

Reports.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
}
