import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { SideBar, PageContent } from '../../../components';
import { setPage } from '../../../store/webNavigation/webNavSlice';
import { getTheme } from '../../../store/theme/themeSlice';
import { enforceLogin } from '../../../services/enforceLogin';
import { AuthService } from '../../../services/auth/AuthService';

const ProfilePage = () => {
    enforceLogin();
    const dispatch = useDispatch();
    dispatch(setPage('profile'));
    const [profile, setProfile] = useState(null);
    const [userPoolId, setUserPoolId] = useState(null);
    const user = new AuthService({ env: process.env.NODE_ENV });



    useEffect(() => {
        user.currentAuthenticatedUser().then(
            (user) => {
                setProfile(user.attributes);
                setUserPoolId(user.pool.userPoolId);
            }
        )
    }, []);

    if (profile) {
        return (
            <div className='row'>
                <SideBar />
                <PageContent>
                    <h1>Profile</h1>
                    <p>Mail: {profile.email}</p>
                    <p>Organization Id: {profile["custom:fintechId"]}</p>
                    <p>Pool Id: {userPoolId}</p>
                </PageContent>
            </div>
        );
    }
};

export default ProfilePage;