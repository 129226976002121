import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: ${({ width }) => width};
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 25px;
  margin: 20px;
  margin-top: ${({ marginTop }) => marginTop};
  background-color: ${({ backgroundColor }) => backgroundColor};
  filter: drop-shadow(0px 0px 7px #eeeeee);
`