import React from 'react';
import PropTypes from "prop-types"
import { InputContainer } from "./Input.style"

const Input = ({
    min,
    name,
    type,
    onChange,
    value,
    placeholder,
    label,
    disabled,
    width,
    marginBottom,
    marginRight

}) => {
    return (
        <InputContainer
            min={min}
            name={name}
            type={type}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            label={label}
            disabled={disabled}
            width={width}
            marginBottom={marginBottom}
            marginRight={marginRight}
        />
    )
}

Input.propTypes = {
    min: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    width: PropTypes.string,
    marginBottom: PropTypes.string,
    marginRight: PropTypes.string
}

export default Input