import BaseServices from "../../BaseServices"
import RestApi from "../../../utils/libs/RestApi";
import RegistrationRoutes from "../../../resources/constants/Routes/api-routes-registration.json"
import { API_URL, EC2_ENV, REST_TIMEOUT } from "../../../resources/constants/EnvironmentVariables.js";
import {
    UploadCertificateRequest,
    CertificateItem,
    UploadCertificateResponse,
    ErrorDetailsResponse
} from "../../../swagger/codeGen/upload-validate-certificate-by-type/src/index.js";

import {
    EnrollFintechRequest,
    EnrollFintechResponse,
} from "../../../swagger/codeGen/enroll-fintech/src/index.js";
import { API } from "aws-amplify";

export class UploadCertificateService extends BaseServices {
    constructor(env) {
        super(env);
        this.restApi = new RestApi({ env, timeout: REST_TIMEOUT });
        this.apiGatewayPrefix = "/obh-enroll";
    }

    /**
     * 
     * @returns 
     */
    async getAPIGateWayEndpoint() {
        const logFunc = `${this.serviceName} getAPIGateWayEndpoint: `;
        const startTime = new Date();
        this.log.debug(logFunc, 'start');

        const object = { error: true, errorMessage: "Error", data: null }
        const apiName = 'enroll';

        try {
            const baseUrl = await API.endpoint(apiName);
            this.log.debug(logFunc + ' Extracted Server Url : ', baseUrl);
            this.log.audit(logFunc, startTime);
            return { ...object, error: false, data: baseUrl }
        } catch (error) {
            this.log.error(`${logFunc} Error: `, JSON.stringify(error));
            return { ...object, errorMessage: error };
        }
    }

    /**
     * 
     * @param {*} type 
     * @param {*} certificateStream 
     * @returns 
     */
    async uploadCertificate(type, certificateStream, transactionId) {
        const logFunc = `${this.serviceName} uploadCertificate: `;
        const startTime = new Date();
        this.log.debug(logFunc, 'start');

        try {
            //#region upload certificate 
            // init URL request template & parameters 

            if (!RegistrationRoutes['post']) throw new Error("POST is not defined in RegistrationRoutes");
            if (!RegistrationRoutes['post']["state_upload_cert"]) throw new Error("'state_upload_cert' is not defined in RegistrationRoutes");

            const templateUrl = RegistrationRoutes['post']["state_upload_cert"];
            const parameters = {
                "domain-server": API_URL,
                "certificateType": type
            };

            // build request 
            const certificateItems = [new CertificateItem(type, certificateStream)];
            const reqData = { certificateItems };
            const requestBody = UploadCertificateRequest.constructFromObject(reqData, null);
            this.log.debug(logFunc + ' Request Body : ', JSON.stringify(requestBody));

            // execute REST api 
            let axiosRes;

            if (EC2_ENV) {
                axiosRes = await this.restApi.post(templateUrl, parameters, requestBody, { transactionId });
            } else {

                //TODO: BEN move to businessblock
                let { error, errorMessage, data } = await this.getAPIGateWayEndpoint();

                //TODO: BEN Update aws-exports file

                //data <-- "endpoint" selected from aws-exports.js  (amplify config )  based on apiName  
                if (!error) {
                    parameters["domain-server"] = data + this.apiGatewayPrefix;

                    axiosRes = await this.restApi.post(templateUrl, parameters, requestBody, { transactionId });
                }

            }

            // check  & parse the response 
            // parse data response based on HTTP status code 
            // test HTTP status 
            const status = axiosRes.status;
            const data = axiosRes;

            let response;
            if (status === 200)
                response = UploadCertificateResponse.constructFromObject(data.data, null);
            else if ([408, 404, 403, 401, 400, 500].includes(status)) {
                this.log.error(logFunc + ' Server Response Headers: ', JSON.stringify(axiosRes.headers));
                this.log.error(logFunc + ' Server Response Data: ', JSON.stringify(axiosRes.data));
                response = ErrorDetailsResponse.constructFromObject(data.data, null);
            }
            else {
                this.log.error(logFunc + ' Server Response Headers: ', JSON.stringify(axiosRes.headers));
                response = ErrorDetailsResponse.constructFromObject(data.data, null);
            }

            this.log.debug(logFunc + ' Server Response: ', JSON.stringify(data.data));
            this.log.audit(logFunc, startTime);

            return response;
            //#endregion

        } catch (error) {
            const errorObj = ErrorDetailsResponse.constructFromObject("unexpected error", null);
            this.log.error(logFunc, JSON.stringify(errorObj));
        }

        this.log.debug(logFunc, 'end');
        this.log.audit(logFunc, startTime);

    }
    /**
     * 
     * @param {*} submitObj 
     * @returns 
     */
    async enrollFintech(submitObj) {
        const logFunc = `${this.serviceName} enrollFintech: `;
        const startTime = new Date();
        this.log.debug(logFunc, 'start');
        try {
            //#region upload certificate 
            // init URL request template & parameters 

            if (!RegistrationRoutes['post']) throw new Error("POST is not defined in RegistrationRoutes");
            if (!RegistrationRoutes['post']["state_enroll_fintech"]) throw new Error("'state_enroll_fintech' is not defined in RegistrationRoutes");

            const templateUrl = RegistrationRoutes['post']["state_enroll_fintech"];
            const parameters = {
                "domain-server": API_URL
            };

            // build request 
            const certificateItems = Object.values(submitObj.certificateItem);
            const reqData = { ...submitObj, certificateItems };
            const requestBody = EnrollFintechRequest.constructFromObject(reqData, null);

            // execute REST api 
            const { transactionId } = submitObj;
            let axiosRes;
            if (EC2_ENV) {
                axiosRes = await this.restApi.post(templateUrl, parameters, requestBody, { transactionId });
            } else {

                //TODO: Ben mobve to BB
                let { error, errorMessage, data } = await this.getAPIGateWayEndpoint();
                //data <-- "endpoint" selected from aws-exports.js  (amplify config )  based on apiName  
                if (!error) {
                    parameters["domain-server"] = data + this.apiGatewayPrefix;
                    axiosRes = await this.restApi.post(templateUrl, parameters, requestBody, { transactionId });
                }
            }

            // check  & parse the response 
            // parse data response based on HTTP status code 
            let { request, headers, data } = axiosRes;
            const { status } = request;   // test HTTP status 
            let response;
            this.log.debug(logFunc, JSON.stringify(data));

            if (status === 201)
                response = EnrollFintechResponse.constructFromObject(data, null);
            else if ([408, 404, 403, 401, 400, 500].includes(status)) {
                this.log.error(logFunc + ' Server Response Headers: ', JSON.stringify(headers));
                this.log.error(logFunc + ' Server Response Data: ', JSON.stringify(axiosRes.data));
                response = ErrorDetailsResponse.constructFromObject(data, null);
            }
            else {
                response = ErrorDetailsResponse.constructFromObject(data, null);
            }

            this.log.debug(logFunc + ' Response From server: ', JSON.stringify(response));
            this.log.audit(logFunc, startTime);

            return response;
            //#endregion

        } catch (error) {
            const errorObj = ErrorDetailsResponse.constructFromObject("unexpected error", null);
            this.log.error(logFunc, JSON.stringify(errorObj));
        }

        this.log.debug(logFunc, 'end');
        this.log.audit(logFunc, startTime);
    }

    async deleteFintech(requestBody, transactionId) {
        const logFunc = `${this.serviceName} enrollFintech: `;
        const startTime = new Date();
        this.log.debug(logFunc, 'start');

        const templateUrl = RegistrationRoutes['post']["state_remove_fintech"];
        const parameters = {
            "domain-server": API_URL
        };
        let response;
        if (EC2_ENV) {
            response = await this.restApi.post(templateUrl, parameters, requestBody, { transactionId });
        }else{

            const url = templateUrl.replace('#domain-server#', '/obh-enroll');

            const baseUrl = await API.endpoint('enroll');
            response = await this.restApi.post(baseUrl+url, parameters, requestBody, { transactionId });
        }
        const { status } = response;
        const { data } = response;

        if (status === 204)
            response = response;
        else if ([408, 404, 403, 401, 400, 500].includes(status)) {
            this.log.error(logFunc + ' Server Response Headers: ', JSON.stringify(data));
            response = ErrorDetailsResponse.constructFromObject(data, null);
        }
        else {
            response = ErrorDetailsResponse.constructFromObject(data, null);
        }

        return response;
    }



}