import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { P, Card01, PageContent, ButtonOutline, ViewTitle, Button2, InputFile, Row, ProgressCircles, OBlogo, InputColor, Col } from '../../../components/index';
import { enrollFinetechAccount, setStyleAndLogo } from "../../../store/registration/registrationSliceActions";
import CreateFinetechAccount from '../../../resources/constants/StepWFControl/CreateFintechAccount.json';
import { MAX_LOGO_SIZE, LOGO_SIZE_ALERT } from '../../../resources/constants/EnvironmentVariables.js';

const LogoAndStyle = () => {
    const dispatch = useDispatch();
    const step = useSelector((state) => state.navigation.step);
    const navigateTo = useNavigate();
    const registerInfo = useSelector((state) => state.registration);
    const createAccountArray = CreateFinetechAccount['create-account'];
    const [form, setForm] = useState({
        logoStream: "",
        fontColor: "#000000",
        backgroundColor: "#ffffff",
        fontSize: "",
        fontName: ""
    });
    const [fileName, setFileName] = useState('');
    const [fileErrorMessage, setFileErrorMessage] = useState('');
    const [freeze, setFreeze] = useState(false);
    const directToSuccess = () => { navigateTo('/welcome') }
    const [tempLogo, setTempLogo] = useState(null);

    const handleClick = () => {
        setFreeze(true);
        dispatch(enrollFinetechAccount({...registerInfo,
            logoStream: form.logoStream,
        
        }, createAccountArray, directToSuccess))
    };

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
        dispatch(setStyleAndLogo({ [e.target.name]: e.target.value }));
    };

    const handleFileChange = (e) => {
        const reader = new FileReader();
        const readerTwo = new FileReader();
        let okToUpload = true;

        reader.onload = () => {
            setFileName(e.target.files[0].name);
            const type = e.target.files[0].type;
            if (!['image/png', 'image/jpg', 'image/jpeg'].includes(type)) {
                okToUpload = false;
                setFileErrorMessage('Must Be jpeg, png or jpg image');
            } else {
                setFileErrorMessage('');
            }

            const size = e.target.files[0].size;
            if (size > MAX_LOGO_SIZE) {
                okToUpload = false;
                setTempLogo(null);
                setFileErrorMessage(LOGO_SIZE_ALERT, fileName);
            }

            if (okToUpload) {
                setForm({ ...form, logoStream: reader.result })
                dispatch(setStyleAndLogo({ 'logoStream': reader.result }));
            }
        }
        reader.readAsDataURL(e.target.files[0]);
        readerTwo.onload = () => { setTempLogo(readerTwo.result) }
        readerTwo.readAsDataURL(e.target.files[0]);
    };

    return (
        <div className='row'>
            <PageContent bgImage="https://res.cloudinary.com/dojmo7vcc/image/upload/v1671286848/OBH-BG_o2jm1r.jpg">
                <div className='h-center'>
                    <Card01 radius={24} minWidth="700px" padding="55px">
                        <Row marginTop="45px" marginBottom='18px'><OBlogo size="63px" /></Row>
                        <Row marginBottom='24px'><div className="enroll-slogan"><h1>Welcome to Open Banking Hub</h1></div></Row>
                        <ProgressCircles step={4} width="420px" />

                        <ViewTitle title="Add some colors to your consent page" />
                        <P>You can always do that later...</P>

                        <Row marginTop='50px'>
                            <InputColor name="backgroundColor" value={form.backgroundColor} onChange={handleChange} label="Background Color" />
                            <InputColor name="fontColor" value={form.fontColor} onChange={handleChange} label="Text Color" />
                        </Row>

                        <InputFile
                            className="cyLogoFile"
                            isValid={fileErrorMessage === ''}
                            errorMessage={fileErrorMessage}
                            fileName={fileName}
                            title="Upload png, jpeg, webp logo file"
                            change={handleFileChange} />

                        <Row>
                            {tempLogo ? <img className='img-ratio-100' src={tempLogo} alt='Logo' /> : null}
                        </Row>


                        <Col justifyContent="space-between">
                            <Button2
                                className="cySubmit"
                                width="370px"
                                marginTop={'30px'}
                                disable={freeze}
                                backgroundColor={"#115DFC"}
                                size='medium'
                                label={'Continue'}
                                rounded={true}
                                arrow={true}
                                onClick={() => handleClick(createAccountArray, step['step'], 'next')}
                            />

                            <ButtonOutline
                                width="370px"
                                marginTop={'30px'}
                                label={'Back to login'}
                                rounded={true}
                                height="50px"
                                onClick={() => { navigateTo('/') }}
                            />
                        </Col>
                    </Card01>
                </div>
            </PageContent>

        </div>
    );
};

export default LogoAndStyle;