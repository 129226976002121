/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_mobile_analytics_app_id": "af2267312cf348a9a5dc0bfc96dd5221",
    "aws_mobile_analytics_app_region": "eu-west-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "af2267312cf348a9a5dc0bfc96dd5221",
            "region": "eu-west-1"
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "app",
            "endpoint": "https://u3sj4c4lp9.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "enroll",
            "endpoint": "https://xea3l10yy0.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "tppapi",
            "endpoint": "https://8xrrq1kig6.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-1:d80ed83d-a14e-4bae-ad70-1aa216130a61",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_AmnS5uo8J",
    "aws_user_pools_web_client_id": "2vm6jqr7nmu4fltb4jd7lsqvpl",
    "oauth": {
        "domain": "obh-dev.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://main.d25l88edsdobl9.amplifyapp.com/,http://localhost:3000/,http://localhost:3000/,http://localhost:3000/",
        "redirectSignOut": "https://main.d25l88edsdobl9.amplifyapp.com/,http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "obhamplified-20221225145146-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d2a9d0gjoj3olk.cloudfront.net",
    "aws_user_files_s3_bucket": "obhfintechmaterials102752-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "obhFintech-dev",
            "region": "eu-west-1"
        },
        {
            "tableName": "obhFintechConsents-dev",
            "region": "eu-west-1"
        },
        {
            "tableName": "obhSessionManager-dev",
            "region": "eu-west-1"
        }
    ]
};


export default awsmobile;
