import styled from 'styled-components';

export const ContainerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyContent }) => justifyContent};
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
  height: ${({ height }) => height};
  background-color: ${({ backgroundColor }) => backgroundColor};
  margin-bottom:20px;
  margin-top: ${({ marginTop }) => marginTop};
  padding: ${({ padding }) => padding};
`

export const H1 = styled.h1`
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 20px;
  color: #333;
`