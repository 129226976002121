import styled from 'styled-components';

export const SelectOptionStyle = styled.select`

    
    
    direction:  ${props => props.direction || "ltr"};
    width: 100%;
    padding: 5px;
    margin: 10px 0;
`;