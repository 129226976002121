import React from 'react';
import PropTypes from 'prop-types';
import { Container, Inactive, CornerBG, Corner2, Corner, Row } from './SideBarNavBtn.style';
import { Circle, Cog, Reports, User } from '../../';

const SideBarNavBtn = ({ page, currPage }) => {
    let pageName = '';
    if (page === 'reports') { pageName = "Reports" }
    if (page === 'settings') { pageName = "Settings" }
    if (page === 'users') { pageName = "Users" }

    let icon = null;
    if (page === 'reports') { icon = <Reports size="20px" color={page === currPage ? "#ffffff" : "#A1A2A5"} /> }
    if (page === 'settings') { icon = <Cog size="20px" color={page === currPage ? "#ffffff" : "#A1A2A5"} /> }
    if (page === 'users') { icon = <User size="20px" color={page === currPage ? "#ffffff" : "#A1A2A5"} /> }


    return ((
        page === currPage ?
            <div>
                <Row>
                    <div></div>
                    <CornerBG>
                        <Corner />
                    </CornerBG>

                </Row>
                <Container>
                    <Circle size="32px">{icon}</Circle>
                    <p>{pageName}</p>
                </Container>
                <Row>
                    <div></div>
                    <CornerBG>
                        <Corner2 />
                    </CornerBG>

                </Row>
            </div>
            :
            <Inactive>
                <Circle size="32px" color={null}>{icon}</Circle>
                <p>{pageName}</p>
            </Inactive>
    ));
};

SideBarNavBtn.propTypes = {
    children: PropTypes.node,
    page: PropTypes.string,
    currPage: PropTypes.string
};

export default SideBarNavBtn;