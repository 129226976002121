/*
 * billing Reports API 
 * Fintech billing reports
 *
 * OpenAPI spec version: 1.0.5
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.37
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {MonthlyReport} from './MonthlyReport';

/**
 * The BillingReportsResponse model module.
 * @module model/BillingReportsResponse
 * @version 1.0.5
 */
export class BillingReportsResponse {
  /**
   * Constructs a new <code>BillingReportsResponse</code>.
   * @alias module:model/BillingReportsResponse
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>BillingReportsResponse</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/BillingReportsResponse} obj Optional instance to populate.
   * @return {module:model/BillingReportsResponse} The populated <code>BillingReportsResponse</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new BillingReportsResponse();
      if (data.hasOwnProperty('monthlyReport'))
        obj.monthlyReport = MonthlyReport.constructFromObject(data['monthlyReport']);
    }
    return obj;
  }
}

/**
 * @member {module:model/MonthlyReport} monthlyReport
 */
BillingReportsResponse.prototype.monthlyReport = undefined;

