import BaseServices from "../../BaseServices"
import RestApi from "../../../utils/libs/RestApi";
import { API_URL, REST_TIMEOUT, ENV, EC2_ENV } from "../../../resources/constants/EnvironmentVariables.js";
import ConsentRoutes from "../../../resources/constants/Routes/api-routes-consent.json"
import { API } from "aws-amplify";
// import Uuid from "../../../utils/libs/Uuid";
// import { cert, key } from "./mtls/mtlsKeys";
// import { CommonHelper } from "../../../BusinessBlocks/Common/CommonHelper";
// import { OrganizationDetails, ErrorDetailsResponse } from "../../../swagger/codeGen/consent-routes/src/index";
// import { Consents } from "../../../swagger/codeGen/PSD2-BOI-1.5.0/src/model/Consents";

export class TesterService extends BaseServices {

    /**
     * 
     * @param {*} env 
     */
    constructor(env) {
        super(env);
        this.restApi = new RestApi({ env: ENV, timeout: REST_TIMEOUT });
        this.cognitoApiRoutePrefix = "/tpp-api";
        this.lambdaName = "tppapi";
    }


    /**
      * 
      * @param {*} consentID 
      * @returns 
      */
    async getConsent(consentId, psuId, fintechId, TLS, URL) {
        const logFunc = `${this.serviceName} getConsent: `;

        try {
            if (!ConsentRoutes['get']) throw new Error("get is not defined in RegistrationRoutes");
            if (!ConsentRoutes.get.get_consent_tester) throw new Error("'get_consent' is not defined in RegistrationRoutes");

            const templateUrl = ConsentRoutes.get.get_consent_tester;
            const parameters = { "domain-server": API_URL,'consent-id':consentId };
            const params = { consentId, fintechId, psuId };
            
            const url = this.restApi.urlBuilder(ConsentRoutes.get.get_consent, { "domain-server": URL, 'consent-id':consentId });
            const headers = {
                url: url,
                tls: TLS,
                "Fintech-ID": fintechId, 
                "PSU-ID": psuId,
            }

            let axiosRes;
            if (EC2_ENV) {
                axiosRes = await this.restApi.get(templateUrl, parameters, { ...headers });
            }
            else {
                const url = templateUrl.replace('#domain-server#', '/obh-enroll').replace('#consent-id#', consentId);

                const baseUrl = await API.endpoint('enroll');
                axiosRes = await this.restApi.get(baseUrl + url, parameters, { params });
            }
            try {
                return axiosRes

            }
            catch (error) {
                this.log.error(logFunc, JSON.stringify(error))
            }
        } catch (error) { this.log.error(logFunc, JSON.stringify(error)) }
    } // end of getConsent

    /**
      * 
      * @param {*} consentID 
      * @returns 
      */
    async getAccountList(consentId, psuId, fintechId, TLS, URL) {
        const logFunc = `${this.serviceName} getAccountList: `;

        try {
            if (!ConsentRoutes['get']) throw new Error("get is not defined in RegistrationRoutes");
            if (!ConsentRoutes.get.get_account_list_tester) throw new Error("'get_account_list' is not defined in RegistrationRoutes");

            const templateUrl = ConsentRoutes.get.get_account_list_tester;
            const parameters = { "domain-server": API_URL };
            const params = { "withBalance": false };
            const url = this.restApi.urlBuilder(ConsentRoutes.get.get_account_list, { "domain-server": URL });

            const header = { "Consent-ID": consentId, "Fintech-ID": fintechId, "PSU-ID": psuId, tls: TLS, url }

            let axiosRes;
            if (EC2_ENV) {
                axiosRes = await this.restApi.get(templateUrl, parameters, { ...header, params })
            } else {
                const url = templateUrl.replace('#domain-server#', '/obh-enroll');

                const baseUrl = await API.endpoint('enroll');
                axiosRes = await this.restApi.get(baseUrl + url, parameters, { ...header, params });
            }
            try {
                return axiosRes
            }
            catch (error) {
                this.log.error(logFunc, JSON.stringify(error))
            }
        } catch (error) { this.log.error(logFunc, JSON.stringify(error)) }
    }

    async getTransactionDetails(consentId, psuId, fintechId, accountId, transactionId, TLS, URL) {
        const logFunc = `${this.serviceName} getTransactionDetails: `;
        try {
            if (!ConsentRoutes['get']) throw new Error("get is not defined in RegistrationRoutes");
            if (!ConsentRoutes.get.get_transaction_details_tester) throw new Error("'get_account_details' is not defined in RegistrationRoutes");

            const templateUrl = ConsentRoutes.get.get_transaction_details_tester;
            const parameters = { "domain-server": API_URL, "account-id": accountId, "transaction-id": transactionId };
            const params = { "withBalance": false };
            const url = this.restApi.urlBuilder(ConsentRoutes.get.get_transaction_details, { "domain-server": URL, "account-id": accountId, "transaction-id": transactionId });

            const header = { "Consent-ID": consentId, "Fintech-ID": fintechId, "PSU-ID": psuId, tls: TLS, url }

            let axiosRes;
            if (EC2_ENV) {
                axiosRes = await this.restApi.get(templateUrl, parameters, { ...header, params })
            } else {
                const url = templateUrl.replace('#domain-server#', '/obh-enroll');
                const baseUrl = await API.endpoint('enroll');
                axiosRes = await this.restApi.get(baseUrl + url, parameters, { ...header, params });
            }

            this.log.debug(logFunc, JSON.stringify(axiosRes));
            try {
                return axiosRes

            }
            catch (error) {
                this.log.error(logFunc, JSON.stringify(error))
            }
        }
        catch (error) { this.log.error(logFunc, JSON.stringify(error)) }
    }

    async getAccountDetails(consentId, psuId, fintechId, accountId, TLS, URL) {
        const logFunc = `${this.serviceName} getAccountDetails: `;
        try {
            if (!ConsentRoutes['get']) throw new Error("get is not defined in RegistrationRoutes");
            if (!ConsentRoutes.get.get_account_details_tester) throw new Error("'get_account_details' is not defined in RegistrationRoutes");

            const templateUrl = ConsentRoutes.get.get_account_details_tester;
            const parameters = { "domain-server": API_URL, "account-id": accountId };
            const params = { "withBalance": false };
            const url = this.restApi.urlBuilder(ConsentRoutes.get.get_account_details, { "domain-server": URL, "account-id": accountId });
            const header = { "Consent-ID": consentId, "Fintech-ID": fintechId, "PSU-ID": psuId, tls: TLS, url }

            let axiosRes;
            if (EC2_ENV) {
                axiosRes = await this.restApi.get(templateUrl, parameters, { ...header, params })
            } else {
                const url = templateUrl.replace('#domain-server#', '/obh-enroll');
                const baseUrl = await API.endpoint('enroll');
                axiosRes = await this.restApi.get(baseUrl + url, parameters, { ...header, params });
            }
            this.log.debug(logFunc, JSON.stringify(axiosRes));
            try {
                return axiosRes
            }
            catch (error) {
                this.log.error(logFunc, JSON.stringify(error))
            }
        }
        catch (error) { this.log.error(logFunc, JSON.stringify(error)) }
    }

    async getAccountBalances(consentId, psuId, fintechId, accountId, TLS, URL) {
        const logFunc = `${this.serviceName} getAccountBalances: `;
        try {
            if (!ConsentRoutes['get']) throw new Error("get is not defined in RegistrationRoutes");
            if (!ConsentRoutes.get.get_account_balances_tester) throw new Error("'get_account_balances' is not defined in RegistrationRoutes");

            const templateUrl = ConsentRoutes.get.get_account_balances_tester;
            const parameters = { "domain-server": API_URL, "account-id": accountId };
            const params = { "withBalance": false };
            const url = this.restApi.urlBuilder(ConsentRoutes.get.get_account_balances, { "domain-server": URL, "account-id": accountId });
            const header = { "Consent-ID": consentId, "Fintech-ID": fintechId, "PSU-ID": psuId, url, tls: TLS }

            let axiosRes;
            if (EC2_ENV) {
                axiosRes = await this.restApi.get(templateUrl, parameters, { ...header, params })
            } else {
                const url = templateUrl.replace('#domain-server#', '/obh-enroll');

                const baseUrl = await API.endpoint('enroll');
                axiosRes = await this.restApi.get(baseUrl + url, parameters, { ...header, params });
            }
            this.log.debug(logFunc, JSON.stringify(axiosRes));
            try {
                return axiosRes

            }
            catch (error) {
                this.log.error(logFunc, JSON.stringify(error))
            }
        }
        catch (error) { this.log.error(logFunc, JSON.stringify(error)) }
    }

    async getAccountTransactions(consentId, psuId, fintechId, accountId, TLS, URL) {
        const logFunc = `${this.serviceName} getAccountTransactions: `;
        try {
            if (!ConsentRoutes['get']) throw new Error("get is not defined in RegistrationRoutes");
            if (!ConsentRoutes.get.get_account_transactions_tester) throw new Error("'get_account_transactions' is not defined in RegistrationRoutes");

            const templateUrl = ConsentRoutes.get.get_account_transactions_tester;
            const parameters = { "domain-server": API_URL, "account-id": accountId };
            const params = { "dateFrom": "2022-04-01", bookingStatus: "booked" };
            const url = this.restApi.urlBuilder(ConsentRoutes.get.get_account_transactions, { "domain-server": URL, "account-id": accountId });

            const header = { "Consent-ID": consentId, "Fintech-ID": fintechId, "PSU-ID": psuId, url, tls: TLS }

            let axiosRes;
            if (EC2_ENV) {
                axiosRes = await this.restApi.get(templateUrl, parameters, { ...header, params });
            } else {
                const url = templateUrl.replace('#domain-server#', '/obh-enroll');

                const baseUrl = await API.endpoint('enroll');
                axiosRes = await this.restApi.get(baseUrl + url, parameters, { ...header, params });
            }

            this.log.debug(logFunc, JSON.stringify(axiosRes));
            try {
                return axiosRes
            }
            catch (error) {
                this.log.error(logFunc, JSON.stringify(error))
            }
        }
        catch (error) { this.log.error(logFunc, JSON.stringify(error)) }
    }
}
