
import { navigationActions } from "./navigationSlice";
import { registrationActions } from "../registration/registrationSlice";
import StepWFControl from "../../utils/libs/StepWFControl";
import { ENV } from "../../resources/constants/EnvironmentVariables.js";

export const navigate = (json, currentStep, direction) => {
  return async (dispatch) => {
    const stepWFControl = new StepWFControl(ENV);
    if (direction === 'next') {
      dispatch(
        navigationActions.navigationSuccess({
          step: stepWFControl.next(json, currentStep),
          navigationError: false
        })
      );
    }
    else if (direction === 'previous') {
      dispatch(
        navigationActions.navigationSuccess({
          step: stepWFControl.previous(json, currentStep),
          navigationError: false
        })
      );
    }
    else if (direction === 'home') {
      dispatch(
        navigationActions.navigationSuccess({
          step: stepWFControl.home(json, currentStep),
          navigationError: false
        })
      );
      dispatch(registrationActions.resetRegistration());
    }
    else {
      navigationActions.navigationSuccess({
        step: {},
        navigationError: true
      });
    }
  };
};
