import React from 'react'
import PropTypes from "prop-types"
import { Container } from "./Card01.style"

export default function Card01({
    darkMode = false,
    dropshadow = false,
    padding = "0px",
    paddingTop = "0px",
    width = "100%",
    minWidth = null,
    height,
    radius = 16,
    backgroundColor = "#ffffff",
    marginRight,
    children,
    marginTop,
    marginBottom
}) {
    return (
        <Container
            marginTop={marginTop}
            marginBottom={marginBottom}
            darkMode={darkMode}
            dropshadow={dropshadow}
            padding={padding}
            paddingTop={paddingTop}
            width={width}
            minWidth={minWidth}
            height={height}
            radius={radius}
            backgroundColor={backgroundColor}
            marginRight={marginRight}
        >
            {children}
        </Container>
    )
}

Card01.propTypes = {
    marginTop: PropTypes.string,
    marginBottom: PropTypes.string,
    darkMode: PropTypes.bool,
    dropshadow: PropTypes.bool,
    padding: PropTypes.string,
    paddingTop: PropTypes.string,
    width: PropTypes.string,
    minWidth: PropTypes.string,
    height: PropTypes.string,
    radius: PropTypes.number,
    backgroundColor: PropTypes.string,
    marginRight: PropTypes.string,
    children: PropTypes.node
}
