import React from 'react';
import PropTypes from 'prop-types';
import { TableContainer, TableBody, TableHeader } from './Table.style';
import { getDateDisplay } from '../../componentsTools/commonFunction/commonFunctions';

const Table = ({ columns, data }) => {


    const certDisplayName = (cert) => {
        switch (cert) {
            case "QSEAL_PUBLIC":
                return "QSeal Public";
            case "QSEAL_PRIVATE":
                return "QSeal Private";
            case "QWAC_PUBLIC":
                return "QWAC Public";
            case "QWAC_PRIVATE":
                return "QWAC Private";
            default:
                return "Unknown Type";
        }
    }

    return (
        <TableContainer>
            <TableHeader>
                {columns.map((column, idx) => <p key={idx}>{column}</p>)}
            </TableHeader>

            {
                data ?
                    data.map((item, idx) => {
                        return (
                            <TableBody key={idx}>
                                <p>{certDisplayName(item["typeCert"])}</p>
                                <p>{item["expiryDate"] &&
                                    getDateDisplay("-", new Date(item["expiryDate"]))
                                }</p>
                            </ TableBody >
                        )
                    })
                    : <p>No Data</p>
            }
        </TableContainer>
    );
};

Table.propTypes = {
    columns: PropTypes.array
};

export default Table;