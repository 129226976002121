/*
 * upload and validate certificates
 * upload and validate certificates
 *
 * OpenAPI spec version: 1.0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The ErrorDetailsResponse model module.
 * @module model/ErrorDetailsResponse
 * @version 1.0.3
 */
export class ErrorDetailsResponse {
  /**
   * Constructs a new <code>ErrorDetailsResponse</code>.
   * @alias module:model/ErrorDetailsResponse
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ErrorDetailsResponse</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ErrorDetailsResponse} obj Optional instance to populate.
   * @return {module:model/ErrorDetailsResponse} The populated <code>ErrorDetailsResponse</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ErrorDetailsResponse();
      if (data.hasOwnProperty('category'))
        obj.category = ApiClient.convertToType(data['category'], 'String');
      if (data.hasOwnProperty('code'))
        obj.code = ApiClient.convertToType(data['code'], 'String');
      if (data.hasOwnProperty('message'))
        obj.message = ApiClient.convertToType(data['message'], 'String');
    }
    return obj;
  }
}

/**
 * Allowed values for the <code>category</code> property.
 * @enum {String}
 * @readonly
 */
ErrorDetailsResponse.CategoryEnum = {
  /**
   * value: "ERROR"
   * @const
   */
  ERROR: "ERROR",

  /**
   * value: "WARNING"
   * @const
   */
  WARNING: "WARNING",

  /**
   * value: "FATAL"
   * @const
   */
  FATAL: "FATAL"
};
/**
 * @member {module:model/ErrorDetailsResponse.CategoryEnum} category
 */
ErrorDetailsResponse.prototype.category = undefined;

/**
 * @member {String} code
 */
ErrorDetailsResponse.prototype.code = undefined;

/**
 * @member {String} message
 */
ErrorDetailsResponse.prototype.message = undefined;

