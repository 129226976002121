import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: ${({ width }) => width};
  margin-bottom: 15px;
  margin-top: ${({ marginTop }) => marginTop};
  padding-bottom: 0px;
`

export const Strip = styled.div`
  width: 100%;
  margin-bottom: 20px;
  `

export const H1 = styled.h1`
  font-size: 24px;
  font-weight: 600;
  font-family: 'Assistant-Regular';
  margin-bottom: 20px;
  color: var(--title-color-dark1);
  margin: 0;
`

export const Underline = styled.div`
  width: 30%;
  height: 2px;
  background-color: #654d77;
  margin-top: 15px;
  
`