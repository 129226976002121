import styled from 'styled-components';

const DARK = '#555555';
const LIGHT = '#ffffff';
const LIGHT_BORDER = '#E8E8E9';

export const Container = styled.div`
  display: inline;
  height: ${({ height }) => height};
  max-width: ${({ width, minWidth }) => (minWidth ? null : width)};
  min-width: ${({ minWidth }) => minWidth};
  border-radius: ${({ radius }) => radius}px;
  background-color: ${({ darkMode }) => (darkMode ? `${DARK}` : `${LIGHT}`)};
  padding: ${({ padding }) => padding};
  padding-top: ${({ paddingTop }) => paddingTop};
  border: 1px solid ${({ darkMode }) => (darkMode ? `${LIGHT_BORDER}` : `${LIGHT_BORDER}`)};
  margin-right: ${({ marginRight }) => marginRight};
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  
  overflow: hidden;
  box-shadow: ${({ dropshadow }) => (dropshadow ? '0px 4px 20px 0px #07256514' : 'none')};
`;
