/*
 * Create fintech account 
 * Enroll new fintech
 *
 * OpenAPI spec version: 1.0.1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The EnrollFintechResponse model module.
 * @module model/EnrollFintechResponse
 * @version 1.0.1
 */
export class EnrollFintechResponse {
  /**
   * Constructs a new <code>EnrollFintechResponse</code>.
   * @alias module:model/EnrollFintechResponse
   * @class
   * @param fintechId {String} fintechId - fintech umnique id ( optional response parameter )
   * @param emailStatus {Boolean} status true- email was sent
   * @param uploadStatus {Boolean} status true- CSS and Image were uploaded
   * @param email {String} fintech status
   */
  constructor(fintechId, emailStatus, uploadStatus, email) {
    this.fintechId = fintechId;
    this.emailStatus = emailStatus;
    this.uploadStatus = uploadStatus;
    this.email = email;
  }

  /**
   * Constructs a <code>EnrollFintechResponse</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/EnrollFintechResponse} obj Optional instance to populate.
   * @return {module:model/EnrollFintechResponse} The populated <code>EnrollFintechResponse</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EnrollFintechResponse();
      if (data.hasOwnProperty('fintechId'))
        obj.fintechId = ApiClient.convertToType(data['fintechId'], 'String');
      if (data.hasOwnProperty('emailStatus'))
        obj.emailStatus = ApiClient.convertToType(data['emailStatus'], 'Boolean');
      if (data.hasOwnProperty('uploadStatus'))
        obj.uploadStatus = ApiClient.convertToType(data['uploadStatus'], 'Boolean');
      if (data.hasOwnProperty('email'))
        obj.email = ApiClient.convertToType(data['email'], 'String');
    }
    return obj;
  }
}

/**
 * fintechId - fintech umnique id ( optional response parameter )
 * @member {String} fintechId
 */
EnrollFintechResponse.prototype.fintechId = undefined;

/**
 * status true- email was sent
 * @member {Boolean} emailStatus
 */
EnrollFintechResponse.prototype.emailStatus = undefined;

/**
 * status true- CSS and Image were uploaded
 * @member {Boolean} uploadStatus
 */
EnrollFintechResponse.prototype.uploadStatus = undefined;

/**
 * fintech status
 * @member {String} email
 */
EnrollFintechResponse.prototype.email = undefined;

