/*
 * Create fintech account 
 * Enroll new fintech
 *
 * OpenAPI spec version: 1.0.1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {EnrollFintechRequest} from '../model/EnrollFintechRequest';
import {EnrollFintechResponse} from '../model/EnrollFintechResponse';
import {ErrorDetailsResponse} from '../model/ErrorDetailsResponse';

/**
* CreateFintechAccount service.
* @module api/CreateFintechAccountApi
* @version 1.0.1
*/
export class CreateFintechAccountApi {

    /**
    * Constructs a new CreateFintechAccountApi. 
    * @alias module:api/CreateFintechAccountApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the createFintechAccount operation.
     * @callback moduleapi/CreateFintechAccountApi~createFintechAccountCallback
     * @param {String} error Error message, if any.
     * @param {module:model/EnrollFintechResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Enroll Fintech Account
     * Enroll Fintech Account
     * @param {String} X_TRANSACTION_ID 
     * @param {Object} opts Optional parameters
     * @param {module:model/EnrollFintechRequest} opts.body 
     * @param {module:api/CreateFintechAccountApi~createFintechAccountCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    createFintechAccount(X_TRANSACTION_ID, opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'X_TRANSACTION_ID' is set
      if (X_TRANSACTION_ID === undefined || X_TRANSACTION_ID === null) {
        throw new Error("Missing the required parameter 'X_TRANSACTION_ID' when calling createFintechAccount");
      }

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        'X-TRANSACTION-ID': X_TRANSACTION_ID
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = EnrollFintechResponse;

      return this.apiClient.callApi(
        '/obh/enroll/fintech', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}