/*
 * Fintech Admin Hub API 
 * Fintech Admin
 *
 * OpenAPI spec version: 1.0.0.1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.36
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The UsersItem model module.
 * @module model/UsersItem
 * @version 1.0.0.1
 */
export class UsersItem {
  /**
   * Constructs a new <code>UsersItem</code>.
   * @alias module:model/UsersItem
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>UsersItem</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/UsersItem} obj Optional instance to populate.
   * @return {module:model/UsersItem} The populated <code>UsersItem</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new UsersItem();
      if (data.hasOwnProperty('firstName'))
        obj.firstName = ApiClient.convertToType(data['firstName'], 'String');
      if (data.hasOwnProperty('lastName'))
        obj.lastName = ApiClient.convertToType(data['lastName'], 'String');
      if (data.hasOwnProperty('email'))
        obj.email = ApiClient.convertToType(data['email'], 'String');
      if (data.hasOwnProperty('companyRole'))
        obj.companyRole = ApiClient.convertToType(data['companyRole'], 'String');
      if (data.hasOwnProperty('groupName'))
        obj.groupName = ApiClient.convertToType(data['groupName'], 'String');
      if (data.hasOwnProperty('userStatus'))
        obj.userStatus = ApiClient.convertToType(data['userStatus'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} firstName
 */
UsersItem.prototype.firstName = undefined;

/**
 * @member {String} lastName
 */
UsersItem.prototype.lastName = undefined;

/**
 * @member {String} email
 */
UsersItem.prototype.email = undefined;

/**
 * @member {String} companyRole
 */
UsersItem.prototype.companyRole = undefined;

/**
 * @member {String} groupName
 */
UsersItem.prototype.groupName = undefined;

/**
 * @member {String} userStatus
 */
UsersItem.prototype.userStatus = undefined;

