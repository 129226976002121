import React from 'react'
import PropTypes from "prop-types"
import Icon from './OBlogo.svg'
import { IMG } from './OBlogo.style'

export default function OBlogo({
  marginTop = "0px",
  marginBottom = "0px",
  size = "50px",
}) {
  return (
    <IMG
      marginTop={marginTop}
      marginBottom={marginBottom}
      src={Icon}
      alt="v"
      size={size}
    />
  )
}

OBlogo.propTypes = {
  size: PropTypes.string,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
}
