import React from 'react';
import PropTypes from 'prop-types';
import { InputTextStyle } from './InputText.Style';

const InputText = ({
    className,
    disabled,
    name,
    value,
    change,
    type = 'text',
    placeholder,
    autocomplete,
    marginBottom
}) => {
    return (

        <InputTextStyle
            className={className}
            disabled={disabled}
            name={name}
            value={value}
            onChange={change}
            placeholder={placeholder}
            type={type}
            autocomplete={autocomplete}
            marginBottom={marginBottom}
        />
    );
};

InputText.propTypes = {
    className: PropTypes.string,
    change: PropTypes.func,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    atocomplete: PropTypes.string,
    marginBottom: PropTypes.string
};

export default InputText;