import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  width: 100%;
  background-color: var(--page-bg);
  min-height: 100vh;
  padding: 20px 40px 20px 40px;
  background-image: url(${({ bgImage }) => bgImage});
`