import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-left: 18px;
  padding-right: 12px;

  width: ${({ width }) => width};
  border: 1px solid #e8e8e9;
  border-radius: 6px;
  height: 46px;
`;

export const Label = styled.div`
  position: absolute;
  font-family: 'Assistant-Regular';


  background-color: #ffffff;
  padding-left: 4px;
  padding-right: 4px;
  z-index: 1;

  color: rgb(91,93,98) !important;
  font-size: 14px;
  font-weight: 400;

  top: -14px;
  left: 12px;
`