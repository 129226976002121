import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from "../../../store/navigation/navigationActions";
import CreateFinetechAccount from '../../../resources/constants/StepWFControl/CreateFintechAccount.json';
import isMailValidation from '../../../componentsTools/validations/isMailValidation';
import passwordValidation from '../../../componentsTools/validations/passwordValidation';
import { Auth } from 'aws-amplify';
import {
    ButtonOutline,
    InputText,
    Button2,
    PageContent,
    P,
    Card01,
    Row,
    OBlogo
} from '../../../components';
import { useNavigate } from 'react-router';

const ResetPassword = () => {
    const dispatch = useDispatch();
    const [userMail, setUserMail] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [isValidMail, setIsValidMail] = useState(false);
    const [isValidPassword, setIsValidPassword] = useState(false);
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [error, setError] = useState(null);

    const navigateTo = useNavigate();
    const createAccountArray = CreateFinetechAccount['create-account'];
    const step = useSelector(state => state.navigation.step);
    const [freezeBtn, setfreezeBtn] = useState(false);

    useEffect(() => {
        setIsValidMail(isMailValidation(userMail));
        setIsValidPassword(passwordValidation(newPassword));
    }, [userMail, newPassword]);

    const onResetClick = () => {
        setError(null);
        setfreezeBtn(true);
        async function resetPassword() {
            try {
                await Auth.forgotPassword(userMail);
                setIsEmailSent(true);
                setfreezeBtn(false);
            }
            catch (err) {
                setError(err.message);
                setfreezeBtn(false);
            }
        }
        setfreezeBtn(false);
        resetPassword();
    }

    const onConfirmClick = () => {
        if (isValidPassword) {

            setError(null);
            setfreezeBtn(true);
            async function resetPassworWithConfirm() {
                try {
                    const response = await Auth.forgotPasswordSubmit(userMail, verificationCode, newPassword);
                    setfreezeBtn(false);
                    if (response === 'SUCCESS') {
                        doBackToLogin(createAccountArray, step['step'], 'home');
                    }
                }
                catch (err) {
                    setError(err.message);
                }
            }
            setfreezeBtn(false);
            resetPassworWithConfirm();
        }
    }

    const doBackToLogin = (json, stepNumber, direction) => {
        dispatch(navigate(json, stepNumber, direction));
        navigateTo(`/`);
    };


    return (
        <div className='row'>

            <PageContent bgImage="https://res.cloudinary.com/dojmo7vcc/image/upload/v1671286848/OBH-BG_o2jm1r.jpg">
                <Card01 radius="24" minWidth="500px" height="720px" padding="55px">
                    <Row marginTop="45px" marginBottom='18px'><OBlogo size="63px" /></Row>
                    <Row marginBottom='24px'><div className="enroll-slogan"><h1>Welcome to Open Banking Hub</h1></div></Row>
                    <Row><div className="enroll-title"><h1>Reset Password</h1></div></Row>

                    <InputText placeholder='Enter your email' change={(e) => { setUserMail(e.target.value); setError(null) }} type="email" value={userMail} />

                    {isEmailSent ?
                        <>  <P marginTop='15px' marginBottom>We sent you an email with a verification code</P>
                            <InputText placeholder='Enter verification number' change={(e) => setVerificationCode(e.target.value)} type="text" value={verificationCode} name="verification" />
                            <InputText marginBottom="0px" placeholder='Your New Password' change={(e) => setNewPassword(e.target.value)} type="password" value={newPassword} />
                            <p className='font10'>{isValidPassword ? '✓ Password is valid' : 'You need 8 characters, uppercase, number, and a special character'}</p>
                        </> : null}
                    <Button2
                        onClick={!isEmailSent ? onResetClick : onConfirmClick}
                        disable={!isValidMail || (isEmailSent && !isValidPassword) || freezeBtn}
                        label={!isEmailSent ? "Reset" : "Reset Password"}
                        rounded={true}
                        width='100%'
                        size='medium'
                    />
                    {error ? <P color="#115DFC">{error}</P> : null}


                    <ButtonOutline
                        marginTop='25px'
                        label='Back to login'
                        rounded={true}
                        width='100%'
                        onClick={() => doBackToLogin(createAccountArray, step['step'], 'home')}
                    />

                </Card01>
            </PageContent>
        </div>

    );
};

export default ResetPassword;