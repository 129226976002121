import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Link } from "react-router-dom";
import { UserCompContainer } from "./UserComp.style"
import { AuthService } from '../../../services/auth/AuthService';
import { useSelector, useDispatch } from 'react-redux'
import { signOut } from '../../../store/loggedUser/loggedUserSlice';
import { ButtonOutline } from '../../';

const UserComp = () => {
    const dispatch = useDispatch();
    const navigateTo = useNavigate();
    const currPage = useSelector(state => state.webNav.page);
    const authService = new AuthService({ env: process.env.NODE_ENV });
    const [user, setUser] = useState(null);

    useEffect(() => {
        const getUser = async () => {
            const user = await authService.currentAuthenticatedUser();
            if (user.attributes) {
                setUser(user.attributes.email);
            }
        }
        if (currPage && !["home","ignore"].includes(currPage) ){
            getUser();
        }

    }, [currPage]);

    const doSignOut = () => {
        authService.signOut().then(
            () => {
                dispatch(signOut());
                navigateTo('/')
            }
        )
    }

    if (!["home","ignore"].includes(currPage) ) {
        return <UserCompContainer>
            <Link to="/profile"><p fontWeight='400'>{user}</p></Link>
            <ButtonOutline
                onClick={() => { doSignOut() }}
                label="Logout"
                color="#115DFC"
                size='small'



            >SignOut</ButtonOutline>
        </UserCompContainer>
    }
    return null;
}

export default UserComp