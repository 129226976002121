import React from 'react';
import { useDispatch } from 'react-redux'
import { SideBar, PageContent } from '../../../components';
import UsersPage from '../../../components/UsersPage/UsersPage';
import { setPage } from '../../../store/webNavigation/webNavSlice';
import { enforceLogin } from '../../../services/enforceLogin';

const FintechUsers = () => {
    enforceLogin();
    const dispatch = useDispatch();
    dispatch(setPage('users'));

    return (
        <div className='row'>
            <SideBar />
            <PageContent justifyContent='start'>
                <UsersPage />
            </PageContent>
        </div>
    );
};

export default FintechUsers;