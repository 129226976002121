import React from 'react';
import './App.css';
import Header from './components/Header/Header';
import FintechApp from './apps/FintechApp';

function App() {
  return (

      <div className="App">
        <Header />
        <FintechApp />
      </div>
  );
}

export default App;
