import { Vicon, Xicon } from '../index'

export const columns = [
    {
        title: 'Time Created',
        dataIndex: 'createdAt',
        key: 'createdAt',
        // sorter: (a, b) => a.createdAt - b.createdAt,
        render: (_, record) => (<>{record.createdAt.split('T')[0] + ' | ' + record.createdAt.split('T')[1].split('.')[0]}</>),
        width: '20%',

    },
    {
        title: 'Billing Rate',
        dataIndex: 'billingRate',
        key: 'billingRate',
        // sorter: (a, b) => a.billingRate - b.billingRate,
        width: '10%',
    },
    {
        title: 'API Name',
        dataIndex: 'apiDisplayName',
        key: 'apiDisplayName',
        width: '12%',

    },
    {
        title: 'Billable',
        dataIndex: 'billingIndication',
        key: 'billingIndication',
        align: 'center',
        // filters: [
        //     { text: 'Yes', value: true },
        //     { text: 'No', value: false },
        // ],
        // onFilter: (value, record) => record.billingIndication === value,
        render: (_, record) => (
            <>{record.billingIndication ? <Vicon size="14px" /> : <Xicon size="14px" />}</>
        ),
        width: '9%',

    },
    {
        title: 'Status',
        dataIndex: 'httpStatus',
        key: 'httpStatus',
        width: '10%',

    },
    {
        title: 'Organization',
        dataIndex: 'organizationName',
        key: 'organizationName',
        // filters: [
        //     { text: 'Bituach Leumi', value: 'Bituach Leumi' },
        //     { text: 'Discount Bank', value: 'Discount Bank' },
        //     { text: 'Bank Hapoalim', value: 'Bank Hapoalim' },
        //     { text: 'Bank Hahayal', value: 'Bank Hahayal' },
        //     { text: 'Clalit Health Services', value: 'Clalit Health Services' },
        //     { text: 'Mizrahi Tefahot Bank', value: 'Mizrahi Tefahot Bank' },
        //     { text: 'HOT telecom', value: 'HOT telecom' },
        // ],
        // onFilter: (value, record) => record.organizationName.indexOf(value) === 0,
    },
];