/*
 * Consents API 
 * Manage consents for Fintech
 *
 * OpenAPI spec version: 1.0.7
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The PsuId model module.
 * @module model/PsuId
 * @version 1.0.7
 */
export class PsuId {
  /**
   * Constructs a new <code>PsuId</code>.
   * psuId string for fintech user ID number
   * @alias module:model/PsuId
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>PsuId</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PsuId} obj Optional instance to populate.
   * @return {module:model/PsuId} The populated <code>PsuId</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PsuId();
    }
    return obj;
  }
}
