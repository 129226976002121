import React from 'react';

import { ButtonGroupStyle } from './ButtonGroup.style';

const ButtonGroup = props => {
    return (
        <ButtonGroupStyle>
            {props.children}
        </ButtonGroupStyle>
    );
};

export default ButtonGroup;