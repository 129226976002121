import BaseServices from "../../BaseServices";
import RestApi from "../../../utils/libs/RestApi";
import { API_URL, EC2_ENV, REST_TIMEOUT } from "../../../resources/constants/EnvironmentVariables.js";
import { FintechAdminRoutes } from "../../../resources/constants/Routes";
import { BillingReportsCompareResponse, BillingReportsDetailsResponse, BillingReportsResponse, ErrorDetailsResponse, } from "../../../swagger/codeGen/billing-reports/src/index";
import { API } from "aws-amplify";
export class FinetechReportsService extends BaseServices {
    constructor(env) {
        super(env);
        this.restApi = new RestApi({ env: this.env, timeout: REST_TIMEOUT });
        this.cognitoApiRoutePrefix = "/monoapp";
        this.lambdaName = "app";
    }

    /**
     * 
     * @param {*} type 
     * @param {*} certificateStream 
     * @returns 
     */
    async getMonthlySummary({ transactionId, fintechId, monthParam }) {

        const logFunc = `${this.serviceName} getMonthlySummary: `;
        const startTime = new Date();
        this.log.debug(logFunc, 'start');
        try {
            //#region upload certificate 
            // init URL request template & parameters 

            if (!FintechAdminRoutes['get']) throw new Error(`${logFunc} GET is not defined in ResetRoutes`);
            if (!FintechAdminRoutes['get'].get_monthly_summary) throw new Error("get_monthly_summary is not defined in RegistrationRoutes");
            const templateUrl = FintechAdminRoutes['get'].get_monthly_summary;
            const parameters = {
                "domain-server": API_URL,
                "monthParam": monthParam ? monthParam : 0,
            };

            // execute REST api 
            // monthParam = o until 36
            let axiosRes;

            if (EC2_ENV) {
                axiosRes = await this.restApi.get(templateUrl, parameters,
                    { transactionId, fintechId });
            } else {
                const url = templateUrl.replace('#domain-server#', this.cognitoApiRoutePrefix);

                //TODO: BEN -  the function can be infra function like Rest API     
                axiosRes = await API.get(this.lambdaName, url,
                    {
                        response: true,
                        queryStringParameters: {
                            "monthParam": monthParam ? monthParam : 0
                        },
                        headers: {
                            "Accept": "application/json", "Content-Type": "application/json",
                            'X-Transaction-Id': transactionId, 'X-Fintech-Id': fintechId
                        }
                    });

            }
            // check  & parse the response 
            // parse data response based on HTTP status code 
            // test HTTP status 
            const status = axiosRes.status;
            const data = axiosRes;
            let response;
            if (status === 200)
                response = BillingReportsResponse.constructFromObject(data.data, null);
            else if ([408, 404, 403, 401, 400, 500].includes(status)) {
                this.log.error(logFunc + ' Server Response Headers: ', JSON.stringify(axiosRes.headers));
                this.log.error(logFunc + ' Server Response Data: ', JSON.stringify(axiosRes.data));
                response = ErrorDetailsResponse.constructFromObject(data.data, null);
            }
            else {
                this.log.error(logFunc + ' Server Response Headers: ', JSON.stringify(axiosRes.headers));
                this.log.error(logFunc + ' Server Response Data: ', JSON.stringify(axiosRes.data));
                response = ErrorDetailsResponse.constructFromObject(data.data, null);
            }

            this.log.debug(logFunc + ' Server Response: ', JSON.stringify(data.data));
            this.log.audit(logFunc, startTime);

            return response;
            //#endregion

        } catch (error) {
            const errorObj = ErrorDetailsResponse.constructFromObject(`${logFunc} unexpected error`, null);
            this.log.error(logFunc, JSON.stringify(errorObj));
            this.log.error(logFunc, error);
        }

        this.log.debug(logFunc, 'end');
        this.log.audit(logFunc, startTime);

    }

    /**
     * 
     * @param {*} type 
     * @param {*} certificateStream 
     * @returns 
     */
    async getMonthlyComparison({ transactionId, fintechId, firstMonth, secondMonth }) {

        const logFunc = `${this.serviceName} getMonthlyComparison: `;
        const startTime = new Date();
        this.log.debug(logFunc, 'start');

        try {
            //#region upload certificate 
            // init URL request template & parameters 

            if (!FintechAdminRoutes['get']) throw new Error(`${logFunc} GET is not defined in ResetRoutes`);
            if (!FintechAdminRoutes['get'].get_monthly_compare) throw new Error("get_monthly_compare is not defined in RegistrationRoutes");
            const templateUrl = FintechAdminRoutes['get'].get_monthly_compare;
            const parameters = {
                "domain-server": API_URL,
                "firstMonth": firstMonth ? firstMonth : 0,
                "secondMonth": secondMonth ? secondMonth : 1,
            };

            // execute REST api 
            // monthParam = o until 36
            let axiosRes;
            if (EC2_ENV) {
                axiosRes = await this.restApi.get(templateUrl, parameters,
                    { transactionId, fintechId });
            } else {
                const url = templateUrl.replace('#domain-server#', this.cognitoApiRoutePrefix);

                //TODO: BEN -  the function can be infra function like Rest API     
                axiosRes = await API.get(this.lambdaName, url,
                    {
                        response: true,
                        queryStringParameters: {
                            "firstMonth": firstMonth ? firstMonth : 0,
                            "secondMonth": secondMonth ? secondMonth : 1,
                        },
                        headers: {
                            "Accept": "application/json", "Content-Type": "application/json",
                            'X-Transaction-Id': transactionId, 'X-Fintech-Id': fintechId
                        }
                    });
            }

            // check  & parse the response 
            // parse data response based on HTTP status code 
            // test HTTP status 
            const status = axiosRes.status;
            const data = axiosRes;
            let response;
            if (status === 200)
                response = BillingReportsCompareResponse.constructFromObject(data.data, null);
            else if ([408, 404, 403, 401, 400, 500].includes(status)) {
                this.log.error(logFunc + ' Server Response Headers: ', JSON.stringify(axiosRes.headers));
                this.log.error(logFunc + ' Server Response Data: ', JSON.stringify(axiosRes.data));
                response = ErrorDetailsResponse.constructFromObject(data.data, null);
            }
            else {
                this.log.error(logFunc + ' Server Response Headers: ', JSON.stringify(axiosRes.headers));
                this.log.error(logFunc + ' Server Response Data: ', JSON.stringify(axiosRes.data));
                response = ErrorDetailsResponse.constructFromObject(data.data, null);
            }

            this.log.debug(logFunc + ' Server Response: ', JSON.stringify(data.data));
            this.log.audit(logFunc, startTime);

            return response;
            //#endregion

        } catch (error) {
            const errorObj = ErrorDetailsResponse.constructFromObject(`${logFunc} unexpected error`, null);
            this.log.error(logFunc, JSON.stringify(errorObj));
            this.log.error(logFunc, JSON.stringify(error));
        }

        this.log.debug(logFunc, 'end');
        this.log.audit(logFunc, startTime);
    }

    /**
     * 
     * @param {*} type 
     * @param {*} certificateStream 
     * @returns 
     */
    async getReportsDetailsWithAdvancedSearch({ transactionId, fintechId, searchParams }) {

        const logFunc = `${this.serviceName} getReportsDetailsWithAdvancedSearch: `;
        const startTime = new Date();
        this.log.debug(logFunc, 'start');

        try {
            //#region upload certificate 
            // init URL request template & parameters 

            if (!FintechAdminRoutes['get']) throw new Error(`${logFunc} GET is not defined in ResetRoutes`);
            if (!FintechAdminRoutes['get'].get_advanced_search) throw new Error("get_advanced_search is not defined in RegistrationRoutes");
            const templateUrl = FintechAdminRoutes['get'].get_advanced_search;
            const parameters = {
                "domain-server": API_URL,
            };

            // execute REST api 
            // monthParam = o until 36
            let axiosRes;

            if (EC2_ENV) {
                axiosRes = await this.restApi.get(templateUrl, parameters, { transactionId, fintechId, params: searchParams });
            } else {
                const url = templateUrl.replace('#domain-server#', this.cognitoApiRoutePrefix);

                //TODO: BEN -  the function can be infra function like Rest API
                axiosRes = await API.get(this.lambdaName, url,
                    {
                        response: true,
                        queryStringParameters: {
                            ...searchParams
                        },
                        headers: {
                            "Accept": "application/json", "Content-Type": "application/json",
                            'X-Transaction-Id': transactionId, 'X-Fintech-Id': fintechId
                        }
                    });
            }

            // check  & parse the response 
            // parse data response based on HTTP status code 
            // test HTTP status 
            const status = axiosRes.status;
            const data = axiosRes;
            let response;
            if (status === 200) {
                response = BillingReportsDetailsResponse.constructFromObject(data.data, null);
            }
            else if ([408, 404, 403, 401, 400, 500].includes(status)) {
                this.log.error(logFunc + ' Server Response Headers: ', JSON.stringify(axiosRes.headers));
                this.log.error(logFunc + ' Server Response Data: ', JSON.stringify(axiosRes.data));
                response = ErrorDetailsResponse.constructFromObject(data.data, null);
            }
            else {
                this.log.error(logFunc + ' Server Response Headers: ', JSON.stringify(axiosRes.headers));
                this.log.error(logFunc + ' Server Response Data: ', JSON.stringify(axiosRes.data));
                response = ErrorDetailsResponse.constructFromObject(data.data, null);
            }

            this.log.debug(logFunc + ' Server Response: ', JSON.stringify(data.data));
            this.log.audit(logFunc, startTime);

            return response;
            //#endregion

        } catch (error) {
            const errorObj = ErrorDetailsResponse.constructFromObject(`${logFunc} unexpected error`, null);
            this.log.error(logFunc, JSON.stringify(errorObj));
            this.log.error(logFunc, JSON.stringify(error));
        }

        this.log.debug(logFunc, 'end');
        this.log.audit(logFunc, startTime);
    }
}
