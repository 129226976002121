import BaseServices from "../../BaseServices";
import RestApi from "../../../utils/libs/RestApi";
import FintechAdminRoutes from "../../../resources/constants/Routes/api-routes-fintech-admin.json";
import { API_URL, EC2_ENV, REST_TIMEOUT } from "../../../resources/constants/EnvironmentVariables.js";
import { ErrorDetailsResponse } from "../../../swagger/codeGen/upload-validate-certificate-by-type/src/index.js";
import { ThemeRequest, ThemeResponse, CertificatesResponse, CertificatesRequest } from "../../../swagger/codeGen/fintech-admin-hub/src/index";
import AmplifyApi from "../../../utils/aws/AmplifyApi/AmplifyApi";
import AmplifyConfig from "../../../utils/aws/AmplifyApi/AmplifyConfig";
import { errorMessages } from "@aws-amplify/datastore/lib-esm/util";

export class FintechAdminService extends BaseServices {

    /**
     * 
     * @param {*} env  env , timeout:
     */
    constructor(env) {

        super(env);

        if (EC2_ENV)
            this.restApi = new RestApi({ env: this.env, timeout: REST_TIMEOUT });
        else {
            this.amplifyApi = new AmplifyApi({ env: this.env, timeout: REST_TIMEOUT });
            this.restApi = new RestApi({ env: this.env, timeout: REST_TIMEOUT });
        }

        //TODO: Ben - please move the B/L as constants ( env )
        this.cognitoApiRoutePrefix = "/monoapp";
        this.lambdaName = "app";
    }

    /**
     * 
     * @param {*} type 
     * @param {*} certificateStream 
     * @returns 
     */
    async getStyle({ transactionId, fintechId }) {

        const logFunc = `${this.serviceName} getStyle: `;
        const startTime = new Date();
        this.log.debug(logFunc, 'start');

        try {
            // init URL request template & parameters 
            if (!FintechAdminRoutes['get']) throw new Error(`${logFunc} GET is not defined in ResetRoutes`);
            if (!FintechAdminRoutes['get'].get_themes) throw new Error("state_reset_fintech_password is not defined in RegistrationRoutes");
            const templateUrl = FintechAdminRoutes['get'].get_themes;
            const parameters = {
                "domain-server": API_URL,
            };

            // execute REST api 
            let retVal;
            if (EC2_ENV) {
                retVal = await this.restApi.get(templateUrl, parameters, { transactionId, fintechId });
            } else {
                // Amplify - -lambda ENROLL 
                parameters["domain-server"] = '/obh-enroll';  // API gateway path 
                //  const baseUrl = await API.endpoint('enroll');

                const { error, errorMessages, url } = await new AmplifyConfig(transactionId, fintechId).getApiGwEndpoint('enroll');
                console.log(' ======= error', error);
                if (error) {
                    throw new Error(errorMessages);
                }
                retVal = await this.restApi.get(url + templateUrl, parameters, { transactionId, fintechId });
            }

            // check  & parse the response 
            // parse data response based on HTTP status code 
            // test HTTP status 
            const status = retVal.status;
            let response;
            if (status === 200)
                response = ThemeResponse.constructFromObject(retVal.data, null);
            else if ([408, 404, 403, 401, 400, 500].includes(status)) {
                this.log.error(logFunc + ' Server Response Headers: ', JSON.stringify(retVal.headers));
                this.log.error(logFunc + ' Server Response  : ', JSON.stringify(retVal.data));
                response = ErrorDetailsResponse.constructFromObject(retVal.data, null);
            }
            else {
                this.log.error(logFunc + ' Server Response Headers: ', JSON.stringify(retVal.headers)); //TODO: test it
                this.log.error(logFunc + ' Server Response  : ', JSON.stringify(retVal.data));
                response = ErrorDetailsResponse.constructFromObject(retVal.data, null);
            }

            this.log.debug(logFunc + ' Server Response: ', JSON.stringify(retVal.data));
            this.log.audit(logFunc, startTime);
            this.log.debug(logFunc, 'end');

            return response;


        } catch (error) {
            const errorObj = ErrorDetailsResponse.constructFromObject(`${logFunc} unexpected error`, null);
            this.log.error(logFunc, JSON.stringify(error));
            return errorObj;
        }
    }

    /**
     * 
     * @param {*} { transactionId, fintechId } 
     * @returns 
     */
    async getCertificates({ transactionId, fintechId }) {

        const logFunc = `${this.serviceName} getCertificates: `;
        const startTime = new Date();
        this.log.debug(logFunc, 'start');

        try {

            // init URL request template & parameters 

            // route templates 
            if (!FintechAdminRoutes['get']) throw new Error(`${logFunc} GET is not defined in ResetRoutes`);
            if (!FintechAdminRoutes['get'].get_certificates) throw new Error("get_certificates is not defined in RegistrationRoutes");

            const templateUrl = FintechAdminRoutes['get'].get_certificates;
            const parameters = {
                "domain-server": API_URL,
            };


            let retVal;
            if (EC2_ENV) {

                retVal = await this.restApi.get(templateUrl, parameters, { transactionId, fintechId });

            } else {
                // Amplify 
                const url = templateUrl.replace('#domain-server#', this.cognitoApiRoutePrefix);

                // //TODO: BEN -  the function can be infra function like Rest API                          
                // retVal = await API.get(this.lambdaName, url,
                //     {
                //         response: true,
                //         headers: {
                //             "Accept": "application/json", "Content-Type": "application/json",
                //             'X-Transaction-Id': transactionId, 'X-Fintech-Id': fintechId
                //         }
                //     });
                retVal = await this.amplifyApi.get(this.lambdaName, url, new AmplifyConfig(this.env, transactionId, fintechId).setRequest());
            }


            // check  & parse the response 
            // parse data response based on HTTP status code 
            // test HTTP status 
            const status = retVal.status;
            let response;
            if (status === 200)
                response = CertificatesResponse.constructFromObject(retVal.data, null);
            else if ([408, 404, 403, 401, 400, 500].includes(status)) {
                this.log.error(logFunc + ' Server Response Headers: ', JSON.stringify(retVal.headers));//TODO: test it 
                this.log.error(logFunc + ' Server Response : ', JSON.stringify(retVal.data));
                response = ErrorDetailsResponse.constructFromObject(retVal.data, null);
            }
            else {
                this.log.error(logFunc + ' Server Response Headers: ', JSON.stringify(retVal.headers));  //TODO: test it 
                this.log.error(logFunc + ' Server Response : ', JSON.stringify(retVal.data));
                response = ErrorDetailsResponse.constructFromObject(retVal.data, null);
            }

            this.log.debug(logFunc + ' Server Response: ', JSON.stringify(retVal.data));
            this.log.audit(logFunc, startTime);
            this.log.debug(logFunc, 'end');

            return response;

        } catch (error) {

            const errorObj = ErrorDetailsResponse.constructFromObject(`${logFunc} Service is temporary unavailable`, null);
            this.log.error(logFunc, JSON.stringify(error));
            return errorObj;
        }

    }

    /**
     * 
     * @returns 
     */
    async updateTheme(requestBody) {
        const logFunc = `${this.serviceName} updateTheme: `;
        const startTime = new Date();
        this.log.debug(logFunc, 'start');

        try {

            // init URL request template & parameters 
            if (!FintechAdminRoutes['put']) throw new Error(`${logFunc} PUT is not defined in ResetRoutes`);
            if (!FintechAdminRoutes['put'].update_theme) throw new Error("update_theme is not defined in RegistrationRoutes");
            const templateUrl = FintechAdminRoutes['put'].update_theme;
            const parameters = {
                "domain-server": API_URL,
            };

            const request = ThemeRequest.constructFromObject(requestBody, null);
            let retVal;
            if (EC2_ENV) {

                retVal = await this.restApi.put(templateUrl, parameters, request,
                    {
                        transactionId: requestBody.transactionId,
                        fintechId: requestBody.fintechId
                    });

            } else {

                // Amplify 

                const url = templateUrl.replace('#domain-server#', this.cognitoApiRoutePrefix);

                //TODO: BEN -  the function can be infra function like Rest API     
                // axiosRes = await API.put(this.lambdaName, url,
                //     {
                //         response: true,
                //         body: request,
                //         headers: {
                //             "Accept": "application/json", "Content-Type": "application/json",
                //             'X-Transaction-Id': requestBody.transactionId, 'X-Fintech-Id': requestBody.fintechId
                //         }
                //     });

                retVal = await this.amplifyApi.put(this.lambdaName, url,
                    new AmplifyConfig(this.env, requestBody.transactionId, requestBody.fintechId).setRequest(request));
            }
            // check  & parse the response 
            // parse data response based on HTTP status code 
            // test HTTP status 
            const status = retVal.status;
            let response;
            if (status === 200)
                response = ThemeResponse.constructFromObject(retVal.data, null);
            else if ([408, 404, 403, 401, 400, 500].includes(status)) {
                this.log.error(logFunc + ' Server Response Headers: ', JSON.stringify(retVal.headers));
                this.log.error(logFunc + ' Server Response  : ', JSON.stringify(retVal.data));
                response = ErrorDetailsResponse.constructFromObject(retVal.data, null);
            }
            else {
                this.log.error(logFunc + ' Server Response Headers: ', JSON.stringify(retVal.headers));
                this.log.error(logFunc + ' Server Response  : ', JSON.stringify(retVal.data));
                response = ErrorDetailsResponse.constructFromObject(retVal.data, null)
            }

            this.log.debug(logFunc + ' Server Response: ', JSON.stringify(retVal.data));
            this.log.audit(logFunc, startTime);
            this.log.debug(logFunc, 'end');
            return response;


        } catch (error) {
            const errorObj = ErrorDetailsResponse.constructFromObject(`${logFunc} Service is temporary unavailable`, null);
            this.log.error(logFunc, JSON.stringify(error));
            return errorObj;
        }
    }

    /**
     * 
     * @param {*} param0 
     * @returns 
     */
    async updateCertificates({ certificateObject, transactionId, fintechId }) {

        const logFunc = `${this.serviceName} updateCertificates: `;
        const startTime = new Date();
        this.log.debug(logFunc, 'start');

        try {

            // init URL request template & parameters 
            // routes 
            if (!FintechAdminRoutes['put']) throw new Error(`${logFunc} PUT is not defined in ResetRoutes`);
            if (!FintechAdminRoutes['put'].update_certificates) throw new Error(`${logFunc} update_certificates is not defined in RegistrationRoutes`);

            // build request URL 
            const templateUrl = FintechAdminRoutes['put'].update_certificates;
            const parameters = {
                "domain-server": API_URL,
            };

            const certificateItems = Object.values(certificateObject);
            const request = CertificatesRequest.constructFromObject({ certificateItems }, null);

            let retVal;
            if (EC2_ENV) {
                retVal = await this.restApi.put(templateUrl, parameters, request, { transactionId, fintechId });
            } else {
                const url = templateUrl.replace('#domain-server#', this.cognitoApiRoutePrefix);
                console.log('===url', url);
                //TODO: BEN -  the function can be infra function like Rest API     
                // axiosRes = await API.put(this.lambdaName, url,
                //     {
                //         response: true,
                //         body: request,
                //         headers: {
                //             "Accept": "application/json", "Content-Type": "application/json",
                //             'X-Transaction-Id': transactionId, 'X-Fintech-Id': fintechId
                //         }
                //     });

                retVal = await this.amplifyApi.put(this.lambdaName, url,
                    new AmplifyConfig(this.env, transactionId, fintechId).setRequest(request));
            }

            // check  & parse the response 
            // parse data response based on HTTP status code 
            // test HTTP status 
            const status = retVal.status;

            let response;
            if (status === 200)
                response = CertificatesResponse.constructFromObject(retVal.data, null);
            else if ([408, 404, 403, 401, 400, 500].includes(status)) {
                this.log.error(logFunc + ' Server Response Headers: ', JSON.stringify(retVal.headers));
                this.log.error(logFunc + ' Server Response  : ', JSON.stringify(retVal.data));
                response = ErrorDetailsResponse.constructFromObject(retVal.data, null);
            }
            else {
                this.log.error(logFunc + ' Server Response Headers: ', JSON.stringify(retVal.headers)); //TODO:test it 
                this.log.error(logFunc + ' Server Response  : ', JSON.stringify(retVal.data));
                response = ErrorDetailsResponse.constructFromObject(retVal.data, null);
            }

            this.log.debug(logFunc + ' Server Response: ', JSON.stringify(retVal.data));
            this.log.audit(logFunc, startTime);

            return response;

        } catch (error) {
            const errorObj = ErrorDetailsResponse.constructFromObject({ message: `${logFunc} Service is temporary unavailable` }, null);
            this.log.error(logFunc, JSON.stringify(error));
            return errorObj;
        }
    }

    /**
     * 
     * @param {*} logoUrl 
     * @param {*} logoStream 
     */
    async uploadImage(logoUrl, logoStream) {

        const logFunc = `${this.serviceName} uploadImage: `;
        const startTime = new Date();
        this.log.debug(logFunc, 'start');

        const headers = { 'Content-Type': 'image/jpg' }

        try {
            // upload image to AWS S3 using logoUrl=> signed URL 
            let retVal = await this.restApi.uploadImage(headers, logoUrl, logoStream);

            // check  & parse the response 
            // parse data response based on HTTP status code 
            // test HTTP status 
            const status = retVal.status;

            let response;
            if (status === 200) {
                response = { error: false };
            }
            else if ([408, 404, 403, 401, 400, 500].includes(status)) {
                this.log.error(logFunc + ' Server Error Response : ', `request headers ${headers}, response error  ${JSON.stringify(retVal.data)}`);
                this.log.error(logFunc + ' Server Error Response : ', `${JSON.stringify(retVal.data)}`);
                response = ErrorDetailsResponse.constructFromObject(retVal.data, null);
            }
            else {
                this.log.error(logFunc + '  Server Error Response : ', `request headers ${headers}, response error  ${JSON.stringify(retVal.data)}`);
                this.log.error(logFunc + ' Server Error Response : ', `${JSON.stringify(retVal.data)}`);
                response = ErrorDetailsResponse.constructFromObject(retVal.data, null);
            }
            this.log.debug(logFunc + ' Server Response: ', JSON.stringify(retVal.data));
            this.log.audit(logFunc, startTime);

            return response;

        } catch (error) {
            const errorObj = ErrorDetailsResponse.constructFromObject({ message: `${error} Service temporary unavailable` }, null);
            this.log.error(logFunc, JSON.stringify(error));
            return errorObj;
        }
    }
}
