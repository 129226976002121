/*
 * Consents API 
 * Manage consents for Fintech
 *
 * OpenAPI spec version: 1.0.7
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The CreateUserSessionResponse model module.
 * @module model/CreateUserSessionResponse
 * @version 1.0.7
 */
export class CreateUserSessionResponse {
  /**
   * Constructs a new <code>CreateUserSessionResponse</code>.
   * @alias module:model/CreateUserSessionResponse
   * @class
   * @param key {String} sessionId - session unique id for user session
   */
  constructor(key) {
    this.key = key;
  }

  /**
   * Constructs a <code>CreateUserSessionResponse</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CreateUserSessionResponse} obj Optional instance to populate.
   * @return {module:model/CreateUserSessionResponse} The populated <code>CreateUserSessionResponse</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CreateUserSessionResponse();
      if (data.hasOwnProperty('key'))
        obj.key = ApiClient.convertToType(data['key'], 'String');
    }
    return obj;
  }
}

/**
 * sessionId - session unique id for user session
 * @member {String} key
 */
CreateUserSessionResponse.prototype.key = undefined;

