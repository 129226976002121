const ProgressBar = (props) => {
    const { bgcolor, completed,all } = props;
  
    
    const containerStyles = {
      height: 35,
      width: '100%',
      backgroundColor: "#e0e0de",
      borderRadius: 50,
      direction:"ltr",
      margin: "10px 0px"
    }
  
    const fillerStyles = {
      height: '100%',
      width: `${(completed/all)*100}%`,
      backgroundColor: bgcolor,
      borderRadius: 'inherit',
      textAlign: 'right',
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: 'width 1s ease-in-out',
    }
  
    const labelStyles = {
      padding: 5,
      color: 'white',
      fontWeight: 'bold'
    }
  
    return (
      <div style={containerStyles}>
        <div style={fillerStyles}>
          <span style={labelStyles}>{`${completed} / ${all}`}</span>
          {/* <span style={labelStyles}>{`${props.completed}/${props.completed}`}</span> */}
        </div>
      </div>
    );
  };
  
  export default ProgressBar;