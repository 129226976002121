import axios from 'axios';

import UtilsBase from './UtilsBase';

/**
 * Error messages for validation function
 */
const BODY_PARAMS_ERROR_MESSAGE = "CRITICAL ERROR: Body Parameters object is Empty";
const TEMPLATE_URL_ERROR_MESSAGE = "CRITICAL ERROR: Template url string is Empty";
const QUERY_PARAMS_ERROR_MESSAGE = "CRITICAL ERROR: Query Params object is Empty";

export default class RestApi extends UtilsBase {
    /**
     * 
     * @param {*} env 
     */
    constructor(envObj) {

        const { env, timeout } = envObj;
        super(env);
        this.timeout = timeout;
    }

    /*
    * Reusable post and get requests. used from the Services folder
        example : 
        /obh/certificate/upload/{certificateType}/
        /site/{path parameter1}/{path parameterN}/.com?queryParameters
    * 
    * @param {*} url 
    * @param {*} templateUrl 
    */
    async get(templateUrl, parameters, headers) {

        const logFunc = `${this.logName} get: `;
        const startTime = new Date();

        try {
            const url = this.urlBuilder(templateUrl, parameters);
            const config = this.createConfig({ headers, timeout: this.timeout });
            this.validateInput([
                { validate: templateUrl, message: TEMPLATE_URL_ERROR_MESSAGE },
                { validate: parameters, message: QUERY_PARAMS_ERROR_MESSAGE }
            ]);

            let data;

            this.log.debug(logFunc, url);
            this.log.debug(logFunc, JSON.stringify(config));
            data = await axios.get(url, config);
            this.log.debug(logFunc, JSON.stringify(data));
            
            this.log.audit(logFunc, startTime);
            return data;

        } catch (error) {
            this.log.error(`${this.logName}  `, error);
            return error;
        }

    }
    /*
    * DELETE
    * 
    * @param {*} url 
    * @param {*} templateUrl 
    */
    async delete(templateUrl, parameters, headers) {

        const logFunc = `${this.logName} Delete: `;
        const startTime = new Date();
        try {
            const url = this.urlBuilder(templateUrl, parameters);
            const config = this.createConfig({ headers, timeout: this.timeout });

            this.validateInput([
                { validate: templateUrl, message: TEMPLATE_URL_ERROR_MESSAGE },
                { validate: parameters, message: QUERY_PARAMS_ERROR_MESSAGE }
            ]);

            let data;

            this.log.debug(logFunc, url);
            this.log.debug(logFunc, JSON.stringify(config));

            data = await axios.delete(url, config);

            this.log.debug(logFunc, JSON.stringify(data));
            

            this.log.audit(logFunc, startTime);

            return data;

        } catch (error) {
            this.log.error(`${this.logName}     `, error);
            return error;
        }

    }
    /**
     * 
     * @param {*} templateUrl 
     * @param {*} parameters 
     * @param {*} headerParameters 
     * @param {*} bodyParameters 
     * @returns response from api
     */
    async post(templateUrl, parameters, bodyParameters, headers) {

        const logFunc = `${this.logName} Post: `;
        const startTime = new Date();
        try {
            const url = this.urlBuilder(templateUrl, parameters);
            const config = this.createConfig({ headers, timeout: this.timeout });

            this.validateInput([
                { validate: templateUrl, message: TEMPLATE_URL_ERROR_MESSAGE },
                { validate: bodyParameters, message: BODY_PARAMS_ERROR_MESSAGE },
                { validate: parameters, message: QUERY_PARAMS_ERROR_MESSAGE }
            ]);

            let data;

            this.log.debug(`${logFunc} Post To`, url);
            this.log.debug(logFunc, JSON.stringify(config));
            data = await axios.post(url, bodyParameters, config);
            this.log.debug(logFunc, JSON.stringify(data));
            
            this.log.audit(logFunc, startTime);
            return data;
        } catch (error) {
            this.log.error(`${this.logName}  `, error);
            return error.response;
        }
    }

    /**
     * 
     * @param {*} templateUrl 
     * @param {*} parameters 
     * @param {*} bodyParameters 
     * @param {*} headerParameters 
     * @returns 
     */
    async put(templateUrl, parameters, bodyParameters, headers) {
        const logFunc = `${this.logName} get: `;
        const startTime = new Date();
        try {
            const url = this.urlBuilder(templateUrl, parameters);
            const config = this.createConfig({ headers, timeout: this.timeout });
            this.validateInput([
                { validate: templateUrl, message: TEMPLATE_URL_ERROR_MESSAGE },
                { validate: parameters, message: QUERY_PARAMS_ERROR_MESSAGE }
            ]);

            let data;


            this.log.debug(logFunc, url);
            this.log.debug(logFunc, JSON.stringify(config));
            this.log.debug(logFunc, JSON.stringify(bodyParameters));
            data = await axios.put(url, bodyParameters, config);

            this.log.debug(logFunc, JSON.stringify(data));
            

            this.log.audit(logFunc, startTime);
            return data;

        } catch (error) {
            this.log.error(`${this.logName}  `, error);
            return error.response;
        }
    }

    /**
    * 
    * @param {*} headers 
    * @param {*} logoUrl 
    * @param {*} logoStream 
    */
    async uploadImage(headers, logoUrl, logoStream) {

        const logFunc = `${this.logName} uploadImage: `;
        const startTime = new Date();
        try {

            this.log.debug(logFunc, logoUrl);
            this.log.debug(logFunc, JSON.stringify(headers));

            const data = await axios.put(logoUrl, logoStream, { headers });
            this.log.debug(logFunc, JSON.stringify(data));

            this.log.audit(logFunc, startTime);
            return data;

        } catch (error) {
            this.log.error(`${this.logName}  `, error);
            return error;
        }


    }

    /**
     * Takes a base url template and rebuilt it
     * for example: 
     * (#domain-name#/obh/certificate/#certificateType# ,
     * {domain-name:'dns',certificateType:'qseal'})
     * Return dns/obh/certificate/qseal etc... 
     * @param {*} templateUrl 
     * @param {*} arrayParameters 
     * @returns valid url string to server api
     */
    urlBuilder(templateUrl, objectParameters) {
        let url = templateUrl;
        for (const key in objectParameters) {
            url = url.replace(new RegExp(`#${key}#`, 'g'), objectParameters[key]);
        }
        return (url);
    }

    /**
     * 
     * @param {*} configObjectParams 
     * @returns 
     */
    createConfig(configObjectParams) {
        const { headers, timeout } = configObjectParams;
        let obj = {};
        if (headers) {
            obj = { ...obj, headers };
        }
        //set default timeout
        obj = { ...obj, timeout: 15000 };
        //set timeout if exists
        if (timeout) {
            obj = { ...obj, timeout };
        }
        if (headers && headers.transactionId) {
            headers[`X-Transaction-Id`] = headers.transactionId;
            delete headers.transactionId;
            obj = { ...obj, headers };
        }
        if (headers && headers.fintechId) {
            headers[`X-Fintech-Id`] = headers.fintechId;
            delete headers.fintechId;
            obj = { ...obj, headers };
        }
        if (headers && headers.params) {
            let params = headers.params;
            obj = { ...obj, params };
            delete headers.params;
        }
        return obj;
    }

    /**
     * Function to validate that the parameters are entered
     * correctly for each method. 
     * throws an error if one is empty or undefined
     * @param {*} arrayValidationFields 
     */
    validateInput(arrayValidationFields) {

        for (let i = 0; i < arrayValidationFields.length; i++) {
            if (!arrayValidationFields[i].validate) {
                throw Error(arrayValidationFields[i].message);
            }
        }

    }

    /**
     * 
     * @param {*} path 
     * @param {*} pathParams 
     * @returns 
     */
    buildUrlEncoding(path, pathParams) {

        if (!path.match(/^\//)) {
            path = '/' + path;
        }

        var url = this.basePath + path;
        url = url.replace(/\{([\w-]+)\}/g, (fullMatch, key) => {
            var value;
            if (pathParams.hasOwnProperty(key)) {
                value = this.paramToString(pathParams[key]);
            } else {
                value = fullMatch;
            }

            return encodeURIComponent(value);
        });

        return url;
    }


}