import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 300px;
  padding: 5px;
  margin: 20px;
  margin-left: auto;
  margin-right: auto;
`

export const PaginBtn = styled.button`
  width: 22px;
  height: 22px;
  border: none;
  background-color: #fff;
  cursor: pointer;
  font-size: 14px;
  color: #000;
  &:hover {
    color: #000;
  }

  p{
    margin: 0;
  }
`