import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOut, signOutOrRefresh } from '../../store/loggedUser/loggedUserSlice';
import Popup from '../../components/UI/NewUI/Popup/Popup';
import { Button, Card01, Row, Grid } from '../../components';
import { useNavigate } from 'react-router';
import { SECONDS_FOR_POPUP_BEFORE_AUTO_SIGNOUT } from '../../resources/constants/EnvironmentVariables';
import Logger from '../../utils/libs/Logger';
const urlParam = new URLSearchParams(window.location.search).get('log')
const events = ["click"];

const ProtectedRoute = props => {

    const { autoLogoutexpiry } = useSelector(state => state.loggedUser);
    const dispatch = useDispatch();
    const navigateTo = useNavigate();

    const [show, setShow] = useState(false);
    let timer, logOutTimer;

    useEffect(() => {
        if (autoLogoutexpiry) {
            timer = setTimeout(() => { logoutAction() }, (autoLogoutexpiry - SECONDS_FOR_POPUP_BEFORE_AUTO_SIGNOUT * 1000) - (Date.now()));
            logOutTimer = setTimeout(() => {
                dispatch(signOut());
                navigateTo('/');
            }, (autoLogoutexpiry) - (Date.now()));
        }
        return () => { clearTimeout(logOutTimer); clearTimeout(timer); }
    }, [autoLogoutexpiry]);

    const refreshExpiry = () => {
        dispatch(signOutOrRefresh());
    }

    useEffect(() => {
        if (urlParam !== null && urlParam !== undefined) {
            Logger.setMode(urlParam);
        }

        dispatch(signOutOrRefresh());
        Object.values(events).forEach((item) => {
            window.addEventListener(item, refreshExpiry);
        });

        return () => {
            Object.values(events).forEach((item) => {
                window.removeEventListener(item, refreshExpiry);
            });
        }
    }, []);

    // logs out user by clearing out auth token in localStorage and redirecting url to /signin page.
    const logoutAction = () => {
        setShow(true);
    };

    return (
        <>
            <Popup show={show}>
                <Card01 padding="20px" paddingTop='20px' minWidth="500px" height="250px" >
                    <Row><div className="settings-card-title"><h1>Session Expiry </h1></div></Row>
                    <Row
                        style={{ color: "var(--color-font-2)" }}>
                        Your Session is about To Expired. Please press Continue if you want to prolong
                        your session Or Redirect to Login after a while.
                    </Row>
                    <Grid marginTop="40px">
                        <Button text={"Continue"} click={() => {
                            clearTimeout(timer);   // timer popup
                            clearTimeout(logOutTimer);  // clear timer of the auto logout
                            dispatch(signOutOrRefresh());
                            setShow(false);
                        }
                        } />
                        <Button text={"LogOut"} click={() => { dispatch(signOut()); navigateTo('/') }} />
                    </Grid>
                </Card01>
            </Popup>
            {props.children}
        </>
    );
};

export default ProtectedRoute;