import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;

  width: 100%-25px;
  height: 48px;

  border-radius: 50px 0px 0px 50px;
  margin-left: 25px;

  padding: 8px;

  background-color: var(--sidebar-active-btn-bg);
  font-weight: 600;
  
  p {
    color: #115DFC;
    margin-top: 1px;
    margin-left: 15px;
    font-weight: 600;
    font-size: 18px;
    font-family: 'Assistant-Regular', sans-serif;
  }
`

export const CornerBG = styled.div`
  background-color: #F4F5F6;
  width: 24px;
  height: 24px;
`

export const Corner = styled.div`
  top: 40px;
  width: 24px;
  height: 24px;
  background-color: #031332;
  border-radius: 0px 0px 50px 0px;
`
export const Corner2 = styled.div`
  top: 40px;
  width: 24px;
  height: 24px;
  background-color: #031332;
  border-radius: 0px 50px 0px 0px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Inactive = styled.div`
  
  display: flex;
  flex-direction: row;
  justify-content: start;

  width: 100%;
  height: 48px;

  border-radius: 50px 0px 0px 50px;
  margin-left: 25px;
  margin-top: 24px;
  margin-bottom: 24px;

  padding: 8px;
  font-weight: 600;
  
  p {
    color: #A1A2A5;
    margin-top: 1px;
    margin-left: 15px;
    font-weight: 600;
    font-size: 18px;
    font-family: 'Assistant-Regular', sans-serif;
  }
`
