import React, { useEffect, useState } from 'react';
import BarChart from '../UI/Charts/BarChart/BarChart';
// import { Select } from '../';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMonthlyCompare } from '../../store/management/managementActions';
import { Row } from '../';

const getDate = (monthBack) => {
    let ts = new Date(new Date().getFullYear(), new Date().getMonth(), 15);
    if (monthBack < 13 && monthBack > -1) {
        ts = new Date(ts.setMonth(ts.getMonth() - monthBack));
    }
    return {
        value: ts.toISOString(),
        representation: `${ts.toLocaleString('default', { year: "2-digit", month: "short" })}`
    };
}

// const monthArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

const toYM = (date) => {
    const ts = new Date(date);
    let month = ts.getMonth() + 1;
    if (month < 10) { month = `0${month}` }
    return `${ts.getFullYear()}-${month}`;
}


const MonthCompare = () => {
    const { monthlyCompare, transactionId, fintechId } = useSelector((state) => state.management);
    const dispatch = useDispatch();
    const [months, setMonths] = useState({
        secondMonth: getDate(1).value,
        firstMonth: getDate(0).value,
        changed: false
    });

    const onChangeMonth = (e) => {
        let date = e.target.value+'-14T21:00:00.000Z'
        setMonths({ ...months, changed: true, [e.target.name]: date });
    }

    useEffect(() => {
        if (months.changed) {
            dispatch(fetchMonthlyCompare({
                transactionId,
                fintechId,
                ...months
            }));
        }
    }, [months]);

    const prepareDataToBar = (data) => {
        const { firstMonth, secondMonth } = data;
        const triggered = [firstMonth.overallRequests, secondMonth.overallRequests];
        const billed = [firstMonth.overallBilledRequests, secondMonth.overallBilledRequests];
        const failed = [firstMonth.overallErrorRequests, secondMonth.overallErrorRequests];

        const titleOne = new Date(months.firstMonth).toLocaleString('default', { year: "2-digit", month: "short" })
        const titleTwo = new Date(months.secondMonth).toLocaleString('default', { year: "2-digit", month: "short" })
        return { triggered, billed, failed, barLabels: [titleOne, titleTwo] }
    }

    return (
        <div>
            <div className="settings-card-title">
                <Row marginBottom="20px" justifyContent="start"><h1>Compare month over month</h1></Row>
            </div>

            <input type="month" name="firstMonth" value={toYM(months.firstMonth)} onChange={onChangeMonth} />

            {/* <Select name="firstMonth" value={months.firstMonth} onChange={onChangeMonth}>
                {
                    monthArray.map(item => {
                        return <option key={item} value={getDate(item).value}>
                            {getDate(item).representation}</option>
                    })
                }
            </Select> */}
            <input type="month" name="secondMonth" value={toYM(months.secondMonth)} onChange={onChangeMonth} />

            {/* <Select name="secondMonth" value={months.secondMonth} onChange={onChangeMonth}>
                {
                    monthArray.map(item => {
                        return <option key={item} value={getDate(item).value}>
                            {getDate(item).representation}</option>
                    })
                }
            </Select> */}

            <BarChart {...prepareDataToBar(monthlyCompare)} />
        </div>
    );
};

export default MonthCompare;