/*
 * Create fintech account 
 * Enroll new fintech
 *
 * OpenAPI spec version: 1.0.1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {CertificateItem} from './CertificateItem';

/**
 * The EnrollFintechRequest model module.
 * @module model/EnrollFintechRequest
 * @version 1.0.1
 */
export class EnrollFintechRequest {
  /**
   * Constructs a new <code>EnrollFintechRequest</code>.
   * @alias module:model/EnrollFintechRequest
   * @class
   * @param fintechId {String} fintechId - fintech umnique id ( optional response parameter )
   * @param user {String} fintech  user admin
   * @param password {String} fintech  password admin
   * @param certificateItems {Array.<module:model/CertificateItem>} 
   */
  constructor(fintechId, user, password, certificateItems) {
    this.fintechId = fintechId;
    this.user = user;
    this.password = password;
    this.certificateItems = certificateItems;
  }

  /**
   * Constructs a <code>EnrollFintechRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/EnrollFintechRequest} obj Optional instance to populate.
   * @return {module:model/EnrollFintechRequest} The populated <code>EnrollFintechRequest</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EnrollFintechRequest();
      if (data.hasOwnProperty('fintechId'))
        obj.fintechId = ApiClient.convertToType(data['fintechId'], 'String');
      if (data.hasOwnProperty('user'))
        obj.user = ApiClient.convertToType(data['user'], 'String');
      if (data.hasOwnProperty('password'))
        obj.password = ApiClient.convertToType(data['password'], 'String');
      if (data.hasOwnProperty('logoStream'))
        obj.logoStream = ApiClient.convertToType(data['logoStream'], 'String');
      if (data.hasOwnProperty('fontColor'))
        obj.fontColor = ApiClient.convertToType(data['fontColor'], 'String');
      if (data.hasOwnProperty('backgroundColor'))
        obj.backgroundColor = ApiClient.convertToType(data['backgroundColor'], 'String');
      if (data.hasOwnProperty('fontSize'))
        obj.fontSize = ApiClient.convertToType(data['fontSize'], 'String');
      if (data.hasOwnProperty('fontName'))
        obj.fontName = ApiClient.convertToType(data['fontName'], 'String');
      if (data.hasOwnProperty('certificateItems'))
        obj.certificateItems = ApiClient.convertToType(data['certificateItems'], [CertificateItem]);
    }
    return obj;
  }
}

/**
 * fintechId - fintech umnique id ( optional response parameter )
 * @member {String} fintechId
 */
EnrollFintechRequest.prototype.fintechId = undefined;

/**
 * fintech  user admin
 * @member {String} user
 */
EnrollFintechRequest.prototype.user = undefined;

/**
 * fintech  password admin
 * @member {String} password
 */
EnrollFintechRequest.prototype.password = undefined;

/**
 * logo stream
 * @member {String} logoStream
 */
EnrollFintechRequest.prototype.logoStream = undefined;

/**
 * font color
 * @member {String} fontColor
 */
EnrollFintechRequest.prototype.fontColor = undefined;

/**
 * backgroundColor color
 * @member {String} backgroundColor
 */
EnrollFintechRequest.prototype.backgroundColor = undefined;

/**
 * font size
 * @member {String} fontSize
 */
EnrollFintechRequest.prototype.fontSize = undefined;

/**
 * font name
 * @member {String} fontName
 */
EnrollFintechRequest.prototype.fontName = undefined;

/**
 * @member {Array.<module:model/CertificateItem>} certificateItems
 */
EnrollFintechRequest.prototype.certificateItems = undefined;

