
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SectionContainer, Input, Button2, Alert, Row, Col, Card01, Grid, Cog, Trash, ButtonOutline, Popup } from '..';
import { AuthService } from '../../services/auth/AuthService';
import 'antd/dist/antd.min.css';
import { Table } from 'antd';
import isMailValidation from '../../componentsTools/validations/isMailValidation';

const UsersPage = () => {
    const { fintechId, transactionId } = useSelector((state) => state.management);
    const [formEmail, setFormEmail] = useState('');
    const [formFirstName, setFormFirstName] = useState('');
    const [formLastName, setFormLastName] = useState('');
    const [formCompanyRole, setFormCompanyRole] = useState('');
    const [formUserGroup, setUserGroup] = useState('Billing');

    const [currUserGroup, setCurrUserGroup] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [isEditMode, setEditMode] = useState(false);

    const [isDisabled, setDisabled] = useState(false);
    const [popup, setPopup] = useState(false);

    const [sentMail, setSentMail] = useState('');
    const [response, setResponse] = useState('');
    const [response2, setResponse2] = useState('');
    const [reload, setReload] = useState(false);
    const [userPoolId, setUserPoolId] = useState(null);

    const authService = new AuthService({ env: process.env.NODE_ENV });

    const clearForm = () => {
        setEditMode(false);
        setFormEmail('');
        setFormFirstName('');
        setFormLastName('');
        setFormCompanyRole('');
    }

    const switchToEdit = (e) => {
        setEditMode(true);
        setFormEmail(e.email);
        setFormFirstName(e.firstName);
        setFormLastName(e.lastName);
        setFormCompanyRole(e.companyRole);
        setUserGroup(e.groupName);
    }

    useEffect(() => {
        authService.currentAuthenticatedUser().then(
            (user) => {
                // setCurrUserGroup(user.attributes['custom:groupName']);
                setCurrUserGroup(user.signInUserSession.accessToken.payload['cognito:groups'][0]);
                setUserPoolId(user.pool.userPoolId)
            }
        );

        authService.getUsersList(fintechId, transactionId).then(
            (res) => {
                setTableData(
                    res.data.map((e) => {
                        return { ...e, key: e.email }
                    }
                    )
                )
            }
        )

    }, []);


    useEffect(() => {
        if (reload) {
            authService.getUsersList(fintechId, transactionId).then(
                (res) => {
                    setTableData(res.data.map((e) => { return { ...e, key: e.email } }))
                    setReload(false);
                }
            )
        }
    }, [reload]);

    const deleteUser = (userName) => {
        authService.deleteUser({ fintechId, transactionId }, { userName }).then(
            () => { setReload(true) }
        )
    }

    const columns = [
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '19%',
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
            width: '12%',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
            width: '12%',
        },
        {
            title: 'User Group',
            dataIndex: 'groupName',
            key: 'groupName',
            width: '12%',
        },
        {
            title: 'Company Role',
            dataIndex: 'companyRole',
            key: 'description',
            width: '12%',
        },
        {
            title: 'User Status',
            dataIndex: 'userStatus',
            key: 'userStatus',
            width: '10%',
        },
        {
            title: 'Action',
            key: 'operation',
            fixed: 'right',
            width: '10%',
            render: (e) =>
                <div>
                    <button style={{ color: "red", }} className="naked-button" onClick={() => {
                        switchToEdit(e);
                        setPopup(true);
                    }
                    }>
                        <Trash size="20px" color="#aaaaaa" />
                    </button>
                    <button className="naked-button" onClick={() => {
                        switchToEdit(e)
                        setResponse(null);
                        setResponse2(null);
                    }}>
                        <Cog size="20px" color="#aaaaaa" marginleft="20px" />
                    </button>
                </div>
        },
    ];

    const doEditUser = () => {
        setDisabled(true);
        authService.updateUser({ fintechId, transactionId }, {
            userName: formEmail.trim(),
            userItem: {
                "groupName": formUserGroup,
                "firstName": formFirstName.trim(),
                "lastName": formLastName.trim(),
                "companyRole": formCompanyRole.trim(),
            }
        }).then(res => {
            setResponse(null);
            setResponse2(res);
            clearForm();
            setReload(true);
            setDisabled(false);
            setTimeout(() => { setResponse2(null) }, 5000);
        })
    }

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const filterString = (str) => { return str.replace(/[^a-zA-Z\u05D0-\u05EA\s]/g, "") }
    const filterEmailString = (str) => { return str.replace(/[^a-z0-9A-Z.@_-]/g, "") }

    const doSendInvite = () => {
        const res = authService.createUser({ fintechId, transactionId }, {
            userName: formEmail.trim(),
            userItem: {
                "groupName": formUserGroup,
                "firstName": formFirstName.trim(),
                "lastName": formLastName.trim(),
                "companyRole": formCompanyRole.trim(),
            }
        });
        // At this point, the user is created in the user pool, but not confirmed.
        // Once the user confirms the email, a lambda function will be triggered (addUserToGroup)
        res.then(
            res => {
                setReload(true);
                setResponse2(null);
                setResponse(res);
                setSentMail(formEmail);
                setFormEmail('');
                setTimeout(() => { setResponse(null) }, 5000);

            }
        )
        clearForm();
    }

    if (currUserGroup === 'Admin') {
        return (
            <>
                <Popup show={popup}>
                    <Card01 padding="20px" paddingTop='20px' minWidth="500px" height="250px" >
                        <Row><div className="settings-card-title"><h1>Delete user</h1></div></Row>
                        <Row marginBottom='50px'><div className="settings-card-title"><p>Are you sure you want to delete {formFirstName} {formLastName} ?</p></div></Row>

                        <Row>
                            <Button2
                                label="Delete"
                                rounded
                                backgroundColor="#ff554b"
                                size="medium"
                                width="120px"
                                fontSize="18px"
                                onClick={() => {
                                    setPopup(false);
                                    clearForm();
                                    deleteUser(formEmail);
                                }}
                            >
                                Delete</Button2>
                            <ButtonOutline height='50px' width="120px" label={"Cancel"} onClick={() => { setPopup(false); clearForm() }}>Cancel</ButtonOutline>
                        </Row>
                    </Card01>

                </Popup>
                <SectionContainer justifyContent="start" marginTop={'0px'} title="Users">
                    <Grid columns={1} >
                        <Card01 padding="20px" paddingTop='20px'>
                            <div className="settings-card-title">
                                {isEditMode ? <h1 className="red-text">Edit {formFirstName} {formLastName}</h1> : <h1>Add a new user</h1>}
                                {isEditMode ? <p>{formEmail}</p> : <p>Invite user by email:</p>}
                            </div>

                            <Row>
                                <Col paddingLeft="20px">
                                    <Grid columns={3} >
                                        <div>
                                            {isEditMode ? null :
                                                <Input
                                                    type="text"
                                                    placeholder="Email"
                                                    value={formEmail}
                                                    onChange={(e) => { setFormEmail(filterEmailString(e.target.value)) }}
                                                    width="100%"
                                                    marginBottom="20px"
                                                    marginRight="50px"
                                                />
                                            }
                                            <Input
                                                type="text"
                                                placeholder="Company Role"
                                                value={formCompanyRole}
                                                onChange={(e) => { setFormCompanyRole(e.target.value) }}
                                                width="100%"
                                                marginBottom="20px"
                                                marginRight="50px"
                                            />

                                        </div>
                                        <div>
                                            <Input
                                                type="text"
                                                placeholder="First Name"
                                                value={formFirstName}
                                                onChange={(e) => { setFormFirstName(filterString(e.target.value)) }}
                                                width="100%"
                                                marginBottom="20px"
                                                marginRight="50px"
                                            />
                                            <Input
                                                type="text"
                                                placeholder="Last Name"
                                                value={formLastName}
                                                onChange={(e) => { setFormLastName(filterString(e.target.value)) }}
                                                width="100%"
                                                marginBottom="20px"
                                                marginRight="50px"
                                            />
                                        </div>

                                        <Col alignItems="flex-start" backgroundColor='#f5f5f5' paddingLeft="20px" paddingBottom="20px" height="150px">
                                            <div className="settings-card-title"><p>Assign the user to a user-group:</p></div>

                                            <label>
                                                <Row>
                                                    <input checked={formUserGroup === "Billing"} type="radio" id="Billing" name="Billing" value="Billing" onChange={(e) => setUserGroup(e.target.value)} />
                                                    <p className="no-margin margin-left-10" >Billing</p>
                                                </Row>
                                            </label>

                                            <label>
                                                <Row>
                                                    <input label="Admin" checked={formUserGroup === "Admin"} type="radio" id="Admin" name="Admin" value="Admin" onChange={(e) => setUserGroup(e.target.value)} />
                                                    <p className="no-margin margin-left-10">Admin</p>
                                                </Row>
                                            </label>
                                        </Col>
                                    </Grid>
                                </Col>

                            </Row>
                            <Row marginTop='20px' justifyContent='center'>
                                {isEditMode && <ButtonOutline height="50px" onClick={() => { clearForm() }} rounded={true} label="Cancel" width="200px" margin="10px" />}
                                {isEditMode ?
                                    <Button2 size="medium" onClick={doEditUser} rounded={true} label="Update user" width="200px" margin="10px" backgroundColor='#ff554b' disable={isDisabled} />
                                    : <Button2 disable={!validateEmail(formEmail)} size="medium" onClick={(validateEmail(formEmail) ? doSendInvite : null)} rounded={true} label="Send Invite" width="300px" margin="10px" />}
                            </Row>
                            {response ? <Alert backgroundColor="#fcf1d2" color="#f2c94c" message={`An invitation was sent to ${sentMail}`} marginTop="20px" height="45px" /> : null}
                            {response2 ? <Alert backgroundColor="#fcf1d2" color="#f2c94c" message={`User updated successfully`} marginTop="20px" height="45px" /> : null}
                        </Card01>
                    </ Grid>

                    <Card01 marginTop='30px' padding="20px" paddingTop='20px'>
                        <div className="settings-card-title"><h1>Users</h1></div>
                        <Table dataSource={tableData} columns={columns} size={'small'} />
                    </Card01>
                </SectionContainer>
            </>
        )
    } else {
        return (
            <div>
                <h1>Restricted</h1>
                <p>Contact your admin for access.</p>
            </div>
        )
    }
}

export default UsersPage;
