/*
 * billing Reports API 
 * Fintech billing reports
 *
 * OpenAPI spec version: 1.0.5
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.37
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The RequestCompareKeys model module.
 * @module model/RequestCompareKeys
 * @version 1.0.5
 */
export class RequestCompareKeys {
  /**
   * Constructs a new <code>RequestCompareKeys</code>.
   * @alias module:model/RequestCompareKeys
   * @class
   * @param firstMonth {Date} 
   * @param secondMonth {Date} 
   */
  constructor(firstMonth, secondMonth) {
    this.firstMonth = firstMonth;
    this.secondMonth = secondMonth;
  }

  /**
   * Constructs a <code>RequestCompareKeys</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/RequestCompareKeys} obj Optional instance to populate.
   * @return {module:model/RequestCompareKeys} The populated <code>RequestCompareKeys</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new RequestCompareKeys();
      if (data.hasOwnProperty('firstMonth'))
        obj.firstMonth = ApiClient.convertToType(data['firstMonth'], 'Date');
      if (data.hasOwnProperty('secondMonth'))
        obj.secondMonth = ApiClient.convertToType(data['secondMonth'], 'Date');
    }
    return obj;
  }
}

/**
 * @member {Date} firstMonth
 */
RequestCompareKeys.prototype.firstMonth = undefined;

/**
 * @member {Date} secondMonth
 */
RequestCompareKeys.prototype.secondMonth = undefined;

