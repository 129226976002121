import styled from 'styled-components';

export const SelectContainer = styled.select`
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.65);
    display: inline-block;
    font-size: 14px;
    height: 32px;
    line-height: 1.5;
    margin-right: 10px;
    outline: 0;
    padding: 4px 11px;
    transition: all 0.3s;
    width: 150px;
    margin-bottom: 20px;
    &:focus {
        border-color: #40a9ff;
        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }

`;
