/*
 * Fintech Admin Hub API 
 * Fintech Admin
 *
 * OpenAPI spec version: 1.0.0.1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.36
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The ThemeResponse model module.
 * @module model/ThemeResponse
 * @version 1.0.0.1
 */
export class ThemeResponse {
  /**
   * Constructs a new <code>ThemeResponse</code>.
   * @alias module:model/ThemeResponse
   * @class
   * @param fontColor {String} 
   * @param backgroundColor {String} 
   */
  constructor(fontColor, backgroundColor) {
    this.fontColor = fontColor;
    this.backgroundColor = backgroundColor;
  }

  /**
   * Constructs a <code>ThemeResponse</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ThemeResponse} obj Optional instance to populate.
   * @return {module:model/ThemeResponse} The populated <code>ThemeResponse</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ThemeResponse();
      if (data.hasOwnProperty('fontColor'))
        obj.fontColor = ApiClient.convertToType(data['fontColor'], 'String');
      if (data.hasOwnProperty('backgroundColor'))
        obj.backgroundColor = ApiClient.convertToType(data['backgroundColor'], 'String');
      if (data.hasOwnProperty('logoUrl'))
        obj.logoUrl = ApiClient.convertToType(data['logoUrl'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} fontColor
 */
ThemeResponse.prototype.fontColor = undefined;

/**
 * @member {String} backgroundColor
 */
ThemeResponse.prototype.backgroundColor = undefined;

/**
 * @member {String} logoUrl
 */
ThemeResponse.prototype.logoUrl = undefined;

