import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Container, Container2 } from './SideBar.style';
import { useSelector } from 'react-redux'
import { SideBarNavBtn } from '../../';
import { AuthService } from '../../../services/auth/AuthService';

const SideBar = () => {
    const authService = new AuthService({ env: process.env.NODE_ENV });
    const currPage = useSelector(state => state.webNav.page);
    const [currUserGroup, setCurrUserGroup] = useState(null);

    useEffect(() => {
        authService.currentAuthenticatedUser().then(
            (user) => {
                setCurrUserGroup(user.signInUserSession.accessToken.payload['cognito:groups'][0]);
            }
        );
    }, []);

    return (
        <Container>
            <Container2>
                <Link to="/fintech-reports">
                    <SideBarNavBtn page="reports" currPage={currPage} />
                </Link>

                {currUserGroup === 'Admin' &&
                    <Link className="cySettings" to="/fintech-management">
                        <SideBarNavBtn page="settings" currPage={currPage} />
                    </Link>
                }

                {currUserGroup === 'Admin' &&
                    <Link className="cyUsers" to="/fintech-users">
                        <SideBarNavBtn page="users" currPage={currPage} />
                    </Link>
                }
            </Container2>
        </Container>
    );
};

export default SideBar;