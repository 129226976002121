import React, { useEffect, useState } from 'react';
import { ReportsMonthlySummary, ReportDetailsTable } from '../';
import PieChart from '../UI/Charts/PieChart/PieChart';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCurrentMonthSummary, fetchPreviousMonthSummary } from '../../store/management/managementActions';
import { Container, SectionContainer, Grid, Row, Card01 } from '../';
import MonthCompare from '../MonthCompare/MonthCompare';
import { setFilter } from '../../store/filter/filterSlice';


const ReportPage = () => {
    const { currentMonthSummary, previousMonthSummary, transactionId, fintechId } = useSelector((state) => state.management);
    const dispatch = useDispatch();

    useEffect(() => {
        if (transactionId) {
            dispatch(fetchCurrentMonthSummary({ transactionId, fintechId }))
            dispatch(fetchPreviousMonthSummary({ transactionId, fintechId }))
        } else {
        }
    }, []);

    const cuurentMont = new Date();
    const previous = new Date(new Date().setMonth(cuurentMont.getMonth() - 1));
    if (cuurentMont.getMonth() === previous.getMonth()){
        previous.setDate(0);
    }
    const dateArray = currentMonthSummary.monthlyReport ? [
        currentMonthSummary.monthlyReport.overallBilledRequests,
        currentMonthSummary.monthlyReport.overallErrorRequests,
    ] : [];
    const dataArray = previousMonthSummary.monthlyReport ? [
        previousMonthSummary.monthlyReport.overallBilledRequests,
        previousMonthSummary.monthlyReport.overallErrorRequests,
    ] : [];

    const firstDay = (date) => {
        var year = date.getFullYear();
        var month = (1 + date.getMonth()).toString().padStart(2, '0');
        return year + '-' + month + '-01';
    }

    const lastDayNum = (date) => {
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        return lastDay.getDate();
    }

    const lastDay = (date) => {
        var year = date.getFullYear();
        var month = (1 + date.getMonth()).toString().padStart(2, '0');
        return year + '-' + month + '-' + lastDayNum(date);
    }

    const updateFromTo = (cuurentMont) => {
        dispatch(setFilter([firstDay(cuurentMont), lastDay(cuurentMont)]))
        window.scrollTo(0, document.body.scrollHeight);
    }


    
    return (
        <div>
            <SectionContainer marginTop={'0px'} title="Reports">
                <Grid>
                    <Card01 paddingTop="15px" dropshadow={true}>
                        <div className="hover" onClick={() => { updateFromTo(cuurentMont) }}>
                            <ReportsMonthlySummary
                                billedTitle={'Overall Billed'}
                                title={'Current Month'}
                                apiFailedTitle={'Overall Failed'}
                                month={" " + cuurentMont.toLocaleString('default', { month: 'long' }) + " " + cuurentMont.getFullYear().toString()}
                                triggeredTitle={'Triggered'}
                                sumTitle={'Total Charge'}
                                data={currentMonthSummary.monthlyReport ? currentMonthSummary.monthlyReport : {}}
                            />
                            <Row><Container><PieChart data={dateArray} /></Container></Row>
                        </div>
                    </Card01>

                    <Card01 paddingTop="15px" dropshadow={true}>
                        <div className="hover" onClick={() => { updateFromTo(previous) }}>

                            <ReportsMonthlySummary
                                billedTitle={'Overall Billed'}
                                title={'Previous Month'}
                                apiFailedTitle={'Overall Failed'}
                                month={" " + previous.toLocaleString('default', { month: 'long' }) + " " + previous.getFullYear().toString()}
                                triggeredTitle={'Triggered'}
                                sumTitle={'Total Charge'}
                                data={previousMonthSummary.monthlyReport ? previousMonthSummary.monthlyReport : {}}
                            />
                            <Row>
                                <Container >
                                    <PieChart data={dataArray} />
                                </Container>
                            </Row>
                        </div>
                    </Card01>

                </Grid>
            </SectionContainer>

            <SectionContainer marginTop={'0px'} >
                <Card01 padding="20px" paddingTop='20px' dropshadow={true}>
                    <MonthCompare />
                </Card01>
            </SectionContainer>

            <SectionContainer marginTop={'0px'}>
                <Card01 padding="20px" paddingTop='20px' dropshadow={true}>
                    <ReportDetailsTable />
                </Card01>
            </SectionContainer>
        </div >

    );
};


export default ReportPage;