import React from 'react';
import PropTypes from "prop-types"
import { TitleContainer } from "./Title.style"
import { P } from '../../'

const Title = ({
    justifyContent = "space-around",
    width = "100%",
    title,
    height = "39px",
    paddingLeft="0px",

}) => {
    return (
        <TitleContainer
            justifyContent={justifyContent}
            width={width}
            height={height}
            paddingLeft={paddingLeft}
        >
            <P
                size="medium"
                color="#031332"
                justify="start"
                padding="0px"
                marginTop="24px"
                fontFamily="Assistant-Regular"
                fontWeight="600"


            >{title}</P>
        </TitleContainer>
    )
}

Title.propTypes = {
    justifyContent: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'space-between', 'space-around', 'space-evenly']),
    width: PropTypes.string,
    height: PropTypes.string,
    paddingLeft: PropTypes.string,

}

export default Title