import React from 'react';
import PropTypes from "prop-types"
import { IMGContainer } from "./IMG.style"

const IMG = ({
    src,
    alt,
    width,
    height="100%",
    rounded = false,
    backgroundColor,
    justifyContent = "center",
    marginLeft,
}) => {

    return (
        <IMGContainer
            marginLeft={marginLeft}
            width={width}
            height={height}
            rounded={rounded}
            justifyContent={justifyContent}
            backgroundColor={backgroundColor}
        >
            <img src={src} alt={alt} />
        </IMGContainer>
    )
}

IMG.propTypes = {
    marginLeft: PropTypes.string,
    src: PropTypes.string,
    alt: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    rounded: PropTypes.bool,
    backgroundColor: PropTypes.string,
    justifyContent: PropTypes.oneOf(["flex-start", "flex-end", "center", "space-between", "space-around", "space-evenly", "stretch"])
}

export default IMG