/*
 * billing Reports API 
 * Fintech billing reports
 *
 * OpenAPI spec version: 1.0.5
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.37
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The RequestAdvSeachKeys model module.
 * @module model/RequestAdvSeachKeys
 * @version 1.0.5
 */
export class RequestAdvSeachKeys {
  /**
   * Constructs a new <code>RequestAdvSeachKeys</code>.
   * @alias module:model/RequestAdvSeachKeys
   * @class
   * @param fromDate {Date} 
   * @param toDate {Date} 
   */
  constructor(fromDate, toDate) {
    this.fromDate = fromDate;
    this.toDate = toDate;
  }

  /**
   * Constructs a <code>RequestAdvSeachKeys</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/RequestAdvSeachKeys} obj Optional instance to populate.
   * @return {module:model/RequestAdvSeachKeys} The populated <code>RequestAdvSeachKeys</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new RequestAdvSeachKeys();
      if (data.hasOwnProperty('fromDate'))
        obj.fromDate = ApiClient.convertToType(data['fromDate'], 'Date');
      if (data.hasOwnProperty('toDate'))
        obj.toDate = ApiClient.convertToType(data['toDate'], 'Date');
      if (data.hasOwnProperty('apiDisplayName'))
        obj.apiDisplayName = ApiClient.convertToType(data['apiDisplayName'], 'String');
      if (data.hasOwnProperty('organizationName'))
        obj.organizationName = ApiClient.convertToType(data['organizationName'], 'String');
      if (data.hasOwnProperty('billingIndication'))
        obj.billingIndication = ApiClient.convertToType(data['billingIndication'], 'Boolean');
      if (data.hasOwnProperty('httpStatus'))
        obj.httpStatus = ApiClient.convertToType(data['httpStatus'], 'Number');
      if (data.hasOwnProperty('currentPage'))
        obj.currentPage = ApiClient.convertToType(data['currentPage'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {Date} fromDate
 */
RequestAdvSeachKeys.prototype.fromDate = undefined;

/**
 * @member {Date} toDate
 */
RequestAdvSeachKeys.prototype.toDate = undefined;

/**
 * Allowed values for the <code>apiDisplayName</code> property.
 * @enum {String}
 * @readonly
 */
RequestAdvSeachKeys.ApiDisplayNameEnum = {
  /**
   * value: "get-consent"
   * @const
   */
  getConsent: "get-consent",

  /**
   * value: "get-consent-status"
   * @const
   */
  getConsentStatus: "get-consent-status",

  /**
   * value: "delete-consent"
   * @const
   */
  deleteConsent: "delete-consent",

  /**
   * value: "create-consent"
   * @const
   */
  createConsent: "create-consent",

  /**
   * value: "refresh-token"
   * @const
   */
  refreshToken: "refresh-token",

  /**
   * value: "access-token"
   * @const
   */
  accessToken: "access-token",

  /**
   * value: "accounts-list"
   * @const
   */
  accountsList: "accounts-list",

  /**
   * value: "accounts-details"
   * @const
   */
  accountsDetails: "accounts-details",

  /**
   * value: "account-balances"
   * @const
   */
  accountBalances: "account-balances",

  /**
   * value: "transaction-list"
   * @const
   */
  transactionList: "transaction-list",

  /**
   * value: "transaction-details"
   * @const
   */
  transactionDetails: "transaction-details"
};
/**
 * @member {module:model/RequestAdvSeachKeys.ApiDisplayNameEnum} apiDisplayName
 */
RequestAdvSeachKeys.prototype.apiDisplayName = undefined;

/**
 * @member {String} organizationName
 */
RequestAdvSeachKeys.prototype.organizationName = undefined;

/**
 * @member {Boolean} billingIndication
 */
RequestAdvSeachKeys.prototype.billingIndication = undefined;

/**
 * @member {Number} httpStatus
 */
RequestAdvSeachKeys.prototype.httpStatus = undefined;

/**
 * @member {Number} currentPage
 */
RequestAdvSeachKeys.prototype.currentPage = undefined;

