import styled from 'styled-components';

export const Container = styled.div`
  background-color: #ffffff;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: fled-start;
`;