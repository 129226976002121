import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import InputUploadCertificate from '../../../components/InputUploadCertificate/InputUploadCertificate';
import { PageContent, Row, Button2, ButtonOutline, Card01, Col, OBlogo, ProgressCircles } from '../../../components/index';
import { setPage } from '../../../store/webNavigation/webNavSlice';

const QSealUpload = () => {
    const dispatch = useDispatch();
    dispatch(setPage('ignore'));

    const certificatesErrors = useSelector((state) => state.registration.certificateItem);
    const navigateTo = useNavigate();
    const checkIfCanForward = () => { return !(certificatesErrors['QSEAL_PUBLIC']['errMsg'] === '' && certificatesErrors['QSEAL_PUBLIC']['hashCertificate'].length > 1) }

    const checkValidity = (certificateType) => {
        const certificateNode = certificatesErrors[certificateType];
        return certificateNode['errMsg'] === '' && certificateNode['hashCertificate'].length > 1;
    }

    return (
        <div className='row'>

            <PageContent bgImage="https://res.cloudinary.com/dojmo7vcc/image/upload/v1671286848/OBH-BG_o2jm1r.jpg">
                <Card01 radius={24} minWidth="700px" height="680px" padding="55px">
                    <Row marginTop="45px" marginBottom='18px'><OBlogo size="63px" /></Row>
                    <Row marginBottom='24px'><div className="enroll-slogan"><h1>Welcome to Open Banking Hub</h1></div></Row>
                    <ProgressCircles step={1} width="420px" />
                    <Row><div className="enroll-title"><h1>Create New Account</h1></div></Row>
                    <Row marginBottom='24px'><div className="enroll-subtitle"><p>Please upload your BOI public certificate</p></div></Row>

                    <InputUploadCertificate
                        title={"QSEAL Public Upload"}
                        isValid={checkValidity('QSEAL_PUBLIC')}
                        errorMessage={certificatesErrors['QSEAL_PUBLIC']['errMsg']}
                        certificateType={'QSEAL_PUBLIC'}
                    />

                    <Col justifyContent="space-between">
                        <Button2
                            className="cySubmit"
                            width="370px"
                            marginTop={'30px'}
                            disable={checkIfCanForward()}
                            backgroundColor={"#115DFC"}
                            size='medium'
                            label={'Continue'}
                            rounded={true}
                            arrow={true}
                            onClick={!checkIfCanForward() ? () => { navigateTo('/upload-certificates') }: null}
                        />

                        <ButtonOutline
                            width="370px"
                            marginTop={'30px'}
                            label={'Back to login'}
                            rounded={true}
                            height="50px"
                            onClick={() => { navigateTo('/') }}
                        />
                    </Col>
                </Card01>
            </PageContent>
        </div>
    );
};
export default QSealUpload;