import { registrationActions } from "./registrationSlice";
import { UploadCertificateService } from "../../services/fintechAdmin/createFintechAccount/UploadCertificateService";
import { EC2_ENV, ENV } from "../../resources/constants/EnvironmentVariables.js";
import { UploadCertificateResponse, ErrorDetailsResponse } from "../../swagger/codeGen/upload-validate-certificate-by-type/src/index.js";
import { EnrollFintechResponse } from "../../swagger/codeGen/enroll-fintech/src/index.js";
import { AuthService } from "../../services/auth/AuthService";

//===============================================================
// uploadCertificate
//===============================================================
/**
 * 
 * @param {*} certificateType 
 * @param {*} certificateStream 
 * @returns 
 */
export const uploadCertificate = (certificateType, certificateStream, transactionId) => {
  return async (dispatch) => {

    const uploadCertificateService = new UploadCertificateService(ENV);
    const response = await uploadCertificateService.uploadCertificate(certificateType, certificateStream, transactionId);

    // check if response is UploadCertificateResponse or it's an ErrorDetailsResponse 
    if (response instanceof UploadCertificateResponse) {


      const hashCertificate = response.certificateHashItems[0].hashCertificate;
      const fintechId = response.fintechId;
      let certificatePayload = {
        typeCert: certificateType,
        hashCertificate,
        stream: certificateStream,
        errMsg: ''
      };

      dispatch(registrationActions.createCertificateSuccess({ certificatePayload, fintechId }));
    } else if (response instanceof ErrorDetailsResponse) {

      let payload = {};
      payload = {
        typeCert: certificateType,
        errMsg: response.message,
        hashCertificate: '',
        stream: ''
      };
      dispatch(registrationActions.createCertificateError(payload));
    }

  };
};

/**
 * 
 * @param {*} user 
 * @param {*} password 
 * @returns 
 */
export const setUserAndPassword = (user, password) => {
  return async (dispatch) => {
    dispatch(registrationActions.createUserAndPassword(
      {
        user, password
      }
    ));
  };
};

/**
 * 
 * @param {*} styleObject 
 * @returns 
 */
export const setStyleAndLogo = (styleObject) => {
  return async (dispatch) => {

    dispatch(registrationActions.createStylesAndLogoSuccess(
      styleObject
    ));

  };
};

export const enrollFinetechAccount = (accountObj, json, directToSuccess) => {
  return async (dispatch) => {
    const registrationService = new UploadCertificateService(ENV);
    const response = await registrationService.enrollFintech(accountObj);

    // check if response is UploadCertificateResponse or it's an ErrorDetailsResponse 
    if (response instanceof EnrollFintechResponse) {

      dispatch(registrationActions.resetRegistration());
      dispatch(registrationActions.enrollFinetechAccountSuccess({
        fintechId: response.fintechId,
        uploadStatus: response.uploadStatus
      }));

      directToSuccess();

    } else if (response instanceof ErrorDetailsResponse) {
      dispatch(registrationActions.enrollFinetechAccountError({
        enrollMsg: response.message
      }));
    }
  };
};
