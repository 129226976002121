/*
 * Fintech Admin Hub API 
 * Fintech Admin
 *
 * OpenAPI spec version: 1.0.0.1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.36
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {CertificateItem} from './CertificateItem';

/**
 * The CertificatesRequest model module.
 * @module model/CertificatesRequest
 * @version 1.0.0.1
 */
export class CertificatesRequest {
  /**
   * Constructs a new <code>CertificatesRequest</code>.
   * @alias module:model/CertificatesRequest
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>CertificatesRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CertificatesRequest} obj Optional instance to populate.
   * @return {module:model/CertificatesRequest} The populated <code>CertificatesRequest</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CertificatesRequest();
      if (data.hasOwnProperty('certificateItems'))
        obj.certificateItems = ApiClient.convertToType(data['certificateItems'], [CertificateItem]);
    }
    return obj;
  }
}

/**
 * @member {Array.<module:model/CertificateItem>} certificateItems
 */
CertificatesRequest.prototype.certificateItems = undefined;

