import UtilsBase from './UtilsBase';

export default class StepWFControl extends  UtilsBase{
   
    /**
 * 
 * @param {*} env 
 */
     constructor(env){
        super(env);
    }

    /**
     * 
     * Checks that the next step in workflow control is legal
     * @param {*} json The Json that is not from the services. it is fetched from json file
     * 
     * @param {*} currentStep The current step that the services track and get from redux
     */
    next( json, currentStep){

        if(!this.validateInput({json, currentStep})){
            return false;
        }
        const checkIfLegalStep = this.filterByDirection( {json, currentStep, "direction":'next'});

        return checkIfLegalStep ? this.wfProp(json, currentStep+1 ) : this.wfProp(json, currentStep );


    }

    /**
     * Checks that the previous step in workflow control is legal
     * @param {*} json 
     * @param {*} currentStep 
     * @returns boolean if its a legal step
     */
    previous( json, currentStep){

        if(!this.validateInput({ json, currentStep})){
            return false;
        }

        const checkIfLegalStep = this.filterByDirection( {json, currentStep, "direction":'previous'});     

        return checkIfLegalStep ? this.wfProp(json, currentStep -1 ) : this.wfProp(json, currentStep );

    }

    /**
     * 
     * @param {*} json 
     * @param {*} currentStep 
     * @returns 
     */
    home( json, currentStep){

        if(!this.validateInput({ json, currentStep})){
            return false;
        }

        const checkIfLegalStep = this.filterByDirection( {json, currentStep, "direction":'home'});     

        return checkIfLegalStep ? this.wfProp(json, 1) : this.wfProp(json, currentStep );

      }

    /**
     * 
     * @param {*} dataObj - The data that is sent to the Utill function of next() and previous()
     * @returns boolean if its a legal step
     */
    filterByDirection( dataObj){
        const {json, currentStep, direction} = dataObj;

        const checkIfLegalStep = json.filter(node=>{
            return node['step'] == currentStep && node[direction];
        });
        return checkIfLegalStep.length > 0; 
    }

    /**
     * 
     * @param {*} json 
     * @param {*} currentStep 
     */
    wfProp( json, currentStep){
       if(!this.validateInput({json, currentStep})){
            return null;
       }
       
       let selected = null;
       json.forEach(element =>{
            if(element['step'] === currentStep){
                selected = element;
            }
        });
        return selected;
    
    }

    /**
     * Counts The steps that are in each path
     * @param {*} json 
     * @returns 
     */
    wfCountSteps( json){

        if(!this.validateInput({json})){
            return 0;
        }

        return json.length;
    }

    /**
     * 
     * @param {*} json 
     * @param {*} currentStep 
     */
    validateInput( fieldsToValidate) {
        const {json, currentStep} = fieldsToValidate;

        if(!json || !Array.isArray(json)) return false;
        if(currentStep && typeof(currentStep) !== 'number') return false;
        return true;
    }
 
}