import React from 'react';
import PropTypes from 'prop-types';
import { ContainerContainer, H1 } from './Container.style';

const Container = ({
    width,
    height,
    maxWidth,
    marginTop = "0px",
    justifyContent = "space-around",
    title,
    children,
    backgroundColor = "#ffffff",
    padding,
    data
}) => {

    return (
        <ContainerContainer
            padding={padding}
            width={width}
            maxWidth={maxWidth}
            height={height}
            marginTop={marginTop}
            title={title}
            backgroundColor={backgroundColor}
            justifyContent={justifyContent}
        >
            {title ? <H1>{title}</H1> : null}
            {children}
        </ContainerContainer>
    );
};

Container.propTypes = {
    padding: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    maxWidth: PropTypes.string,
    marginTop: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.node,
    backgroundColor: PropTypes.string,
    justifyContent: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'space-between', 'space-around', 'space-evenly']),
    data: PropTypes.any
};

export default Container;