import React from 'react';
import PropTypes from "prop-types"
import { ButtonContainer } from "./ButtonOutline.style"

const ButtonOutline = ({
    marginTop,
    label,
    color = "#115DFC",
    width,
    height = "36px",
    backgroundColor = "#ffffff",
    size = "36px",
    rounded = true,
    margin,
    onClick,
}) => {
    return (
        <ButtonContainer
            rounded={rounded}
            backgroundColor={backgroundColor}
            marginTop={marginTop}
            color={color}
            width={width}
            height={height}
            size={size}
            margin={margin}
            onClick={onClick}

        >
            {label}
        </ButtonContainer>
    )
}

ButtonOutline.propTypes = {
    backgroundColor: PropTypes.string,
    height: PropTypes.string,
    marginTop: PropTypes.string,
    onClick: PropTypes.func,
    label: PropTypes.string,
    color: PropTypes.string,
    width: PropTypes.string,
    size: PropTypes.string,
    margin: PropTypes.string,
    rounded: PropTypes.bool,
}

export default ButtonOutline