import Logger from './Logger';
export default class UtilsBase {

    /**
     * 
     * @param {*} param0 
     */
    constructor(env) {

        this.log = new Logger(env);
        this.env = env;
        this.logName = `${this.constructor.name} CLASS`;
    }
}