import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import Logger from '../utils/libs/Logger';
import { setPage } from '../store/webNavigation/webNavSlice';
import { Container } from '../components';
import { useNavigate } from 'react-router';

const urlParam = new URLSearchParams(window.location.search).get('logger')

const LoggerComp = () => {
    const dispatch = useDispatch();
    dispatch(setPage('ignore'));
    document.title = "Set Logger"
    const [message, setMessage] = useState('');
    const navigateTo = useNavigate();

    useEffect(() => {
        if (urlParam === null || urlParam === undefined) {
            setMessage('No logger mode was set in the URL. Mode is set to "errors".');
        }
        else if (urlParam !== 'errors' && urlParam !== 'debug') {
            setMessage(`Invalid logger mode was set in the URL - ${urlParam}. Chooose either "errors" or "debug". No changes were made.`);
        }
        else {
            Logger.setMode(urlParam);
            setMessage(`Logger param is OK. Logger mode is set to ${urlParam}`);
            navigateTo('/')
        }
    }, [])

    return (
        <Container padding='10px' backgroundColor='#444444' height={"100vh"} justifyContent="flex-start">
            <p>{message}</p>
        </Container>
    )
}

export default LoggerComp