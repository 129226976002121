import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from "react-router-dom";
import { Button2, InputText, PageContent, Card01, OBlogo, P, Row, H1 } from '../../../components/index';
import { navigate } from "../../../store/navigation/navigationActions";
import ResetPasswordFinetech from '../../../resources/constants/StepWFControl/ResetFintechPassword.json';
import { setPage } from '../../../store/webNavigation/webNavSlice';
import { signIn } from '../../../store/loggedUser/loggedUserSlice';
import { getTheme } from '../../../store/theme/themeSlice';
import { registrationActions } from '../../../store/registration/registrationSlice';

const HomePage = () => {
    const { transactionId, fintechId } = useSelector((state) => state.management);
    const dispatch = useDispatch();
    dispatch(setPage('home'));
    
    const [freezeBtn, setFreezeBtn] = useState(false);
    const [error, setError] = useState(false);
    const navigateTo = useNavigate();
    const resetPasswordArray = ResetPasswordFinetech['reset-password'];

    const handleClick = (json, stepNumber, direction) => {
        dispatch(navigate(json, stepNumber, direction));
    };

    const [signInForm, setSignInForm] = useState({ userMail: "", password: "" })

    const onChange = (e) => {
        setError(null);
        setSignInForm({
            ...signInForm, [e.target.name]: e.target.value
        })
    }

    const doGetTheme = () => { dispatch(getTheme({ transactionId, fintechId })) }

    const doSignIn = (e) => {
        e.preventDefault();

        if (!freezeBtn) {
            setFreezeBtn(true);
            const AlertError = () => {
                setError('Wrong email or password');
            }
            const linkToFirstPage = () => { navigateTo('/fintech-reports') }
            const unFreeze = () => { setFreezeBtn(false) }

            const sendPayloadToSignIn = {
                doGetTheme,
                AlertError,
                signInForm,
                unFreeze,
                linkToFirstPage
            }
            dispatch(signIn(sendPayloadToSignIn));
        }
    }

    return (
        <div className='row'>
            <PageContent bgImage="https://res.cloudinary.com/dojmo7vcc/image/upload/v1671286848/OBH-BG_o2jm1r.jpg">

                <Card01 width="530px" padding="80px" height="595px">
                    <Row marginTop="48px" marginBottom='24px'>
                        <OBlogo size="63px" />
                    </Row>
                    <H1 color="#031332">Welcome to Open Banking Hub</H1>
                    <form>
                        <InputText change={onChange} name="userMail" value={signInForm.userMail} placeholder='User Mail' type="text" autocomplete="user-name" />
                        <InputText change={onChange} name="password" value={signInForm.password} placeholder='Password' type="password" autocomplete="current-password" />

                        {error ? <P color="#EB5757">{error}</P> : null}

                        <Link onClick={() => handleClick(resetPasswordArray, 1, 'next')} style={{ fontSize: "11px", color: "#115DFC" }} to={'verify-certificate'}>Forgot / Set New Password</Link>
                        <div className='v-spacer' />
                        <Button2
                            onClick={doSignIn}
                            label='Sign In'
                            width='100%'
                            type='submit'
                            rounded
                            size='medium'
                            disable={freezeBtn}
                        />
                    </form>
                    <div className='v-spacer' />

                    <Link onClick={()=>{dispatch(registrationActions.resetRegistration())}} className="cyReg" to="/upload-qseal-public">
                        <P marginTop='25px' fontSize="16px" >Don’t have an account? </P><P color='#115DFC'>Register</P>
                    </Link>

                </Card01>
            </PageContent>
        </div>
    );
};

export default HomePage;