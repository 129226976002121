import React from 'react';
import { HeaderContainer, LogoContainer } from './Header.style';
import { UserComp } from '../'
import { useSelector } from 'react-redux'
import { OBlogoMono, Row, IMG } from '../';
const Header = () => {
    const currPage = useSelector(state => state.webNav.page);
    const theme = useSelector(state => state.theme.theme);

    if (currPage !== "ignore" && currPage !== "home") {
        return (
            <HeaderContainer>
                <LogoContainer ><OBlogoMono /><p>Open Banking</p></LogoContainer>
                <Row
                    justifyContent="space-between"
                    width="100%"
                >
                    {theme.logoUrl !== "" ?
                        <IMG height="50px" src={theme.logoUrl} alt="logo" marginLeft={"45px"} /> :
                        <IMG height="50px" src={"https://res.cloudinary.com/dojmo7vcc/image/upload/v1673630779/empty_zvtxeq.png"} alt="logo" marginLeft={"45px"} />
                    }
                    <UserComp />
                </Row>
            </HeaderContainer>
        );
    }
};

export default Header;