import { API } from "aws-amplify";
import UtilsBase from '../../libs/UtilsBase';


export default class AmplifyApi extends UtilsBase {
    /**
     * 
     * @param {*} env 
     */
    constructor(envObj) {

        const { env, timeout } = envObj;
        super(env);
        this.timeout = timeout;
    }
 

/**
 * 
 * @param {*} lambdaName 
 * @param {*} url 
 * @param {*} amplifyConfig 
 * @returns 
 */
async get(  lambdaName , url, amplifyConfig ) {
    const logFunc = `${this.logName} put amplify: `;
    const startTime = new Date();
    try {

        let data;
        this.log.debug(logFunc, url);
        this.log.debug(logFunc, lambdaName);
        this.log.debug(logFunc, JSON.stringify(amplifyConfig));

        data = await API.get( lambdaName, url, amplifyConfig);

        this.log.debug(logFunc, JSON.stringify(data));
        

        this.log.audit(logFunc, startTime);
        return data;

    } catch (error) {
        this.log.error(`${this.logName}  `, error);
        return error.response;
    }
}



/**
 * 
 * @param {*} lambdaName 
 * @param {*} url 
 * @param {*} amplifyConfig 
 * @returns 
 */
    async put(  lambdaName , url, amplifyConfig ) {
        const logFunc = `${this.logName} put amplify: `;
        const startTime = new Date();
        try {

            let data;       
            this.log.debug(logFunc, url);
            this.log.debug(logFunc, lambdaName);
            this.log.debug(logFunc, JSON.stringify(amplifyConfig));

            data = await API.put( lambdaName, url, amplifyConfig);

            this.log.debug(logFunc, JSON.stringify(data));
            

            this.log.audit(logFunc, startTime);
            return data;

        } catch (error) {
            this.log.error(`${this.logName}  `, error);
            return error.response;
        }
    }
 
    

/**
 * 
 * @param {*} lambdaName 
 * @param {*} url 
 * @param {*} amplifyConfig 
 * @returns 
 */
async post(  lambdaName , url, amplifyConfig ) {
    const logFunc = `${this.logName} put amplify: `;
    const startTime = new Date();
    try {

        let data;

        this.log.debug(logFunc, url);
        this.log.debug(logFunc, lambdaName);
        this.log.debug(logFunc, JSON.stringify(amplifyConfig));

        data = await API.post( lambdaName, url, amplifyConfig);

        this.log.debug(logFunc, JSON.stringify(data));
        

        this.log.audit(logFunc, startTime);
        return data;

    } catch (error) {
        this.log.error(`${this.logName}  `, error);
        return error.response;
    }
}


}