import React from 'react'
import PropTypes from "prop-types"
import Icon from './OBlogoMono.svg'
import { IMG } from './OBlogoMono.style'

export default function OBlogoMono({
  size = "32px",
  color = "#ffffff",
}) {
  return (
    <IMG
      color={color}
      src={Icon}
      alt="OB"
      size={size}
    />
  )
}

OBlogoMono.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
}
