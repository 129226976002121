import React from 'react';
import PropTypes from "prop-types"
import { H1Container } from "./H1.style"

const H1 = ({
    width = "100%",
    fontSize = "24px",
    color = "#031332",
    justify = "center",
    padding = "0px",
    marginTop = "0px",
    weight = "600",
    children,
    fontFamily = "Assistant-Regular",

}) => {

    return (
        <H1Container
            weight={weight}
            width={width}
            fontSize={fontSize}
            color={color}
            justify={justify}
            padding={padding}
            marginTop={marginTop}
            fontFamily={fontFamily}
        >
            {children}
        </H1Container>
    )
}

H1.propTypes = {
    weight: PropTypes.string,
    width: PropTypes.string,
    fontSize: PropTypes.string,
    color: PropTypes.string,
    justify: PropTypes.oneOf(["center", "flex-start", "flex-end"]),
    padding: PropTypes.string,
    marginTop: PropTypes.string,
    fontFamily: PropTypes.string,
}

export default H1