/*
 * upload and validate certificates
 * upload and validate certificates
 *
 * OpenAPI spec version: 1.0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {CertificateHashItem} from './CertificateHashItem';

/**
 * The UploadCertificateResponse model module.
 * @module model/UploadCertificateResponse
 * @version 1.0.3
 */
export class UploadCertificateResponse {
  /**
   * Constructs a new <code>UploadCertificateResponse</code>.
   * @alias module:model/UploadCertificateResponse
   * @class
   * @param certificateHashItems {Array.<module:model/CertificateHashItem>} 
   */
  constructor(certificateHashItems) {
    this.certificateHashItems = certificateHashItems;
  }

  /**
   * Constructs a <code>UploadCertificateResponse</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/UploadCertificateResponse} obj Optional instance to populate.
   * @return {module:model/UploadCertificateResponse} The populated <code>UploadCertificateResponse</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new UploadCertificateResponse();
      if (data.hasOwnProperty('fintechId'))
        obj.fintechId = ApiClient.convertToType(data['fintechId'], 'String');
      if (data.hasOwnProperty('certificateHashItems'))
        obj.certificateHashItems = ApiClient.convertToType(data['certificateHashItems'], [CertificateHashItem]);
    }
    return obj;
  }
}

/**
 * fintechId - fintech umnique id ( optional response parameter )
 * @member {String} fintechId
 */
UploadCertificateResponse.prototype.fintechId = undefined;

/**
 * @member {Array.<module:model/CertificateHashItem>} certificateHashItems
 */
UploadCertificateResponse.prototype.certificateHashItems = undefined;

