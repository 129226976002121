import styled from 'styled-components';

export const PContainer = styled.p`
  width: ${({ width }) => width};
  font-size: ${({ fontSize }) => fontSize};
  color: ${({ color }) => color};
  justify-content: ${({ justify }) => justify};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-top: ${({ marginTop }) => marginTop};
  padding: ${({ padding }) => padding};
  padding-left: ${({ paddingLeft }) => paddingLeft};
  font-family: ${({ fontFamily }) => fontFamily};
  display: flex;
  font-weight: ${({ fontWeight }) => fontWeight};
  display: inline;
`;
