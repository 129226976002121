import React from 'react';
import { useDispatch } from 'react-redux'
import { SideBar, PageContent } from '../../../components';
import ReportPage from '../../../components/ReportPage/ReportPage';
import { setPage } from '../../../store/webNavigation/webNavSlice';
import { enforceLogin } from '../../../services/enforceLogin';

const FintechReports = () => {
    enforceLogin();
    const dispatch = useDispatch();
    dispatch(setPage('reports'));

    return (
        <div className='row'>
            <SideBar />
            <PageContent>
                <ReportPage />
            </PageContent>
        </div>
    );
};

export default FintechReports;