import React from 'react';
import { InfoTextStyle } from './InfoText.Style';


const InfoText = props => {
    return (

        <InfoTextStyle >
            <p><b>{props.step} </b>
                {props.infoText} </p>
        </InfoTextStyle>
    );
};


export default InfoText;