import { createSlice } from '@reduxjs/toolkit';
import Uuid from '../../utils/libs/Uuid';

const initialState = {
  transactionId: Uuid.uuid(),
  fintechId: 'OB:IL-BOI-500007-AIPI_TestFintech_U1',
  currentMonthSummary: {},
  previousMonthSummary: {},
  monthlyCompare: {
    firstMonth: {},
    secondMonth: {}
  },
  organizations:[],
  advancedSearchData: {},
};

const managementSlice = createSlice({
  name: 'management',
  initialState: initialState,
  reducers: {
    resetManagementSlice: () => {
      return { ...initialState, transactionId: Uuid.uuid() }
    },
    fetchOrganizationsSuccess: (state, action) => {
      state.organizations = action.payload.organizations;
    },
    fetchOrganizationsFailed: (state, action) => {
      state.organizations = [];
    },
    
    fetchCurrentMonthSummarySuccess(state, action) {
      state.currentMonthSummary.monthlyReport = action.payload.monthlyReport;
      state.monthlyCompare.firstMonth = { ...action.payload.monthlyReport };
      state.currentMonthSummary.errorMessage = "";
    },

    fetchCurrentMonthSummaryFailed(state, action) {
      state.currentMonthSummary.monthlyReport = {};
      state.currentMonthSummary.errorMessage = action.payload.message;
    },

    fetchPreviousMonthSummarySuccess(state, action) {
      state.previousMonthSummary.monthlyReport = action.payload.monthlyReport;
      state.monthlyCompare.secondMonth = { ...action.payload.monthlyReport };
      state.previousMonthSummary.errorMessage = "";
    },

    fetchPreviousMonthSummaryFailed(state, action) {
      state.previousMonthSummary.monthlyReport = {};
      state.previousMonthSummary.errorMessage = action.payload.message;
    },

    fetchAdvancedSearchReportsDetailsSuccess(state, action) {
      state.advancedSearchData = { ...action.payload.data };
      state.advancedSearchData.errorMessage = action.payload.message;
    },

    fetchAdvancedSearchReportsDetailsFailed(state, action) {
      state.advancedSearchData.monthlyReport = {};
      state.advancedSearchData.errorMessage = action.payload.message;
    },

    fetchMonthlyCompareSuccess(state, action) {
      state.monthlyCompare = { ...action.payload.data };
      state.monthlyCompare.errorMessage = action.payload.message;
    },

    fetchMonthlyCompareFailed(state, action) {
      state.monthlyCompare = { ...initialState.monthlyCompare };
      state.monthlyCompare.errorMessage = action.payload.message;
    },

  },
});

export const managementActions = managementSlice.actions;

export default managementSlice;