import styled from 'styled-components';

const handleColorType = type => {
  switch (type) {
    case "home":
      return `border-color:var(--color-5);
                color: white; 
                background-color: var(--color-5);`;
    case "signin":
      return `border-color:#0f0202;
                color: white; 
                background-color: var(--color-5);`;
    case "validate":
      return `border-color:lightblue;
                color:  #0f0202; 
                background-color: lightblue;`;
    case "create":
      return `border-color:var(--color-4);
                color: white; 
                background-color: var(--color-4);`;
    case "resend-mail":
      return `border-color:var(--color-6);
                color: white; 
                background-color: var(--color-6);`;
    default:
      return `border-color:var(--continue-green);
                color: #ffffff; 
                background-color: var(--continue-green);`;
  }
};

const ButtonStyle = styled.button`
    padding: 8px;
    display: block;
    width: 100%;
    border-style: solid;
    margin:10px 0;
    font-size:14px;
    font-weight: 600;
    border-radius:50px;
    transition: background-color .5s;
    border-width: 0px;
    ${({ type }) => handleColorType(type)};
    cursor: pointer;
    :disabled{
        background-color: var(--light-grey);
        border-color: var(--frame-border-color);
        color : var(--frame-border-color);
    }
    :hover {
      opacity: 0.85;
    }
    

`;

export { ButtonStyle };
