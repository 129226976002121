import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setPage } from '../../store/webNavigation/webNavSlice';
import { getTheme } from '../../store/theme/themeSlice';
import { H1, Container, Button2, Row, Col, IMG, Grid, Card01 } from '../../components';
import { EstablishConsentService } from '../../services/consent/establishConsent/EstablishConsentService';
import psuIdArray from './Resources/psuIdArray.js';
import { CONSENT_COOKIE_TTL } from '../../resources/constants/EnvironmentVariables';

const DemoTPP = () => {
    const dispatch = useDispatch();
    dispatch(setPage('ignore'));
    document.title = "Fintech Demo Page"

    const createSession = new EstablishConsentService();
    const [stylesObject, setStylesObject] = useState({ fontColor: "", backgroundColor: "", logoUrl: "" });
    const { transactionId, fintechId } = useSelector((state) => state.management);

    const [psuId, setPsuId] = useState(psuIdArray[0].value);
    const [finId, setFinId] = useState('OB:IL-BOI-500007-AIPI_TestFintech_U1');
    const [redirectUrl, setRedirectUrl] = useState('tpp-app_v1.0.8/index.html');

    const redirectToConsentPage = (key) => { window.location.href = `/consent?key=${key}`; }

    const putDataInCookie = (key, value) => {
        let expires = "";
        let date = new Date();
        date.setTime(date.getTime() + (CONSENT_COOKIE_TTL));
        expires = "; expires=" + date.toUTCString();
        document.cookie = key + "=" + (value || "") + expires + "; path=/";
    }

    const callCreateUserSession = () => {
        let axiosRes = createSession.createUserSession(psuId, finId, redirectUrl);

        putDataInCookie('psuId', psuId);
        putDataInCookie('finId', finId);
        putDataInCookie('redirectUrl', redirectUrl);

        axiosRes.then(res => {
            if (!res.data || res.data.length === 0) { alert('Server error, try again later') }
            else {
                redirectToConsentPage(JSON.parse(res.data).key)
            }
        }
        )
    }

    async function fetchStyleObject(transactionId, fintechId) {
        try {
            dispatch(getTheme({ transactionId, fintechId })).then(res => setStylesObject(JSON.parse(res.payload)))
        } catch (error) {
        }
    }

    useEffect(() => {
        // The next component (TppAccomplishConsent) will call 'getAccessToken'. The next line will prevent the call from happening twice.
        localStorage.removeItem('wasRequestSent');
        if (transactionId && fintechId) { fetchStyleObject(transactionId, fintechId) }
    }, []);

    if (stylesObject !== null) {
        return (
            <Container padding='10px'>
                <Row><IMG src={stylesObject.logoUrl} alt='Logo' width="200px" justifyContent='space-around' /></Row>
                <H1 size="medium">Create consent simulation:</H1>
                <Card01 padding="20px" paddingTop="20px" width='750px'>

                    <Grid columns={2} gap="10px" width="700px">
                        <p>Accounts</p>
                        <select defaultValue={psuId} name="psuId" onChange={(e) => setPsuId(e.target.value)}>
                            {psuIdArray.map((psuItem) => {
                                return <option key={psuItem.value} value={psuItem.value}>{psuItem.name}</option>
                            })}
                        </select>
                    </Grid>

                    <Row columns={2} gap="10px" width="700px">
                        <Row width="320px" justifyContent='flex-start' ><p>Fintech Id</p></Row>
                        <input className="tpp-field" type="text" name="finId" onChange={(e) => setFinId(e.target.value)} value={finId} />
                    </Row>

                    <Row columns={2} gap="10px" width="700px">
                        <Row width="320px" justifyContent='flex-start' ><p>Redirect URL</p></Row>
                        <input className="tpp-field" type="text" name="redirectUrl" onChange={(e) => setRedirectUrl(e.target.value)} value={redirectUrl} />
                    </Row>
                </Card01>

                <p>Clicking the button will redirect you to the consent page</p>

                <Button2
                    rounded
                    label='Go !'
                    width='300px'
                    onClick={callCreateUserSession}
                />

            </Container >
        )
    } else return <Row height="200px"><Col><h1>No data, you have to enroll first</h1></Col></Row>
}

export default DemoTPP