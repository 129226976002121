import React from 'react';
import PropTypes from "prop-types"
import { ColContainer } from "./Col.style"

const Col = ({
    children,
    width = "100%",
    height,
    marginTop = "0px",
    marginBottom = "0px",
    paddingTop,
    paddingLeft,
    paddingRight,
    paddingBottom,
    backgroundColor = "transparent",
    justifyContent = "space-around",
    alignItems = "center",
    margin,

}) => {

    return (
        <ColContainer
            paddingBottom={paddingBottom}
            paddingTop={paddingTop}
            paddingLeft={paddingLeft}
            paddingRight={paddingRight}
            width={width}
            height={height}
            justifyContent={justifyContent}
            alignItems={alignItems}
            backgroundColor={backgroundColor}
            marginTop={marginTop}
            marginBottom={marginBottom}
            margin={margin}
        >
            {children}
        </ColContainer>
    )
}

Col.propTypes = {
    paddingBottom: PropTypes.string,
    paddingTop: PropTypes.string,
    paddingLeft: PropTypes.string,
    paddingRight: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    children: PropTypes.node.isRequired,
    justifyContent: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'space-between', 'space-around', 'space-evenly']),
    alignItems: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'stretch', 'baseline']),
    backgroundColor: PropTypes.string,
    marginTop: PropTypes.string,
    marginBottom: PropTypes.string,
    margin: PropTypes.string
}

export default Col