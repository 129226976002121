import React, { useState, useEffect } from 'react';
import { FintechAdminService } from '../../../../services/fintechAdmin/finetechAdmin/FintechAdminService';
import { Container, Button2, InputFile, Col, Grid, RollingSpinner, Table } from '../../../../components';
import { CertificateItem, CertificatesResponse } from '../../../../swagger/codeGen/fintech-admin-hub/src';
import { useSelector } from 'react-redux';
import { validateCertificatePrivateFormat, validateCertificatePublicFormat } from '../../../../resources/constants/EnvironmentVariables';

const fintechAdminService = new FintechAdminService();
const CertificateTypes = CertificateItem.TypeCertEnum;
const initialState = {};
const fileObject = { stream: "", errorMessage: "", fileName: "" };
Object.values(CertificateTypes).forEach((key) => { initialState[key] = { typeCert: key, ...fileObject } });

const CertificateUpdate = ({ setAlertMessage }) => {
    const { transactionId, fintechId } = useSelector((state) => state.management);
    const [certificates, setCertificates] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [files, setFiles] = useState(initialState);
    const columns = ["Type", "Expiry Date"];

    useEffect(() => {
        async function fetchCertificatesObject({ transactionId, fintechId }) {
            try {
                const service = new FintechAdminService();
                const response = await service.getCertificates({ transactionId, fintechId });
                if (response instanceof CertificatesResponse) { setCertificates(response) }
                else { setErrorMessage(response.message) }
            } catch (error) { setErrorMessage(error) } finally { setIsLoading(false) }
        }
        if (transactionId && fintechId) { setIsLoading(true); fetchCertificatesObject({ transactionId, fintechId }) }
    }, []);

    const getCerts = () => {
        async function fetchCertificatesObject({ transactionId, fintechId }) {
            try {
                const service = new FintechAdminService();
                const response = await service.getCertificates({ transactionId, fintechId });
                if (response instanceof CertificatesResponse) { setCertificates(response) }
                else { setErrorMessage(response.message) }
            } catch (error) { setErrorMessage(error) } finally { setIsLoading(false) }
        }
        if (transactionId && fintechId) { setIsLoading(true); fetchCertificatesObject({ transactionId, fintechId }) }
    }

    const onSubmitCert = (e) => {
        e.preventDefault();
        async function updateCertificates({ certificateObject, transactionId, fintechId }) {
            try {
                const response = await fintechAdminService.updateCertificates({ certificateObject, transactionId, fintechId });
                if (response instanceof CertificatesResponse) {
                    setAlertMessage({ submitSuccess: "Update Success", submitError: null });
                    setTimeout(() => { setFiles(initialState); setAlertMessage(null); getCerts(); }, 3000);
                }
                else { setAlertMessage({ submitSuccess: null, submitError: response.message }) }
            } catch (error) { setAlertMessage({ submitSuccess: null, submitError: error }) }
        }
        if (checkValidity(files)) { updateCertificates({ certificateObject: files, transactionId, fintechId }) }
    }

    const checkCertFiles = (stream, type) => {
        const retObj = { error: true, errorMessage: "" };
        const test = new String(stream);

        if ([CertificateTypes.QSEAL_PUBLIC, CertificateTypes.QWAC_PUBLIC].includes(type)) {
            if (test.startsWith(validateCertificatePublicFormat[0]) &&
                test.endsWith(validateCertificatePublicFormat[1])) {
                return { error: false, errorMessage: "" };
            }
            return { ...retObj, errorMessage: "Wrong Public Key Format" };
        } else {
            if (test.startsWith(validateCertificatePrivateFormat[0]) &&
                test.endsWith(validateCertificatePrivateFormat[1])) {
                return { error: false, errorMessage: "" };
            }
            return { ...retObj, errorMessage: "Wrong Private Key Format" };
        }
    }

    const clearRow = (row) => {
        let temp = certificates;
        temp[row].expiryDate = null;
        setCertificates(temp);
    }

    const onFileChange = (e, row) => {
        clearRow(row);
        const reader = new FileReader();
        reader.onloadend = (evt) => {
            const type = e.target.files[0].type;
            let fileObject = { ...files[e.target.name] };

            fileObject.fileName = e.target.files[0].name;
            if (['image/png', 'image/jpg', 'image/jpeg'].includes(type)) {
                fileObject.errorMessage = "Wrong file Format";
            } else if (checkCertFiles(reader.result, e.target.name).error) {
                fileObject.errorMessage = checkCertFiles(reader.result, e.target.name).errorMessage;
            } else {
                fileObject.errorMessage = "";
                fileObject.stream = reader.result;
            }
            setFiles({ ...files, [e.target.name]: fileObject });
        }
        reader.readAsBinaryString(e.target.files[0]);
    }

    const checkValidity = (filesToCheck) => {
        for (const key in filesToCheck) {
            if (filesToCheck[key].fileName === '' || filesToCheck[key].errorMessage !== '') {
                return false;
            }
        }
        return true;
    }

    return (
        <Container marginTop='10px' height="300px">
            <Grid>
                <div>
                    {isLoading ? <RollingSpinner /> : <Table columns={columns} data={certificates} />}
                    <div> {errorMessage} </div>
                </div>

                <form onSubmit={onSubmitCert}>
                    <Col height="210px" paddingTop="0px" marginTop='60px' >
                        <InputFile
                            width="200px"
                            isValid={files[CertificateTypes.QSEAL_PUBLIC].fileName !== "" && files[CertificateTypes.QSEAL_PUBLIC].errorMessage === ''}
                            errorMessage={files[CertificateTypes.QSEAL_PUBLIC].errorMessage}
                            fileName={files[CertificateTypes.QSEAL_PUBLIC].fileName}
                            change={(e) => { onFileChange(e, 0) }}
                            name={CertificateTypes.QSEAL_PUBLIC}
                        />
                        <InputFile
                            width="200px"
                            isValid={files[CertificateTypes.QSEAL_PRIVATE].fileName !== "" && files[CertificateTypes.QSEAL_PRIVATE].errorMessage === ''}
                            errorMessage={files[CertificateTypes.QSEAL_PRIVATE].errorMessage}
                            fileName={files[CertificateTypes.QSEAL_PRIVATE].fileName}
                            name={CertificateTypes.QSEAL_PRIVATE}
                            change={(e) => { onFileChange(e, 1) }}
                        />
                        <InputFile
                            width="200px"
                            isValid={files[CertificateTypes.QWAC_PUBLIC].fileName !== ''
                                && files[CertificateTypes.QWAC_PUBLIC].errorMessage === ''}
                            errorMessage={files[CertificateTypes.QWAC_PUBLIC].errorMessage}
                            fileName={files[CertificateTypes.QWAC_PUBLIC].fileName}
                            change={(e) => { onFileChange(e, 2) }}
                            name={CertificateTypes.QWAC_PUBLIC}
                        />
                        <InputFile
                            width="200px"
                            isValid={files[CertificateTypes.QWAC_PRIVATE].fileName !== ''
                                && files[CertificateTypes.QWAC_PRIVATE].errorMessage === ''}
                            errorMessage={files[CertificateTypes.QWAC_PRIVATE].errorMessage}
                            fileName={files[CertificateTypes.QWAC_PRIVATE].fileName}
                            change={(e) => { onFileChange(e, 3) }}
                            name={CertificateTypes.QWAC_PRIVATE}
                        />

                        <Button2
                            fontSize="14px"
                            width="100%"
                            marginTop="10px"
                            disable={!checkValidity(files)}
                            onClick={onSubmitCert}
                            label="Update"
                            rounded
                        />
                    </Col>
                </form>
            </Grid>
        </Container>
    );
};

export default CertificateUpdate;