import React from 'react';
import { Route, Routes } from 'react-router-dom';

// Import view components:
import HomePage from '../views/fintechAdmin/HomePage/HomePage';
import { CertificatesUpload, UserAndPassword, LogoAndStyle, WelcomeUser, QSealUpload } from '../views/fintechAdmin/enrollFlow';
import { FintechManagement, FintechReports, FintechUsers, ProfilePage } from '../views/fintechAdmin/fintechManagmentPages';
import { ResetPassword } from '../views/restPassword/index';
import { DemoTPP, Tester, UserConfirm, TppAccomplishConsent, CreateAndRedirectToBank, Qa, Logger } from '../views/index';
import ProtectedRoute from '../hoc/ProtectedRoute/ProtectedRoute';

const FintechApp = () => {
    return (
        <>
            <Routes>
                {/* Enroll */}
                <Route path='/' exact element={<HomePage />} />
                <Route path='/upload-qseal-public' element={<QSealUpload />} />
                <Route path='/upload-certificates' element={<CertificatesUpload />} />
                <Route path='/user-and-password' element={<UserAndPassword />} />
                <Route path='/logo-and-style' element={<LogoAndStyle />} />
                <Route path='/welcome' element={<WelcomeUser />} />

                {/* Reset Password */}
                <Route path='/verify-certificate' element={<ResetPassword />} />

                {/* Dashboard Pages */}
                <Route path='/fintech-management' element={<ProtectedRoute><FintechManagement /></ProtectedRoute>} />
                <Route path='/fintech-reports' element={<ProtectedRoute><FintechReports /></ProtectedRoute>} />
                <Route path='/fintech-users' element={<ProtectedRoute><FintechUsers /></ProtectedRoute>} />
                <Route path='/profile' element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />

                {/* Demo and Internal Pages */}
                <Route path='/consent' element={<CreateAndRedirectToBank />} />
                <Route path='/tpp' element={<DemoTPP />} />
                <Route path='/tester' element={<Tester />} />
                <Route path='/user-confirm' element={<UserConfirm />} />
                <Route path='/qa' element={<Qa />} />
                <Route path='/logger' element={<Logger />} />
                <Route path='/tpp-accomplish-consent' element={<TppAccomplishConsent />} />
                <Route path='*' element={<HomePage />} />
            </Routes>
        </>
    );
};

export default FintechApp;