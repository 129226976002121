import React, { useState } from 'react';
import { useDispatch } from 'react-redux'
import { StylePage, CertificateTable, CertificateUpdate } from '.';
import { SideBar, PageContent, SectionContainer, Grid, Card01, Row, Alert } from '../../../components';
import { setPage } from '../../../store/webNavigation/webNavSlice';
import { enforceLogin } from '../../../services/enforceLogin';

const FintechManagement = () => {
    enforceLogin();
    const dispatch = useDispatch();
    dispatch(setPage('settings'));
    const [alertMessage, setAlertMessage] = useState(null);

const renderAlert = () => {
        if (alertMessage) {
            if (alertMessage.submitSuccess) {
                return <Alert backgroundColor="#fcf1d2" color="#f2c94c" message={alertMessage.submitSuccess} marginTop="20px" height="45px" />
            }
            if (alertMessage.submitError) {
                return <Alert backgroundColor="#ECC7C4" color="#B42C2E" message={alertMessage.submitError} marginTop="20px" height="45px" />
            }
        }
    }
    
    return (
        <div className='row'>
            <SideBar />
            <PageContent justifyContent='start'>
                <SectionContainer title="Settings">
                    <Grid>
                        <Card01 padding="20px" paddingTop='20px' dropshadow={true}>
                            <StylePage />
                        </ Card01>

                        <Card01 padding="20px" paddingTop='20px' dropshadow={true}>
                            <div className="settings-card-title">
                                <h1>Update certificates</h1>
                            </div>

                            <Row>
                                {/* <CertificateTable /> */}
                                <CertificateUpdate setAlertMessage={setAlertMessage} />
                            </Row>

                            {renderAlert()}

                        </Card01>
                    </Grid>
                </SectionContainer>
            </PageContent>
        </div>
    );
};

export default FintechManagement;