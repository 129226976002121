import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'antd';
import 'antd/dist/antd.min.css';
import { Select, Row } from '../';
import { fetchAdvancedSearchReportsDetails, fetchOrganizations } from '../../store/management/managementActions';
import { DatePicker } from 'antd';
import moment from 'moment';

import { columns } from './columns'
import { RequestAdvSeachKeys } from '../../swagger/codeGen/billing-reports/src';
import { getCurrentDate } from '../../componentsTools/commonFunction/commonFunctions';
import { ORG_LIST } from '../../resources/constants/EnvironmentVariables';


const ReportDetailsTable = () => {
    const dispatch = useDispatch();

    const { advancedSearchData, transactionId, fintechId, organizations } = useSelector(state => state.management);
    const { range } = useSelector(state => state.filter);
    const monthBefore = new Date(new Date().setMonth(new Date().getMonth() - 1));

    const prepareParms = (currentPage) => {
        const prepared = Object.keys(searchParams)
            .filter((k) => searchParams[k] != null && searchParams[k] != undefined)
            .reduce((a, k) => ({ ...a, [k]: searchParams[k] }), {});

        if (currentPage) { prepared["currentPage"] = currentPage }
        if (prepared["httpStatus"] === '') { delete prepared["httpStatus"] };
        if (prepared["organizationName"] === '') { delete prepared["organizationName"] };
        if (prepared["apiDisplayName"] === '') { delete prepared["apiDisplayName"] };

        return {
            ...prepared,
            toDate: new Date(searchParams.toDate).toISOString(),
            fromDate: new Date(searchParams.fromDate).toISOString()
        }
    };

    // Local state
    const [searchParams, setSearchParams] = useState(
        {
            fromDate: getCurrentDate('-', monthBefore),
            toDate: getCurrentDate('-'),
            apiDisplayName: undefined,
            organizationName: undefined,
            billingIndication: undefined,
            httpStatus: undefined,
        }
    )
    // DONT PUT CURRENTPAGE IN SEARCHPARAMS - IT WILL CAUSE INFINITE LOOP
    const [currentPage, setCurrentPage] = useState(1);

    // 1. Initial load
    useEffect(() => { dispatch(fetchAdvancedSearchReportsDetails({ fintechId, transactionId, searchParams: prepareParms() })) }, []);

    // 2. Graph was clicked on other component - update table
    useEffect(() => {
        if (range) {
            setCurrentPage(1);
            setSearchParams({
                billingIndication: true,
                toDate: range[1],
                fromDate: range[0],
                httpStatus: '',
                organizationName: '',
                apiDisplayName: '',
            })
        }
    }, [range]);

    // 3. Manual filter selection
    useEffect(() => {
        setCurrentPage(1);
        dispatch(fetchAdvancedSearchReportsDetails({ fintechId, transactionId, searchParams: prepareParms(1) }));
    }, [searchParams]);

    useEffect(() => {
        if (organizations.length < 1) {
            dispatch(fetchOrganizations());
        }
    }, [organizations]);

    // Manual filter selection
    const onQueryParamChange = (e) => {
        const temp = { ...searchParams };
        if (e.target.name === 'fromDate') { if (e.target.value > searchParams.toDate) { temp.toDate = e.target.value } }
        temp[e.target.name] = e.target.value !== '' ? e.target.value : undefined;
        if (e.target.name === 'fromDate') { temp.fromDate = e.target.value.format("YYYY-MM-DD") }
        if (e.target.name === 'toDate') { temp.toDate = e.target.value.format("YYYY-MM-DD") }

        if (temp.fromDate && temp.toDate) { setSearchParams({ ...temp, currentPage: 1 }) }
    }

    // Manual pagination
    const onPageChange = (pageNum) => {
        setCurrentPage(pageNum);
        dispatch(fetchAdvancedSearchReportsDetails({ fintechId, transactionId, searchParams: prepareParms(pageNum) }))
    }

    // Adding index as key for table
    let formattedData = []
    if (advancedSearchData.reportsDetailsRecords) {
        formattedData = (advancedSearchData.reportsDetailsRecords).map(
            (i, index) => {
                return {
                    ...i, key: index
                }
            }
        )
    }
    return (
        <>
            <div className="settings-card-title"><Row marginBottom="20px" justifyContent="flex-start"><h1>Records</h1></Row></div>

            <form>
                <DatePicker
                    name="fromDate"
                    format='DD/MM/YYYY'
                    value={moment(searchParams.fromDate)}
                    onChange={(value) =>
                        onQueryParamChange({ target: { value, name: "fromDate" } })
                    }
                />

                <DatePicker
                    name="toDate"
                    format='DD/MM/YYYY'
                    value={moment(searchParams.toDate)}
                    onChange={(value) =>
                        onQueryParamChange({ target: { value, name: "toDate" } })
                    }
                    disabledDate={(current) => current && current < moment(searchParams.fromDate)}
                />

                <Select value={searchParams.apiDisplayName} name="apiDisplayName" onChange={onQueryParamChange}>
                    <option value={""}>Api Name</option>
                    {
                        Object.values(RequestAdvSeachKeys.ApiDisplayNameEnum).map(
                            (item, idx) => {
                                return <option key={idx} value={item}>{item}</option>;
                            })
                    }
                </Select>

                <Select value={searchParams.billingIndication} placeholder='Billable' name="billingIndication" onChange={onQueryParamChange}>
                    <option value={""}>Billing Type</option>
                    <option value={true}>Billable</option>
                    <option value={false}>Not billed</option>
                </Select>

                <Select value={searchParams.organizationName} placeholder='Organization' name="organizationName" onChange={onQueryParamChange}>
                    <option value={""}>Organization</option>
                    {
                        Object.values(organizations).map(
                            (item, idx) => {
                                return <option key={idx} value={item.dbName}>{item.dbName}</option>;
                            })
                    }
                </Select>

                <Select value={searchParams.httpStatus} name="httpStatus" onChange={onQueryParamChange}>
                    <option value={''}>Status Code</option>
                    <option value={200}>200</option>
                    <option value={201}>201</option>
                    <option value={204}>204</option>
                    <option value={401}>401</option>
                    <option value={402}>402</option>
                    <option value={403}>403</option>
                    <option value={408}>408</option>
                    <option value={500}>500</option>
                </Select>
            </form>

            <Table
                dataSource={[...formattedData]}
                columns={columns}
                size={'small'}
                loading={typeof (advancedSearchData.reportsDetailsRecords) !== 'undefined' ? false : true}
                pagination={{
                    current: currentPage,
                    onChange: (page) => onPageChange(page),
                    showSizeChanger: false,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${advancedSearchData.totalItems} items`,
                    pageSizeOptions: ["10"],
                    defaultPageSize: 10,
                    defaultCurrent: 1,
                    total: (advancedSearchData.reportsDetailsRecords ? advancedSearchData.totalItems : 0),
                    position: ['bottomCenter']
                }}
            >
            </Table>

        </>
    );
};

export default ReportDetailsTable;