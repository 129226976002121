import { AuthService } from '../services/auth/AuthService'

export const enforceLogin = () => {
    const authService = new AuthService({ env: process.env.NODE_ENV });
    const user = authService.currentAuthenticatedUser();
    user.then((res) => {

        // No cognito session at all
        if (!res.attributes) {
            window.location.href = "/";
        }

        // Actual user is not logged in
        if (res.attributes.email !== sessionStorage.getItem("OBH user")) {
            authService.signOut();
            window.location.href = "/";
        }
    })
}
