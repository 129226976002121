/*
 * upload and validate certificates
 * upload and validate certificates
 *
 * OpenAPI spec version: 1.0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
// import {ErrorDetailsResponse} from '../model/ErrorDetailsResponse';
// import {UploadCertificateRequest} from '../model/UploadCertificateRequest';
import {UploadCertificateResponse} from '../model/UploadCertificateResponse';

/**
* UploadCertificate service.
* @module api/UploadCertificateApi
* @version 1.0.3
*/
export class UploadCertificateApi {

    /**
    * Constructs a new UploadCertificateApi. 
    * @alias module:api/UploadCertificateApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the uploadCertificate operation.
     * @callback moduleapi/UploadCertificateApi~uploadCertificateCallback
     * @param {String} error Error message, if any.
     * @param {module:model/UploadCertificateResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Upload list of any type of certificates
     * Upload certificate
     * @param {String} X_TRANSACTION_ID 
     * @param {Object} opts Optional parameters
     * @param {module:model/UploadCertificateRequest} opts.body 
     * @param {module:api/UploadCertificateApi~uploadCertificateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    uploadCertificate(X_TRANSACTION_ID, opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'X_TRANSACTION_ID' is set
      if (X_TRANSACTION_ID === undefined || X_TRANSACTION_ID === null) {
        throw new Error("Missing the required parameter 'X_TRANSACTION_ID' when calling uploadCertificate");
      }

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        'X-TRANSACTION-ID': X_TRANSACTION_ID
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = UploadCertificateResponse;

      return this.apiClient.callApi(
        '/obh/certificate/upload/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}