/*
 * Consents API 
 * Manage consents for Fintech
 *
 * OpenAPI spec version: 1.0.7
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The AccessTokenRequest model module.
 * @module model/AccessTokenRequest
 * @version 1.0.7
 */
export class AccessTokenRequest {
  /**
   * Constructs a new <code>AccessTokenRequest</code>.
   * @alias module:model/AccessTokenRequest
   * @class
   * @param code {String} code - the get param that is given after bank redirect to tpp after consent confirmation
   * @param orgId {String} orgId - Organization Id
   */
  constructor(code, orgId) {
    this.code = code;
    this.orgId = orgId;
  }

  /**
   * Constructs a <code>AccessTokenRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AccessTokenRequest} obj Optional instance to populate.
   * @return {module:model/AccessTokenRequest} The populated <code>AccessTokenRequest</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AccessTokenRequest();
      if (data.hasOwnProperty('code'))
        obj.code = ApiClient.convertToType(data['code'], 'String');
      if (data.hasOwnProperty('orgId'))
        obj.orgId = ApiClient.convertToType(data['orgId'], 'String');
    }
    return obj;
  }
}

/**
 * code - the get param that is given after bank redirect to tpp after consent confirmation
 * @member {String} code
 */
AccessTokenRequest.prototype.code = undefined;

/**
 * orgId - Organization Id
 * @member {String} orgId
 */
AccessTokenRequest.prototype.orgId = undefined;

