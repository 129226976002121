import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { FileInputWrapperStyle, FileInputInfoTextStyle, FileInputContentAreaStyle } from './InputFile.Style';

import { UploadIcon, Col } from '../../';

const InputFile = ({
    title,
    fileName,
    errorMessage,
    isValid,
    change,
    name,
    width,
    className
}) => {

    const hiddenFileInput = useRef();
    const handleClick = event => { hiddenFileInput.current.click() };

    return (
        <FileInputWrapperStyle width={width}>
            <FileInputInfoTextStyle>{title}</FileInputInfoTextStyle>
            <label style={{ cursor: "pointer" }}>
                <FileInputContentAreaStyle onClick={handleClick}>
                    {fileName === '' ?
                        <><UploadIcon /><span className='file-button' > Choose File </span></> :
                        <span className='file-name' >{fileName}</span>
                    }
                    {errorMessage !== '' ? <span className='file-error' > {errorMessage} </span> : null}
                    {isValid ? <span className='file-success' style={{ color: 'green' }}>{'✓'}</span> : null}
                </FileInputContentAreaStyle>
            </label>
            <input className={className} name={name} ref={hiddenFileInput} style={{ display: "none" }} onChange={change} type="file"></input>

        </FileInputWrapperStyle>
    );
};

InputFile.propTypes = {
    title: PropTypes.string,
    isValid: PropTypes.bool,
    errorMessage: PropTypes.string,
    fileName: PropTypes.string,
    width: PropTypes.string,
};

export default InputFile;