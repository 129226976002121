import React from 'react';
import PropTypes from "prop-types"
import { AlertContainer } from "./Alert.style"

const Alert = ({
    message,
    color,
    backgroundColor = "#F2C94C",
    marginTop,
    height = "25px",
}) => {

    return (
        <AlertContainer
            backgroundColor={backgroundColor}
            color={color}
            marginTop={marginTop}
            height={height}
        >
            {message}
        </AlertContainer>
    )
}

Alert.propTypes = {
    color: PropTypes.string,
    message: PropTypes.string,
    backgroundColor: PropTypes.string,
    marginTop: PropTypes.string,
    height: PropTypes.string,
}

export default Alert