import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { uploadCertificate } from "../../store/registration/registrationSliceActions";
import InputFile from '../UI/InputFile/InputFile';

const InputUploadCertificate = (props) => {
    const dispatch = useDispatch();
    const [fileName, setFileName] = useState("");
    const hiddenFileInput = useRef();
    const handleClick = () => { hiddenFileInput.current.click() };
    const transactionId = useSelector((state) => state.registration.transactionId);

    const handleChange = async (e) => {
        e.preventDefault();
        const reader = new FileReader();
        reader.onloadend = () => {
            dispatch(uploadCertificate(props.certificateType, reader.result, transactionId));
            setFileName(e.target.files[0].name);
        }
        reader.readAsBinaryString(e.target.files[0]);
    }

    return (
        <InputFile
            className={props.className}
            isValid={props.isValid}
            errorMessage={props.errorMessage}
            fileName={fileName}
            title={props.title}
            click={handleClick}
            change={handleChange}>
        </InputFile>
    );
};

InputUploadCertificate.propTypes = {
    certifiacteType: PropTypes.string,
};

export default InputUploadCertificate;