import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { Col, PageContent, Card01, Button2, ButtonOutline, ProgressCircles, InputUploadCertificate, Row, OBlogo } from '../../../components/index';

const CertificatesUpload = () => {
    const navigateTo = useNavigate();
    const certificatesErrors = useSelector((state) => state.registration.certificateItem);

    const checkIfCanForward = () => {
        return !(certificatesErrors['QSEAL_PRIVATE']['errMsg'] === ''
            && certificatesErrors['QSEAL_PRIVATE']['hashCertificate'].length > 1 &&
            certificatesErrors['QWAC_PUBLIC']['errMsg'] === ''
            && certificatesErrors['QWAC_PUBLIC']['hashCertificate'].length > 1 &&
            certificatesErrors['QWAC_PRIVATE']['errMsg'] === ''
            && certificatesErrors['QWAC_PRIVATE']['hashCertificate'].length > 1
        );
    }

    const checkValidity = (certificateType) => {
        const certificateNode = certificatesErrors[certificateType];
        return certificateNode['errMsg'] === '' && certificateNode['hashCertificate'].length > 1;
    }

    return (
        <div className='row'>
            <PageContent bgImage="https://res.cloudinary.com/dojmo7vcc/image/upload/v1671286848/OBH-BG_o2jm1r.jpg">
                <Card01 radius={24} minWidth="700px" padding="55px">
                    <Row marginTop="45px" marginBottom='18px'><OBlogo size="63px" /></Row>
                    <Row marginBottom='24px'><div className="enroll-slogan"><h1>Welcome to Open Banking Hub</h1></div></Row>
                    <ProgressCircles step={2} width="420px" />
                    <Row><div className="enroll-title"><h1>Create New Account</h1></div></Row>
                    <Row marginBottom='24px'><div className="enroll-subtitle"><p>Please upload your QWAC certificate and your private keys</p></div></Row>

                    <InputUploadCertificate className="cyFile1" title={"QSEAL Private Upload"} isValid={checkValidity('QSEAL_PRIVATE')} errorMessage={certificatesErrors['QSEAL_PRIVATE']['errMsg']}
                        certificateType={'QSEAL_PRIVATE'} />

                    <InputUploadCertificate className="cyFile2" title={"QWAC Public Upload"} isValid={checkValidity('QWAC_PUBLIC')}
                        errorMessage={certificatesErrors['QWAC_PUBLIC']['errMsg']}
                        certificateType={'QWAC_PUBLIC'} />

                    <InputUploadCertificate className="cyFile3" title={"QWAC Private Upload"} isValid={checkValidity('QWAC_PRIVATE')}
                        errorMessage={certificatesErrors['QWAC_PRIVATE']['errMsg']}
                        certificateType={'QWAC_PRIVATE'} />


                    <Col justifyContent="space-between">
                        <Button2
                            className="cySubmit"
                            width="370px"
                            marginTop={'30px'}
                            disable={checkIfCanForward()}
                            backgroundColor={"#115DFC"}
                            size='medium'
                            label={'Continue'}
                            rounded={true}
                            arrow={true}
                            onClick={ !checkIfCanForward() ? () => { navigateTo('/user-and-password') }: null }
                        />

                        <ButtonOutline
                            width="370px"
                            marginTop={'30px'}
                            label={'Back to login'}
                            rounded={true}
                            height="50px"
                            onClick={() => { navigateTo('/') }}
                        />
                    </Col>



                </Card01>
            </PageContent>
        </div>
    );
};

export default CertificatesUpload;
