import { createSlice } from '@reduxjs/toolkit';
import Uuid from '../../utils/libs/Uuid';

const initialState = {
  fintechId: '',
  uploadStatus: false,
  user: '',
  password: '',
  logoStream: '',
  fontColor: '',
  backgroundColor: '',
  fontSize: '',
  fontName: '',
  certificateItem: {
    QSEAL_PUBLIC: {
      typeCert: "QSEAL_PUBLIC",
      hashCertificate: '',
      errMsg: '',
      stream: '',
    },

    QSEAL_PRIVATE: {
      typeCert: "QSEAL_PRIVATE",
      hashCertificate: '',
      errMsg: '',
      stream: '',
    },

    QWAC_PUBLIC: {
      typeCert: "QWAC_PUBLIC",
      hashCertificate: '',
      errMsg: '',
      stream: '',
    },

    QWAC_PRIVATE: {
      typeCert: "QWAC_PRIVATE",
      hashCertificate: "",
      errMsg: '',
      stream: '',
    }
  },
  enrollMsg: '',
  transactionId: Uuid.uuid(),
};

const registrationSlice = createSlice({
  name: 'registration',
  initialState: initialState,
  reducers: {
    createCertificateSuccess(state, action) {
      state.certificateItem[`${action.payload.certificatePayload['typeCert']}`] = action.payload.certificatePayload;
      if (action.payload.fintechId) state.fintechId = action.payload.fintechId;
    },
    createCertificateError(state, action) {
      state.certificateItem[`${action.payload['typeCert']}`] = action.payload;
    },
    createUserAndPassword(state, action) {
      state.user = action.payload.user;
      state.password = action.payload.password;
    },
    createStylesAndLogoSuccess(state, action) {
      state[Object.keys(action.payload)[0]] = Object.values(action.payload)[0];
    },
    enrollFinetechAccountSuccess(state, action) {
      state.fintechId = action.payload.fintechId;
      state.uploadStatus = action.payload.uploadStatus;
    },
    enrollFinetechAccountError(state, action) {
      state.enrollMsg = action.payload.enrollMsg;
    },
    resetRegistration: () => {
      return { ...initialState, transactionId: Uuid.uuid() };
    },

  },
});

export const registrationActions = registrationSlice.actions;

export default registrationSlice;