import UtilsBase from './UtilsBase';
import { v4 } from 'uuid';
export default class Uuid extends UtilsBase {
    /**
     * 
     * @param {*} env 
     */
    constructor(envObj) {
        const { env } = envObj;
        super(env);
    }

    /**
     * 
     */
    static uuid() {
        const functionName = "uuid";
        try {
            return v4().replace(/-/gi, '');
            // return v4();
        } catch (error) {
            this.log.error(`${this.logName} ${functionName} `, error);
            return error;
        }
    }

    /**
    * 
    * @returns 
    */
    static uuid32() {
        const functionName = "uuid32";
        try {
            return v4().replace(/-/gi, '');
        } catch (error) {
            this.log.error(`${this.logName} ${functionName} `, error);
            return error;
        }
    }
}
