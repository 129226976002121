import BaseServices from "../../BaseServices"
import RestApi from "../../../utils/libs/RestApi";
import { API_URL, REST_TIMEOUT, ENV, EC2_ENV } from "../../../resources/constants/EnvironmentVariables.js";
import ConsentRoutes from "../../../resources/constants/Routes/api-routes-consent.json"
import Uuid from "../../../utils/libs/Uuid";
// import { cert, key } from "./mtls/mtlsKeys";
import { API } from "aws-amplify";
import { CommonHelper } from "../../../BusinessBlocks/Common/CommonHelper";
import { OrganizationDetails, ErrorDetailsResponse } from "../../../swagger/codeGen/consent-routes/src/index";
import { Consents } from "../../../swagger/codeGen/PSD2-BOI-1.5.0/src/model/Consents";

export class EstablishConsentService extends BaseServices {

    /**
     * 
     * @param {*} env 
     */
    constructor(env) {
        super(env);
        this.restApi = new RestApi({ env: ENV, timeout: REST_TIMEOUT });
        this.cognitoApiRoutePrefix = "/tpp-api";
        this.lambdaName = "tppapi";
    }

    /**
     * 
     * @param {*} requestBody 
     * @returns 
     */
    async establishConsent(requestBody, psdVersion) {
        const logFunc = `${this.serviceName} establishConsent: `;

        try {

            if (!ConsentRoutes['post']) throw new Error("POST is not defined in RegistrationRoutes");
            if (!ConsentRoutes.post.establish_consent) throw new Error("'state_upload_cert' is not defined in RegistrationRoutes");

            const templateUrl = ConsentRoutes.post.establish_consent;
            const parameters = { "domain-server": API_URL };

            // initialize of Consents based on PSD2 
            const consents = await CommonHelper.initializeConsentObjByType(requestBody.type, psdVersion, requestBody);

            const request = {
                key: requestBody.key,
                orgId: `${requestBody.orgId}`,
                consentRequestBody: {
                    ...consents, access: requestBody.access
                }
            }
            // execute REST api
            const transactionId = Uuid.uuid();
            let axiosRes;
            if (EC2_ENV) {
                axiosRes = await this.restApi.post(templateUrl, parameters, request, { transactionId });
            } else {
                const url = templateUrl.replace('#domain-server#', '/obh-enroll');

                const baseUrl = await API.endpoint('enroll');
                axiosRes = await this.restApi.post(baseUrl + url, parameters, request, { transactionId });
            }

            this.log.debug(logFunc + ' Request Body : ', JSON.stringify(axiosRes));

            return axiosRes;

        } catch (error) {
            this.log.error(logFunc, JSON.stringify(error));
        }
    }
    /**
     * 
     * @param {*} psuId 
     * @param {*} fintechId 
     * @param {*} tppRedirectUri 
     * @returns 
     */
    async createUserSession(psuId, fintechId, tppRedirectUri) {
        const logFunc = `${this.serviceName} createUserSession: `;
        try {
            if (!ConsentRoutes['post']) throw new Error("POST is not defined in RegistrationRoutes");
            if (!ConsentRoutes.post.create_user_session) throw new Error("'state_upload_cert' is not defined in RegistrationRoutes");

            const templateUrl = ConsentRoutes.post.create_user_session;
            const parameters = { "domain-server": API_URL };
            const request = {
                fintechId: fintechId,
                psuId,
                tppRedirectUrl: tppRedirectUri,
            }

            const transactionId = Uuid.uuid();
            let axiosRes;
            if (EC2_ENV) {
                axiosRes = await this.restApi.post(templateUrl, parameters, request, { transactionId });
            }
            else {
                const url = templateUrl.replace('#domain-server#', '/obh-enroll');
                const baseUrl = await API.endpoint('enroll');
                axiosRes = await this.restApi.post(baseUrl + url, parameters, request, { transactionId });
            }

            return axiosRes;

        } catch (error) {
            this.log.error(logFunc, JSON.stringify(error));
        }
    }

    /**
     * 
     * @param {*} param0 
     * @returns 
     */
    async getAccessToken({ code, psuId, tppRedirectUri, orgId, fintechId }) {
        const logFunc = `${this.serviceName} getAccessToken: `;
        try {
            if (!ConsentRoutes['post']) throw new Error("POST is not defined in RegistrationRoutes");
            if (!ConsentRoutes.post.get_access_token) throw new Error("'state_upload_cert' is not defined in RegistrationRoutes");

            const templateUrl = ConsentRoutes.post.get_access_token;
            const parameters = { "domain-server": API_URL };
            const request = { code, tppRedirectUri, orgId };
            const header = { "Fintech-ID": fintechId, "PSU-ID": psuId }

            const transactionId = Uuid.uuid();

            let axiosRes;
            if (EC2_ENV) {
                axiosRes = await this.restApi.post(templateUrl, parameters, request, {...header, transactionId });
            } else {
                const url = templateUrl.replace('#domain-server#', '/obh-enroll');
                const baseUrl = await API.endpoint('enroll');
                axiosRes = await this.restApi.post(baseUrl + url, parameters, request, {...header,  transactionId });
            }

            return axiosRes;
        } catch (error) {
            this.log.error(logFunc, JSON.stringify(error));
        }
    }


    /**
      * 
      * @param {*} consentID 
      * @returns 
      */
    async getConsent(consentId, psuId, fintechId) {
        const logFunc = `${this.serviceName} getConsent: `;

        try {
            if (!ConsentRoutes['get']) throw new Error("get is not defined in RegistrationRoutes");
            if (!ConsentRoutes.get.get_consent) throw new Error("'get_consent' is not defined in RegistrationRoutes");

            const templateUrl = ConsentRoutes.get.get_consent;
            const parameters = { "domain-server": API_URL, 'consent-id':consentId };
            
            const header = { "Fintech-ID": fintechId, "PSU-ID": psuId }


            let axiosRes;
            if (EC2_ENV) { axiosRes = await this.restApi.get(templateUrl, parameters, {...header }) }
            else {
                const url = templateUrl.replace('#domain-server#', '/obh-enroll').replace('#consent-id#', consentId);

                const baseUrl = await API.endpoint('enroll');
                axiosRes = await this.restApi.get(baseUrl + url, parameters, { ...header });
            }
            try {
                return axiosRes

            }
            catch (error) {
                this.log.error(logFunc, JSON.stringify(error))
            }
        } catch (error) { this.log.error(logFunc, JSON.stringify(error)) }
    } // end of getConsent

    /**
      * 
      * @param {*} consentID 
      * @returns 
      */
    async getAccountList(consentId, psuId, fintechId) {
        const logFunc = `${this.serviceName} getAccountList: `;

        try {
            if (!ConsentRoutes['get']) throw new Error("get is not defined in RegistrationRoutes");
            if (!ConsentRoutes.get.get_account_list) throw new Error("'get_account_list' is not defined in RegistrationRoutes");

            const templateUrl = ConsentRoutes.get.get_account_list;
            const parameters = { "domain-server": API_URL };
            const params = { "withBalance": false };
            const header = { "Consent-ID": consentId, "Fintech-ID": fintechId, "PSU-ID": psuId }

            let axiosRes;
            if (EC2_ENV) {
                axiosRes = await this.restApi.get(templateUrl, parameters, { ...header, params })
            } else {
                const url = templateUrl.replace('#domain-server#', '/obh-enroll');
                const baseUrl = await API.endpoint('enroll');
                axiosRes = await this.restApi.get(baseUrl + url, parameters, { ...header, params });
            }

            this.log.debug(logFunc, JSON.stringify(axiosRes))
            try {
                return axiosRes
            }
            catch (error) {
                this.log.error(logFunc, JSON.stringify(error))
            }
        } catch (error) { this.log.error(logFunc, JSON.stringify(error)) }
    }

    async getAccountDetails(consentId, psuId, fintechId, accountId) {
        const logFunc = `${this.serviceName} getAccountDetails: `;
        try {
            if (!ConsentRoutes['get']) throw new Error("get is not defined in RegistrationRoutes");
            if (!ConsentRoutes.get.get_account_details) throw new Error("'get_account_details' is not defined in RegistrationRoutes");

            const templateUrl = ConsentRoutes.get.get_account_details;
            const parameters = { "domain-server": API_URL, "account-id": accountId };
            const params = { "withBalance": false };
            const header = { "Consent-ID": consentId, "Fintech-ID": fintechId, "PSU-ID": psuId }

            let axiosRes;
            if (EC2_ENV) {
                axiosRes = await this.restApi.get(templateUrl, parameters, { ...header, params })
            } else {
                const url = templateUrl.replace('#domain-server#', '/obh-enroll');

                const baseUrl = await API.endpoint('enroll');
                axiosRes = await this.restApi.get(baseUrl + url, parameters, { ...header, params });
            }

            try {
                this.log.debug(logFunc, JSON.stringify(axiosRes))
                return axiosRes
            }
            catch (error) {
                this.log.error(logFunc, JSON.stringify(error))
            }
        }
        catch (error) { this.log.error(logFunc, JSON.stringify(error)) }
    }

    async getAccountBalances(consentId, psuId, fintechId, accountId) {
        const logFunc = `${this.serviceName} getAccountBalances: `;
        try {
            if (!ConsentRoutes['get']) throw new Error("get is not defined in RegistrationRoutes");
            if (!ConsentRoutes.get.get_account_balances) throw new Error("'get_account_balances' is not defined in RegistrationRoutes");

            const templateUrl = ConsentRoutes.get.get_account_balances;
            const parameters = { "domain-server": API_URL, "account-id": accountId };
            const params = {};
            const header = { "Consent-ID": consentId, "Fintech-ID": fintechId, "PSU-ID": psuId }

            let axiosRes;
            if (EC2_ENV) {
                axiosRes = await this.restApi.get(templateUrl, parameters, { ...header, params })
            } else {
                const url = templateUrl.replace('#domain-server#', '/obh-enroll');

                const baseUrl = await API.endpoint('enroll');
                axiosRes = await this.restApi.get(baseUrl + url, parameters, { ...header, params });
            }
            this.log.debug(logFunc, JSON.stringify(axiosRes))
            try {
                return axiosRes
            }
            catch (error) {
                this.log.error(logFunc, JSON.stringify(error))
            }
        }
        catch (error) { this.log.error(logFunc, JSON.stringify(error)) }
    }

    async getAccountTransactions(consentId, psuId, fintechId, accountId) {
        const logFunc = `${this.serviceName} getAccountTransactions: `;
        try {
            if (!ConsentRoutes['get']) throw new Error("get is not defined in RegistrationRoutes");
            if (!ConsentRoutes.get.get_account_transactions) throw new Error("'get_account_transactions' is not defined in RegistrationRoutes");

            const templateUrl = ConsentRoutes.get.get_account_transactions;
            const parameters = { "domain-server": API_URL, "account-id": accountId };
            const params = { "dateFrom": "2022-04-01", bookingStatus: "booked" };
            const header = { "Consent-ID": consentId, "Fintech-ID": fintechId, "PSU-ID": psuId }

            let axiosRes;
            if (EC2_ENV) {
                axiosRes = await this.restApi.get(templateUrl, parameters, { ...header, params });
            } else {
                const url = templateUrl.replace('#domain-server#', '/obh-enroll');

                const baseUrl = await API.endpoint('enroll');
                axiosRes = await this.restApi.get(baseUrl + url, parameters, { ...header, params });
            }
            this.log.debug(logFunc, JSON.stringify(axiosRes))
            try {
                return axiosRes

            }
            catch (error) { 
                this.log.error(logFunc, JSON.stringify(error)) }
        }
        catch (error) { this.log.error(logFunc, JSON.stringify(error)) }
    }

    /**
     * 
     * @returns 
     */
    async getOrganizations() {
        const logFunc = `${this.serviceName} getOrganizations: `;
        try {
            if (!ConsentRoutes['get']) throw new Error("get is not defined in RegistrationRoutes");
            if (!ConsentRoutes.get.get_organizations) throw new Error("'get_organizations' is not defined in RegistrationRoutes");

            const templateUrl = ConsentRoutes.get.get_organizations;
            const parameters = { "domain-server": API_URL };

            let axiosRes;
            if (EC2_ENV) {
                axiosRes = await this.restApi.get(templateUrl, parameters, {});
            } else {
                const url = templateUrl.replace('#domain-server#', '/obh-enroll');
                const baseUrl = await API.endpoint('enroll');
                axiosRes = await this.restApi.get(baseUrl + url, parameters, {});
            }

            const { status, data } = axiosRes;
            let response;
            if (status === 200)
                response = data;
            else if ([408, 404, 403, 401, 400, 500].includes(status)) {
                this.log.error(logFunc + ' Server Response Headers: ', JSON.stringify(data));
                response = ErrorDetailsResponse.constructFromObject(data, null);
            }
            else {
                response = ErrorDetailsResponse.constructFromObject(data, null);
            }
            return response;

        } catch (error) {
            this.log.error(logFunc, JSON.stringify(error));
        }
    }

    /**
     * 
     * @param {*} orgId 
     * @returns 
     */
    async getOrganizationDetails(orgId) {
        const logFunc = `${this.serviceName} getOrganizationDetails: `;

        try {
            if (!ConsentRoutes['get']) throw new Error("get is not defined in RegistrationRoutes");
            if (!ConsentRoutes.get.get_organization_details) throw new Error("'get_organizations' is not defined in RegistrationRoutes");

            const templateUrl = ConsentRoutes.get.get_organization_details;
            const parameters = { "domain-server": API_URL, orgId };

            let axiosRes;
            if (EC2_ENV) {
                axiosRes = await this.restApi.get(templateUrl, parameters, {});
            } else {
                const url = templateUrl.replace('#domain-server#', '/obh-enroll');
                const baseUrl = await API.endpoint('enroll');
                axiosRes = await this.restApi.get(baseUrl + url, parameters, {});
            }
            const { status, data } = axiosRes;
            let response;
            if (status === 200) {
                response = OrganizationDetails.constructFromObject(data);
            }
            else if ([408, 404, 403, 401, 400, 500].includes(status)) {
                this.log.error(logFunc + ' Server Response Headers: ', JSON.stringify(data));
                response = ErrorDetailsResponse.constructFromObject(data, null);
            }
            else {
                response = ErrorDetailsResponse.constructFromObject(data, null);
            }
            return response;

        } catch (error) {
            this.log.error(logFunc, JSON.stringify(error));
        }
    }
}
