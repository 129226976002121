import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { setPage } from '../../store/webNavigation/webNavSlice';
import { InputText, PageContent, Card01, OBlogo, Container, Button2, Row, Col, Grid, RollingSpinner } from '../../components';

const Tester = () => {
    const dispatch = useDispatch();
    dispatch(setPage('ignore'));
    document.title = "Confirm User";

    const [response, setResponse] = useState(null);
    const param = new URLSearchParams(window.location.search).get('code')

    useEffect(() => {
        console.log('fetching')

    }, [])


    return (
        <div className='row'>
            <PageContent bgImage="https://res.cloudinary.com/dojmo7vcc/image/upload/v1671286848/OBH-BG_o2jm1r.jpg">

                <Card01 width="530px" padding="80px" height="595px">
                    <Row marginTop="48px" marginBottom='24px'>
                        <OBlogo size="63px" />
                    </Row>

                    <h1>You are being redirected</h1>
                    <RollingSpinner />
                    {param}
                </Card01>
            </PageContent>
        </div>
    )
}

export default Tester