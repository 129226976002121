import styled from 'styled-components';

export const H1Container = styled.h1`
  width: ${({ width }) => width};
  font-size: 6.5vw;
  /* font-size: ${({ size }) => (size === 'small' ? 20 : size === 'medium' ? 30 : 70)}px; */
  color: ${({ color }) => color};
  justify-content: ${({ justify }) => justify};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-top: ${({ marginTop }) => marginTop};
  padding: ${({ padding }) => padding};
  font-family: ${({ fontFamily }) => fontFamily};
  display: flex;
  font-weight: 700;
`;
