import React from 'react'
import PropTypes from "prop-types"
import Icon from './User.svg'
import { IMG } from './User.style'

export default function User({
  size = "50px",
  color = "red",
}) {
  return (
    <IMG
    color={color}
      src={Icon}
      alt="User"
      size={size}
    />
  )
}

User.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
}
