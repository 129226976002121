import React from 'react';
import PropTypes from 'prop-types';
import { Container,PaginBtn } from './Pagination.style';

const Pagination = ({
    marginTop = "20px",
    currPage = 1,
    totalPages = 1,
    prevPage = () => { },
    nextPage = () => { },
    firstPage = () => { },
    lastPage = () => { },
    goToPage = () => { },

}) => {

    return (
        <Container marginTop={marginTop}>
            <PaginBtn onClick={firstPage}>{'<<'}</PaginBtn>
            <PaginBtn onClick={prevPage}>{'<'}</PaginBtn>
            <p>Page: {currPage} / {totalPages}</p>
            <PaginBtn onClick={nextPage}>{'>'}</PaginBtn>
            <PaginBtn onClick={lastPage}>{'>>'}</PaginBtn>

        </Container>

    );
};

Pagination.Pagination = {
    marginTop: PropTypes.string,
    currPage: PropTypes.number,
    totalPages: PropTypes.number,
    prevPage: PropTypes.func,
    nextPage: PropTypes.func,
    firstPage: PropTypes.func,
    lastPage: PropTypes.func,
    goToPage: PropTypes.func
};

export default Pagination;