import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card01, PageContent, Col, Button2, ButtonOutline, Popup, InputText, InputValidationInfo, Row, ProgressCircles, OBlogo } from '../../../components/index';
import { setUserAndPassword } from "../../../store/registration/registrationSliceActions";
import passwordValidation from '../../../componentsTools/validations/passwordValidation';
import { useNavigate } from 'react-router';
import isMailValidation from '../../../componentsTools/validations/isMailValidation';

const UserAndPassword = () => {
    const dispatch = useDispatch();
    const navigateTo = useNavigate();

    const [errorUser, setErrorUser] = useState(false);
    const [errorPassword, setErrorPassword] = useState(false);
    const [errorConfirm, setErrorConfirm] = useState(false);
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');
    const [TOU, setTOU] = useState(false);
    const [TOUPopup, setTOUPopup] = useState(false);
    const [passVisibility, setPassVisibility] = useState(false);
    const [passVisibility2, setPassVisibility2] = useState(false);

    const checkUser = (usr) => {
        if (isMailValidation(usr)) return true;
        return false;
    };

    const checkConfirm = (conf) => {
        if (conf === password) return true;
        return false;
    };

    const passChange = (e) => { setPassword(e.target.value) };
    const confirmChange = (e) => { setConfirm(e.target.value) };
    const userChange = (e) => { setUser(e.target.value) };

    useEffect(() => {
        dispatch(setUserAndPassword(user, password));
        setErrorUser(!checkUser(user));
        setErrorPassword(!passwordValidation(password));
        setErrorConfirm(!checkConfirm(confirm));
    }, [password, user, confirm]);

    return (
        <div className='row'>
            <Popup
                show={TOUPopup}
            >
                <Card01 radius={24} minWidth="650px" padding="55px" paddingTop='20px'>
                    <h1>Terms of Use</h1>
                    <Button2
                        width="170px"
                        marginTop={'30px'}
                        backgroundColor={"#115DFC"}
                        size='medium'
                        label={'Close'}
                        rounded={true}
                        onClick={() => { setTOUPopup(false) }}
                        />
                </Card01>
            </Popup>

            <PageContent bgImage="https://res.cloudinary.com/dojmo7vcc/image/upload/v1671286848/OBH-BG_o2jm1r.jpg">
                <Card01 radius={24} minWidth="700px" padding="55px">

                    <Row marginTop="45px" marginBottom='18px'><OBlogo size="63px" /></Row>
                    <Row marginBottom='24px'><div className="enroll-slogan"><h1>Welcome to Open Banking Hub</h1></div></Row>
                    <ProgressCircles step={3} width="420px" />

                    <InputText className="cyField1" placeholder='User Email' change={userChange} type="email" value={user} />

                    <Row>

                        <InputText className="cyField2" placeholder='Password' change={passChange} value={password} type={passVisibility ? "text" : "password"} />

                        <Col width='100px'>
                            <Button2 tabIndex={-1} label="👁️" backgroundColor='#ffffff' onClick={() => setPassVisibility(!passVisibility)} />
                        </Col>
                    </Row>

                    <Row>
                        <InputText className="cyField3" placeholder='Password Confirm' change={confirmChange} value={confirm} type={passVisibility2 ? "text" : "password"} />
                        <Col width='100px'>
                            <Button2 tabIndex={-1} label="👁️" backgroundColor='#ffffff' onClick={() => setPassVisibility2(!passVisibility2)} />
                        </Col>
                    </Row>

                    <InputValidationInfo text={'Password should contain uppercase, number and a special character'} isValid={!errorPassword} />
                    <InputValidationInfo text={'Passwords dont match'} isValid={!errorConfirm} />


                    <Row marginTop='20px'>
                        <div className="width-30">
                            <input type="checkbox" id="tou" name="tou" checked={TOU} onClick={() => setTOU(!TOU)} />
                        </div>
                        <div onClick={() => {setTOU(!TOU) }} className="percent-100">
                            <p  style={{ cursor: "pointer"}} className="p-no-margin grey-text" >I accept and agree to the <span className='tou-link' onClick={() => { setTOUPopup(true) }}>terms of use</span></p>
                        </div>
                    </Row>

                    <Col justifyContent="space-between">
                        <Button2
                            className="cySubmit"
                            width="370px"
                            marginTop={'30px'}
                            disable={!checkConfirm(confirm) || !checkUser(user) || !passwordValidation(password) || !TOU}
                            backgroundColor={"#115DFC"}
                            size='medium'
                            label={'Continue'}
                            rounded={true}
                            arrow={true}
                            onClick={!(!checkConfirm(confirm) || !checkUser(user) || !passwordValidation(password)) && TOU ? () => { navigateTo('/logo-and-style') } : null}
                        />

                        <ButtonOutline
                            width="370px"
                            marginTop={'30px'}
                            label={'Back to login'}
                            rounded={true}
                            height="50px"
                            onClick={() => { navigateTo('/') }}
                        />
                    </Col>

                </Card01>
            </PageContent >
        </div>
    );
};

export default UserAndPassword;