import styled from 'styled-components';

export const H1Container = styled.h1`
  font-weight: ${({ weight }) => weight};
  width: ${({ width }) => width};
  font-size: ${({ fontSize }) => fontSize};
  color: ${({ color }) => color};
  justify-content: ${({ justify }) => justify};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-top: ${({ marginTop }) => marginTop};
  padding: ${({ padding }) => padding};
  font-family: ${({ fontFamily }) => fontFamily};
  display: flex;
`;
