import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Logger from '../utils/libs/Logger';
import { setPage } from '../store/webNavigation/webNavSlice';
import { getTheme } from '../store/theme/themeSlice';
import { Container, Button2, Row, Col, Input, Popup, Card01 } from '../components';
import { UploadCertificateService } from '../services/fintechAdmin/createFintechAccount/UploadCertificateService';
import { ENV } from '../resources/constants/EnvironmentVariables';
import isMailValidation from '../componentsTools/validations/isMailValidation';
import { ErrorDetailsResponse } from '../swagger/codeGen/enroll-fintech/src';

// OBHMATRIX[DDMMYYYY]

const Qa = () => {
    const dispatch = useDispatch();
    dispatch(setPage('ignore'));
    document.title = "QA Toolkit"

    const [stylesObject, setStylesObject] = useState();
    const { transactionId, fintechId } = useSelector((state) => state.management);
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const [popupError, setPopupError] = useState(false);

    async function fetchStyleObject(transactionId, fintechId) {
        try {
            dispatch(getTheme({ transactionId, fintechId })).then((res) => {
                setStylesObject(JSON.parse(res.payload))
            }
            )
        } catch (error) {
        }
    }

    const doDelete = async () => {
        const service = new UploadCertificateService(ENV);
        const response = await service.deleteFintech({ fintechId, password, email }, transactionId);

        if (response.code === '400') {
            setPopupError(true);
        } else {
            setPassword('');
            setEmail('');
            setStylesObject(null);
            setDeleteSuccess(true);
        }
    }

    useEffect(() => {
        if (!isMailValidation(email) && email) {
            setEmailError(`Wrong email format`);
        } else {
            setEmailError(``);

        }
    }, [email]);

    useEffect(() => { if (transactionId && fintechId) { fetchStyleObject(transactionId, fintechId) } }, []);

    if (stylesObject) return (
        <Container padding='10px' backgroundColor='#444444' height={"100vh"} justifyContent="flex-start">
            <Popup show={popupError} >
                <Card01 padding="40px" paddingTop="30px">
                    <Col height="150px">
                        <h1>Wrong phrase, please try again</h1>
                        <Button2 width="200px" rounded label="Close" onClick={() => setPopupError(false)} />
                    </Col>
                </Card01>
            </Popup>
            <Row><h1 className="qa-h1">🛠️ Qa Toolkit</h1></Row>

            <Row marginTop='30px' width="500px">
                <Input placeholder="Protection Phrase" width={"300px"} onChange={(e) => setPassword(e.target.value)} value={password} />
            </Row>

            <Row marginTop='30px' width="500px">
                <Input placeholder="User email to delete (optional) " width={"300px"} onChange={(e) => setEmail(e.target.value)} value={email} />
            </Row>

            <Row marginTop='30px' width="500px">
                {emailError ? <div color="#ff0000">{emailError}</div> : null}
            </Row>

            <Row marginTop='30px' width="500px">
                <Button2
                    width="200px"
                    rounded
                    label="Delete Certificate"
                    onClick={doDelete}
                ></Button2>
            </Row>

            <Col alignItems='flex-start' marginTop='40px'>
                <p className="qa-h3">Info:</p>
                <p className="qa-p"><b>FintechId:</b> {fintechId}</p>
                <p className="qa-p"><b>TrasactionId:</b> {transactionId}</p>
                <p></p>
                <p className="qa-h3">Fintech Logo:</p>
                <img src={stylesObject.logoUrl} alt="Fintech Logo" />
            </Col>
        </Container>
    )
    else return (
        <Container padding='10px' backgroundColor='#444444' height={"100vh"} justifyContent="flex-start">
            <Row>
                {deleteSuccess ?
                    <h1 className="qa-h1">Fintech was deleted from DynamoDB.</h1>
                    : <h1 className="qa-h1">You are not logged in</h1>
                }
            </Row>
        </Container>
    )
}

export default Qa