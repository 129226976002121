/*
 * Consents API 
 * Manage consents for Fintech
 *
 * OpenAPI spec version: 1.0.7
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The EstablishConsentRequest model module.
 * @module model/EstablishConsentRequest
 * @version 1.0.7
 */
export class EstablishConsentRequest {
  /**
   * Constructs a new <code>EstablishConsentRequest</code>.
   * @alias module:model/EstablishConsentRequest
   * @class
   * @param consentRequestBody {Object} consents Obj structure is taken from PSD2-XS2AFramework-1.4.2.yaml
   * @param key {String} fintechId - fintech unique id
   * @param orgId {String} orgId - The organization that we need to make the consent
   */
  constructor(consentRequestBody, key, orgId) {
    this.consentRequestBody = consentRequestBody;
    this.key = key;
    this.orgId = orgId;
  }

  /**
   * Constructs a <code>EstablishConsentRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/EstablishConsentRequest} obj Optional instance to populate.
   * @return {module:model/EstablishConsentRequest} The populated <code>EstablishConsentRequest</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EstablishConsentRequest();
      if (data.hasOwnProperty('consentRequestBody'))
        obj.consentRequestBody = ApiClient.convertToType(data['consentRequestBody'], Object);
      if (data.hasOwnProperty('key'))
        obj.key = ApiClient.convertToType(data['key'], 'String');
      if (data.hasOwnProperty('orgId'))
        obj.orgId = ApiClient.convertToType(data['orgId'], 'String');
    }
    return obj;
  }
}

/**
 * consents Obj structure is taken from PSD2-XS2AFramework-1.4.2.yaml
 * @member {Object} consentRequestBody
 */
EstablishConsentRequest.prototype.consentRequestBody = undefined;

/**
 * fintechId - fintech unique id
 * @member {String} key
 */
EstablishConsentRequest.prototype.key = undefined;

/**
 * orgId - The organization that we need to make the consent
 * @member {String} orgId
 */
EstablishConsentRequest.prototype.orgId = undefined;

