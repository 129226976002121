import React, { useState } from 'react'
import PropTypes from "prop-types"
import { Container, Row } from "./QNA.style"

export default function QNA({
    q,
    a
}) {

    const [showAnswer, setShowAnswer] = React.useState(false)

    return (
        <Container>
            <Row>
                <p
                    className="cursor-pointer margin-left-10"
                    onClick={() => setShowAnswer(!showAnswer)}
                >
                    {showAnswer ? "-" : "+"}
                </p>
                <p
                    className="cursor-pointer"
                    onClick={() => setShowAnswer(!showAnswer)}
                >{q}</p>
            </Row>

            {showAnswer && <p className="bg-qna">{a}</p>}

        </Container >
    )
}

QNA.propTypes = {
    q: PropTypes.string,
    a: PropTypes.string
}
