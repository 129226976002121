import { createSlice } from '@reduxjs/toolkit';

const navigationSlice = createSlice({
  name: 'navigation',
  initialState: {
    step: {},
    navigationError: false
  },
  reducers: {
    navigationSuccess(state, action) {
      state.step = action.payload.step;
      state.navigationError = false;
    },
    navigationError(state, action) {
      state.navigationError = true;
    },
  },
});

export const navigationActions = navigationSlice.actions;

export default navigationSlice;