/*
 * Consents API 
 * Manage consents for Fintech
 *
 * OpenAPI spec version: 1.0.7
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The EstablishConsentResponse model module.
 * @module model/EstablishConsentResponse
 * @version 1.0.7
 */
export class EstablishConsentResponse {
  /**
   * Constructs a new <code>EstablishConsentResponse</code>.
   * @alias module:model/EstablishConsentResponse
   * @class
   * @param redirectUrl {String} fintechId - fintech unique id
   * @param consentId {String} consentId of the consent that was established
   */
  constructor(redirectUrl, consentId) {
    this.redirectUrl = redirectUrl;
    this.consentId = consentId;
  }

  /**
   * Constructs a <code>EstablishConsentResponse</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/EstablishConsentResponse} obj Optional instance to populate.
   * @return {module:model/EstablishConsentResponse} The populated <code>EstablishConsentResponse</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EstablishConsentResponse();
      if (data.hasOwnProperty('redirectUrl'))
        obj.redirectUrl = ApiClient.convertToType(data['redirectUrl'], 'String');
      if (data.hasOwnProperty('consentId'))
        obj.consentId = ApiClient.convertToType(data['consentId'], 'String');
    }
    return obj;
  }
}

/**
 * fintechId - fintech unique id
 * @member {String} redirectUrl
 */
EstablishConsentResponse.prototype.redirectUrl = undefined;

/**
 * consentId of the consent that was established
 * @member {String} consentId
 */
EstablishConsentResponse.prototype.consentId = undefined;

