import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = props => {
  const data = {
    labels: ['API Billed', 'API Error',],
    datasets: [
      {
        label: 'Votes',
        data: props.data ? props.data : [1, 1],
        backgroundColor: ['#115DFC', '#FCB512'],
        borderColor: ['#ffffff'],
        borderWidth: 1,
      },
    ],
  };
  return (
    <Pie data={data} />
  )
};

export default PieChart;