import styled from 'styled-components';

export const FileInputWrapperStyle = styled.div`
    margin:10px 0px;
    width: ${({ width }) => width};
`;

export const FileInputInfoTextStyle = styled.div`
    font-size:10px; 
    padding:1px 10px;
`;

export const FileInputContentAreaStyle = styled.div`
    border:1px solid var(--frame-border-color);
    background-color : #ffffff; 
    padding:4px 15px;
    border-radius: 50px;
    display:flex;

    span{
        font-size:11px;
        padding: 4px 5px;
        margin-right:5px;
    }

    span.file-error{
        color:red;
    }

    span.file-success{
        color:green;
    }

    span.file-name{
        color:var(--color-font-1);
    }

    span.file-button{
        /* background-color : var(--frame-border-color); */
    }

`;