import React from 'react'
import PropTypes from "prop-types"
import { Container, Label } from "./InputColor.style"
import { Bucket } from '../../../index'

export default function InputColor({
    datatestid = "input-color",
    name,
    disabled,
    value,
    onChange,
    label,
    width = "45%",
    placeholder = "aaa"
}) {
    return (
        <Container data-testid="aaa" width={width}>
            <Label>{label}</Label>
            <input
                placeholder = {placeholder}
                type="color"
                name={name}
                disabled={disabled}
                value={value}
                onChange={onChange}
            />
            <Bucket
                size="17px"
                color="#031332"
            />
        </Container>
    )
}

InputColor.propTypes = {
    placeholder: PropTypes.string,
    datatestid: PropTypes.string,
    label: PropTypes.string,
    width: PropTypes.string,
}
