import styled from 'styled-components';

export const Container = styled.button`
    background-color: ${({ selected }) => (selected ? "#115DFC" : "#b6cdfc")};
    width: 100%;
    height: 80px;
    border: none;
    font-size: 12px;
    border-radius: 5px;
    color: ${({ selected }) => (selected ? "#fff" : "#115DFC")};
`;
