import React, { useEffect, useState } from 'react';
import { FintechAdminService } from '../../../../services/fintechAdmin/finetechAdmin/FintechAdminService';
import { ErrorDetailsResponse, ThemeResponse } from '../../../../swagger/codeGen/fintech-admin-hub/src';
import { RollingSpinner, Container, Button2, Row, Col, InputColor, InputFile, Alert } from '../../../../components';
import { useSelector, useDispatch } from 'react-redux';
import { getTheme } from '../../../../store/theme/themeSlice';
import { MAX_LOGO_SIZE, LOGO_SIZE_ALERT } from '../../../../resources/constants/EnvironmentVariables';


const initialFileInfoState = { fileName: '', errorMessage: '' };

const StylePage = () => {
    const dispatch = useDispatch();
    const { transactionId, fintechId } = useSelector((state) => state.management);
    const [stylesObject, setStylesObject] = useState({ fontColor: "", backgroundColor: "", logoUrl: "https://res.cloudinary.com/dojmo7vcc/image/upload/v1673630779/empty_zvtxeq.png" });
    const [form, setForm] = useState({ fontColor: "", backgroundColor: "", logoStream: "" });
    const [isLoading, setIsLoading] = useState(false);
    const [submitInfo, setSubmitInfo] = useState({ submitError: null, submitSuccess: null });
    const [fileInfo, setFileInfo] = useState(initialFileInfoState);
    const [tempLogo, setTempLogo] = useState(null);

    const onFileChange = (e) => {
        const reader = new FileReader();
        const readerTwo = new FileReader();
        let okToUpload = true;

        reader.onload = () => {
            const fileName = e.target.files[0].name;

            const type = e.target.files[0].type;
            if (!['image/png', 'image/jpg', 'image/jpeg'].includes(type)) {
                okToUpload = false;
                setFileInfo({ errorMessage: 'jpeg / png / jpg only', fileName });
            } else {
                setFileInfo({ errorMessage: '', fileName });
            }

            const size = e.target.files[0].size;
            if (size > MAX_LOGO_SIZE) {
                okToUpload = false;
                setTempLogo(null);
                setFileInfo({ errorMessage: LOGO_SIZE_ALERT, fileName });
            }

            if (okToUpload) { setForm({ ...form, logoStream: reader.result }) }
        }

        readerTwo.onload = () => { setTempLogo(readerTwo.result) }
        reader.readAsArrayBuffer(e.target.files[0]);
        readerTwo.readAsDataURL(e.target.files[0]);
    }

    const onInputChange = (e) => { setForm({ ...form, [e.target.name]: e.target.value }) }

    const submitForm = (event) => {
        // Update Themes on AWS S3 
        async function updateTheme() {
            try {
                const service = new FintechAdminService();
                if (form.logoStream === "") { delete form.logoStream }
                if (tempLogo) { setForm({ ...form, logoStream: tempLogo }) }

                const response = await service.updateTheme({ ...form, transactionId, fintechId });
                if (response instanceof ThemeResponse && response.logoUrl) {
                    let retVal = {} ;
                    if(form.logoStream){
                        retVal = await service.uploadImage(response.logoUrl, form.logoStream);
                    }
                    if (retVal instanceof ErrorDetailsResponse) {
                        setSubmitInfo({ ...submitInfo, submitSuccess: null, submitError: "Error Uploading File" });
                        setTimeout(() => { setSubmitInfo({ ...submitInfo, submitSuccess: null, submitError: null }) }, 3000);
                    } else {
                        setSubmitInfo({ ...submitInfo, submitError: null, submitSuccess: "Styles were Updated" });
                        setTimeout(() => { setSubmitInfo({ ...submitInfo, submitSuccess: null, submitError: null }) }, 3000);
                        fetchStyleObject();
                        dispatch(getTheme({ transactionId, fintechId }))
                    }
                }
                else {
                    setSubmitInfo({ ...submitInfo, submitSuccess: "", submitError: response.message });
                }
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                setSubmitInfo({ ...submitInfo, submitSuccess: "", submitError: error });
            }
        }

        // Execute 
        event.preventDefault();
        setIsLoading(true);
        updateTheme();
    }

    async function fetchStyleObject() {
        try {
            const service = new FintechAdminService();
            const response = await service.getStyle({ transactionId, fintechId });
            setStylesObject({ ...response });
            setForm({ ...response, logoStream: "" });
            setFileInfo(initialFileInfoState);
        } catch (error) {
        } finally { setIsLoading(false) }
    }

    useEffect(() => {
        setIsLoading(true);
        if (transactionId && fintechId) { fetchStyleObject({ transactionId, fintechId }) }
    }, []);

    return (<>
        {isLoading ? <RollingSpinner /> :
            <div>
                <div className="settings-card-title">
                    <h1>Update theme</h1>
                    <p>These theme settings will apply to your consent page</p>
                </div>

                <form onSubmit={submitForm}>
                    <Row marginBottom="30px">

                        <div className="logo-container">
                            {tempLogo ?
                                <img className='img-ratio' src={tempLogo} alt='Logo' /> :
                                <img className='img-ratio' src={stylesObject.logoUrl} alt='Logo' />
                            }
                        </div>

                        <Col alignItems="flex-start">
                            <InputFile
                                isValid={fileInfo.errorMessage === '' && fileInfo.fileName !== ''}
                                errorMessage={fileInfo.errorMessage}
                                fileName={fileInfo.fileName}
                                change={onFileChange}
                            />

                        </Col>
                    </Row>

                    <Row>
                        <InputColor name="backgroundColor" disabled={isLoading} value={form.backgroundColor} onChange={onInputChange} label="Background Color" />
                        <InputColor name="fontColor" disabled={isLoading} value={form.fontColor} onChange={onInputChange} label="Text Color" />
                    </Row>

                    <Container width="45%" padding="20px">
                        <Button2
                            fontSize="14px"
                            disabled={isLoading}
                            onClick={submitForm}
                            label="Update"
                            rounded
                            width="100%"
                        />
                    </Container>
                </form>
            </div>
        }

        {/* Messages */}
        <div>

            {submitInfo.submitSuccess && 
            <Alert backgroundColor="#fcf1d2" color="#f2c94c" message={submitInfo.submitSuccess} marginTop="20px" height="45px" />
            // <Alert backgroundColor="#D7FADC" color="#6AEB55" message={submitInfo.submitSuccess} marginTop="20px" height="45px" />
            }
            {submitInfo.submitError && <div style={{ color: "red" }}>{submitInfo.submitError}</div>}
        </div>
    </>
    );
};

export default StylePage;