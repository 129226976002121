import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Title } from '../';

const ReportsMonthlySummary = props => {
    return (
        <div>
            <Title
                paddingLeft="24px"
                justifyContent="flex-start"
                title={props.title + ': ' + props.month} />

            <Grid paddingLeft="24px">
                <div>{props.triggeredTitle}: {props.data.overallRequests ? props.data.overallRequests : 0}</div>
                <div>{props.billedTitle}: {props.data.overallBilledRequests} / {props.data.overallRequests}</div>
            </Grid>
            <Grid
                paddingLeft="24px"
                marginBottom="40px">

                <div>{props.sumTitle}: {parseFloat((props.data.overallCharge)).toPrecision(2)} $</div>
                <div>{props.apiFailedTitle}: {props.data.overallErrorRequests}</div>
            </Grid>
        </div>
    );
};

ReportsMonthlySummary.title = {
    title: PropTypes.string,
    triggeredTitle: PropTypes.string,
    data: PropTypes.object,
    billedTitle: PropTypes.string,
    sumTitle: PropTypes.string,
    apiFailedTitle: PropTypes.string,
    billedOverAll: PropTypes.string,
};

export default ReportsMonthlySummary;