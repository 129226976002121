import React from 'react';
import PropTypes from "prop-types"
import { PContainer } from "./P.style"

const P = ({
    width = "100%",
    fontSize = "16px",
    color = "#5B5D62",
    justify = "start",
    padding = "0px",
    paddingLeft = "0px",
    marginTop = "0px",
    children,
    fontFamily = "Assistant-Regular",
    fontWeight = "500",

}) => {

    return (
        <PContainer
            fontWeight={fontWeight}
            width={width}
            fontSize={fontSize}
            color={color}
            justify={justify}
            padding={padding}
            paddingLeft={paddingLeft}
            marginTop={marginTop}
            fontFamily={fontFamily}
        >
            {children}
        </PContainer>
    )
}

P.propTypes = {
    fontWeight: PropTypes.oneOf(['100', '200', '300', '400', '500', '600', '700', '800', '900']),
    width: PropTypes.string,
    fontSize: PropTypes.string,
    color: PropTypes.string,
    justify: PropTypes.oneOf(["center", "flex-start", "flex-end"]),
    padding: PropTypes.string,
    paddingLeft: PropTypes.string,
    marginTop: PropTypes.string,
    fontFamily: PropTypes.string,
}

export default P