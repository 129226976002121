import React from 'react'
import PropTypes from "prop-types"
import Icon from './Bucket.svg'
import { IMG } from './Bucket.style'

export default function Bucket({
  size = "50px",
  color = "red",
}) {
  return (
    <IMG
    color={color}
      src={Icon}
      alt="Bucket"
      size={size}
    />
  )
}

Bucket.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
}
