import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { setPage } from '../../store/webNavigation/webNavSlice';
import { Container, Button2, Col, Row, Grid, Card01 } from '../../components';
import { TesterService } from '../../services/demoTPP/tester/Tester';
import psuIdArray from './Resources/psuIdArray.js';
import { EC2_ENV, TESTER_DATA } from '../../resources/constants/EnvironmentVariables';

const Tester = () => {
    const dispatch = useDispatch();
    dispatch(setPage('ignore'));
    document.title = "Tester Page"
    const getAccessToken = new TesterService();

    const [freeze, setFreeze] = useState(false);

    const [transactionDetailsInput, setTransactionDetailsInput] = useState(null);
    const [accountDetailsInput, setAccountDetailsInput] = useState(null);
    const [transIDInput, setTransIDInput] = useState(null);
    const [accountBalancesInput, setAccountBalancesInput] = useState(null);
    const [accountTransactionsInput, setAccountTransactionsInput] = useState(null);

    const [psuId, setPsuId] = useState(psuIdArray[0].value);

     //TODO: David  ???
    const [environment, setEnvironment] = useState(TESTER_DATA[0].url);

    const [TLS, setTLS] = useState(TESTER_DATA[0].tls);
    const [URL, setUrl] = useState(TESTER_DATA[0].url);
    //TODO: David please update   HARD CODED 
    const [fintechId, setFintechId] = useState('OB:IL-BOI-500007-AIPI_TestFintech_U1');
    
    const [consentId, setConsentId] = useState(null);

    const [consentStatus, setConsentStatus] = useState('Click the button to get consent status');
    const [accountList, setAccountList] = useState('Click the button to get account list');
    const [transactionDetails, setTransactionDetails] = useState('Click the button to get transaction details');
    const [accountDetails, setAccountDetails] = useState('Click the button to get account details');
    const [accountBalances, setAccountBalances] = useState('Click the button to get account balances');
    const [accountTransactions, setAccountTransactions] = useState('Click the button to get account transactions');

    const handleResponse = (res) => {
        let message;
        if (res && res.data && res.status && res.status < 300) {
            message = res.data;
        }
        else {
            
            if (res.response.data.message) 
                { 
                    message = "Please check the server response (JSON.stringify) " //res.response.data.message 
                }
            else {
                message = res.response.data;
            }
        }
        return message;
    }

    const doGetConsent = () => {
        setFreeze(true);
        getAccessToken.getConsent(consentId, psuId, fintechId, TLS, URL).then((res) => {
            setFreeze(false);
            setConsentStatus(handleResponse(res));
        }
        )
    }

    const doGetAccountList = () => {
        setFreeze(true);
        getAccessToken.getAccountList(consentId, psuId, fintechId, TLS, URL).then((res) => {
            setFreeze(false);
            setAccountList(handleResponse(res));
        }
        )
    }

    const doGetAccountDetails = () => {
        setFreeze(true);
        getAccessToken.getAccountDetails(consentId, psuId, fintechId, accountDetailsInput, TLS, URL).then((res) => {
            setFreeze(false);
            setAccountDetails(handleResponse(res));
        }
        )
    }

    const doGetAccountBalances = () => {
        setFreeze(true);
        getAccessToken.getAccountBalances(consentId, psuId, fintechId, accountDetailsInput, TLS, URL).then((res) => {
            setFreeze(false);
            console.log('-----------------', )
            setAccountBalances(handleResponse(res));
        }
        )
    }

    const doGetAccountTransactions = () => {
        setFreeze(true);
        getAccessToken.getAccountTransactions(consentId, psuId, fintechId, accountDetailsInput, TLS, URL).then((res) => {
            setFreeze(false);
            setAccountTransactions(handleResponse(res));
        }
        )
    }

    const doGetTransactionDetails = () => {
        setFreeze(true);
        getAccessToken.getTransactionDetails(consentId, psuId, fintechId, accountDetailsInput, transIDInput, TLS, URL).then((res) => {
            setFreeze(false);
            setTransactionDetails(handleResponse(res));
        }
        )
    }

    // UI chunks
    const getConsentDemo = () => {
        return (
            <>
                <h1><b>Consent Status</b></h1>
                <Col justifyContent='flex-start'>
                    {consentStatus && <p className='font10'><b>Consent status: </b>{consentStatus}</p>}
                    <Grid>
                        <Button2 onClick={doGetConsent} size="small" rounded width="200px" label="Simulate getConsent" disable={freeze} />
                    </Grid>
                </Col>
            </>
        )
    }

    const getAccountList = () => {
        return (
            <>
                <h1><b>Account List</b></h1>
                <Col justifyContent='flex-start'>
                    {consentStatus && <p className='font10'><b>Account List: </b>{accountList}</p>}
                    <Grid>
                        <Button2 onClick={doGetAccountList} size="small" rounded width="250px" label="Simulate getAccountList" disable={freeze} />
                    </Grid>
                </Col>
            </>
        )
    }

    const getTransactionDetailsDemo = () => {
        return (
            <>
                <h1><b>Transaction Details</b></h1>
                <Col justifyContent='flex-start'>
                    {consentStatus && <p className='font10'><b>Details: </b>{transactionDetails}</p>}
                    <Grid>
                        <div>

                            <input className='input-field' type="text" onChange={(e) => setTransactionDetailsInput(e.target.value)} value={transactionDetailsInput} placeholder="accountId" />
                            <input className='input-field' type="text" onChange={(e) => setTransIDInput(e.target.value)} value={transIDInput} placeholder="TransactionID" />
                            <Button2 onClick={doGetTransactionDetails} size="small" rounded width="350px" label="Simulate getTransactionDetails" disable={freeze} />
                        </div>
                    </Grid>
                </Col>
            </>
        )
    }

    const getAccountDetailsDemo = () => {
        return (
            <>
                <h1><b>Account details, balances and Transactions</b></h1>
                <Col justifyContent='flex-start'>
                    {consentStatus && <p className='font10'><b>Details: </b>{accountDetails}</p>}
                    <Grid>
                        <div>
                            <input className='input-field' type="text" onChange={(e) => setAccountDetailsInput(e.target.value)} value={accountDetailsInput} placeholder="ResourceId" />
                            <Button2 onClick={doGetAccountDetails} size="small" rounded width="300px" label="Simulate getAccountDetails" disable={freeze} />
                        </div>
                    </Grid>
                    <div className="horizontal-line" />
                </Col>
            </>
        )
    }

    const getAccountBalancesDemo = () => {
        return (
            <>
                <Col justifyContent='flex-start'>
                    {consentStatus && <p className='font10'><b>Balances: </b>{accountBalances}</p>}
                    <Grid>
                        <div>
                            <input className='input-field' type="text" onChange={(e) => setAccountBalancesInput(e.target.value)} value={accountBalancesInput} placeholder="ResourceId" />
                            <Button2 onClick={doGetAccountBalances} size="small" rounded width="300px" label="Simulate getAccountBalances" disable={freeze} />
                        </div>
                    </Grid>
                    <div className="horizontal-line" />
                </Col>
            </>
        )
    }

    const getAccountTransactionsDemo = () => {
        return (
            <>
                <Col justifyContent='flex-start'>
                    {consentStatus && <p className='font10'><b>Transactions: </b>{accountTransactions}</p>}
                    <Grid>
                        <div>
                            <input className='input-field' type="text" onChange={(e) => setAccountTransactionsInput(e.target.value)} value={accountTransactionsInput} placeholder="ResourceId" />
                            <Button2 onClick={doGetAccountTransactions} size="small" rounded width="300px" label="Simulate getAccountTransactions" disable={freeze} />
                        </div>
                    </Grid>
                </Col>
            </>
        )
    }

    if (!EC2_ENV) {
        return <p>Please go to EC2 link, tester is not available in AMPLIFY </p>
    }
    else
        return (

            <Container backgroundColor='#eeeeee' padding="10px">
                <h1>🧪 Tester Page</h1>
                <Grid>
                    <Card01 padding="20px" paddingTop="20px">
                        <Grid alignItems="flex-start" justifyContent="flex-start" rowGap="10px">
                            <h2><b>Form</b></h2>
                            <p></p>

                            <p>Environment</p>
                            <select defaultValue={environment} name="environment" onChange={(e) => {
                                // Environment and URL are the same. I will fix it later
                                setEnvironment(TESTER_DATA[e.target.value].url)
                                setUrl(TESTER_DATA[e.target.value].url);
                                setTLS(TESTER_DATA[e.target.value].tls);
                            }
                            }>
                                {
                                    TESTER_DATA.map((item, idx) => { return <option key={idx} value={idx}>{item.name}</option> })
                                }
                            </select>

                            <p>psuId</p>
                            <select defaultValue={psuId} name="psuId" onChange={(e) => setPsuId(e.target.value)}>
                                {psuIdArray.map((psuItem) => {
                                    return <option key={psuItem.value} value={psuItem.value}>{psuItem.name}</option>
                                })}
                            </select>

                            <p>FintechId</p>
                            <input className="tpp-field" type="text" defaultValue={fintechId} onChange={(e) => { setFintechId(e.target.value) }} />

                            <p>ConsentId</p>
                            <input className="tpp-field" type="text" defaultValue={consentId} onChange={(e) => { setConsentId(e.target.value) }} />
                        </Grid>
                    </Card01>

                    <Card01 padding="20px" paddingTop="20px">
                        <Grid columns={1} alignItems="flex-start" rowGap="14px">
                            <h2><b>Summary</b></h2>
                            <p>env: {environment}</p>
                            <p>psuId: {psuId}</p>
                            <p>fintehcId: {fintechId}</p>
                            <p>consentId: {consentId}</p>
                        </Grid>

                    </Card01>
                </Grid>

                <Card01 padding="20px" paddingTop="20px" marginTop="20px">
                    {getConsentDemo()}
                </Card01>

                <Card01 padding="20px" paddingTop="20px" marginTop="20px">
                    {getAccountList()}
                </Card01>


                <Card01 padding="20px" paddingTop="20px" marginTop="20px">
                    {getAccountDetailsDemo()}
                    {getAccountBalancesDemo()}
                    {getAccountTransactionsDemo()}
                </Card01>

                <Card01 padding="20px" paddingTop="20px" marginTop="20px">
                    {getTransactionDetailsDemo()}
                </Card01>

            </ Container >
        )
}

export default Tester