import React from 'react';
import PropTypes from "prop-types"
import { Container } from './PageContent.style';

const PageContent = ({
    children,
    bgImage,
    alignItems = 'center',
    justifyContent = 'center'
}) => {

    return (
        <Container
            bgImage={bgImage}
            alignItems={alignItems}
            justifyContent={justifyContent}
        >
            {children}
        </Container>
    );
};

PageContent.propTypes = {
    children: PropTypes.node,
    bgImage: PropTypes.string,
    alignItems: PropTypes.string,
    justifyContent: PropTypes.string
};

export default PageContent;