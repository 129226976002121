import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 245px;
  background-color: var(--sidebar-bg);
  padding: 15px 0px 15px 0px;
`

export const Container2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 245px;
`

