/*
 * billing Reports API 
 * Fintech billing reports
 *
 * OpenAPI spec version: 1.0.5
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.37
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {MonthlyReport} from './MonthlyReport';

/**
 * The BillingReportsCompareResponse model module.
 * @module model/BillingReportsCompareResponse
 * @version 1.0.5
 */
export class BillingReportsCompareResponse {
  /**
   * Constructs a new <code>BillingReportsCompareResponse</code>.
   * @alias module:model/BillingReportsCompareResponse
   * @class
   * @param firstMonth {module:model/MonthlyReport} 
   * @param secondMonth {module:model/MonthlyReport} 
   */
  constructor(firstMonth, secondMonth) {
    this.firstMonth = firstMonth;
    this.secondMonth = secondMonth;
  }

  /**
   * Constructs a <code>BillingReportsCompareResponse</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/BillingReportsCompareResponse} obj Optional instance to populate.
   * @return {module:model/BillingReportsCompareResponse} The populated <code>BillingReportsCompareResponse</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new BillingReportsCompareResponse();
      if (data.hasOwnProperty('firstMonth'))
        obj.firstMonth = MonthlyReport.constructFromObject(data['firstMonth']);
      if (data.hasOwnProperty('secondMonth'))
        obj.secondMonth = MonthlyReport.constructFromObject(data['secondMonth']);
    }
    return obj;
  }
}

/**
 * @member {module:model/MonthlyReport} firstMonth
 */
BillingReportsCompareResponse.prototype.firstMonth = undefined;

/**
 * @member {module:model/MonthlyReport} secondMonth
 */
BillingReportsCompareResponse.prototype.secondMonth = undefined;

