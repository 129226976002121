import styled from 'styled-components';

export const HeaderContainer = styled.header`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 70px;
    background-color: #ffffff;
    padding-left: 0px;

    p {
        color: #888888;
        font-size: 10px;
    }

    @media (max-width: 768px) {
        height: 100%;
    }
`;

export const LogoContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 10px;

    p{
        color: #ffffff;
        font-size: 22px;
        font-weight: 600;
        margin: 0;
        margin-left: 10px;
        font-family: 'Assistant-Regular';
        display: inline;
    }
    border-bottom: 1px solid #43464B;
    background-color: #031332;
    min-width: 245px;
    height: 70px;

`