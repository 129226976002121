/*
 * upload and validate certificates
 * upload and validate certificates
 *
 * OpenAPI spec version: 1.0.3
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.35
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {CertificateItem} from './CertificateItem';

/**
 * The UploadCertificateRequest model module.
 * @module model/UploadCertificateRequest
 * @version 1.0.3
 */
export class UploadCertificateRequest {
  /**
   * Constructs a new <code>UploadCertificateRequest</code>.
   * @alias module:model/UploadCertificateRequest
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>UploadCertificateRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/UploadCertificateRequest} obj Optional instance to populate.
   * @return {module:model/UploadCertificateRequest} The populated <code>UploadCertificateRequest</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new UploadCertificateRequest();
      if (data.hasOwnProperty('certificateItems'))
        obj.certificateItems = ApiClient.convertToType(data['certificateItems'], [CertificateItem]);
    }
    return obj;
  }
}

/**
 * @member {Array.<module:model/CertificateItem>} certificateItems
 */
UploadCertificateRequest.prototype.certificateItems = undefined;

