import React, { useEffect, useState } from 'react';

import MaskedInput from 'react-input-mask';


const AccountInput = ({ orgInputName,
    maskPattern = [
        { mask: "9999 9999 9999 9999", maskCheck: "^4$", accountInputPattern: "^4[0-9]{12}(?:[0-9]{3})?$", name: "visa" },
        { mask: "9999 9999 9999 9999", maskCheck: "^5[1-5]$", accountInputPattern: "^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}", name: "mastercard" },
        { mask: "9999 999999 9999", maskCheck: "^3[47]", accountInputPattern: "^3[47][0-9]{12}$", name: "amex" }

    ]
    , setIsValidAccountNumber,
    accountNumber,
    setAccountNumber,
    disabled }) => {

    function isIBAN(str) { return /^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/.test(str) }
    const isNumber = (str) => { return /^\d+$/.test(str) }
    const isChar = (str) => { return /^[a-zA-Z]+$/.test(str) }

    const IBANMask = (e) => {
        const val = e.replaceAll(" ", "").replaceAll("_", "");
        setAccountNumber(val);
    }

    const isMasked = (str) => {
        return str.length > 1 && str.length < 24 && isNumber(str.slice(-1))
    }

    useEffect(() => {
        setIsValidAccountNumber(new RegExp(regex).test(accountNumber));
        maskPattern.forEach(item => {
            if (new RegExp(item.maskCheck).test(accountNumber)) {
                setMask(item.mask);
                setRegex(item.accountInputPattern);
                return;
            }
        });
    }, [accountNumber]);

    const [mask, setMask] = useState(maskPattern[0].mask);
    const [regex, setRegex] = useState(maskPattern[0].mask);

    const inputsDictionary = {
        "maskedPan": {
            "name": "Masked",
            "validate": (e) => {

                const val = e.replaceAll(" ", "").replaceAll("_", "");
                setAccountNumber(val);
                // if (val.length < 3 && isNumber(val.slice(-1))) { setAccountNumber(val) }
                // if (val.length > 2 && val.length < 24 && isNumber(val.slice(-1))) { setAccountNumber(val) }
                // if (val.length === 0) { setAccountNumber('') }
            },
            "clickable": isMasked,

        },
        "iban": {
            "name": "Iban",
            "validate": IBANMask,
            "clickable": isIBAN,

        }
    }


    return (
        <div>
            <MaskedInput
                alwaysShowMask
                disabled={disabled}
                mask={mask} name="card" size="30"
                type="text"
                required
                onChange={e => {
                    inputsDictionary[orgInputName].validate(e.target.value);
                }
                }
            />


        </div>
    );
};

export default AccountInput;