export function getCurrentDate(separator='-', dateObj){

    let newDate = new Date();
    if(dateObj){
        newDate= dateObj;
    }
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date<10 ? `0${date}` : `${date}`}`
}

export function getDateDisplay(separator='-', dateObj){

    let newDate = new Date();
    if(dateObj){
        newDate= dateObj;
    }
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${date<10 ? `0${date}` : `${date}`}${separator}${month<10?`0${month}`:`${month}`}${separator}${year}`
}

export function getOneYearFromNow(separator='-', dateObj){

    let newDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1))
    if(dateObj){
        newDate= dateObj;
    }
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date<10 ? `0${date}` : `${date}`}`
}
