import { createSlice } from '@reduxjs/toolkit';

const filter = createSlice({
  name: 'filter',
  initialState: {
    range: null
  },
  reducers: {
    setFilter: (state, action) => { state.range = action.payload; },
  }
})

export const { setFilter } = filter.actions;

export default filter.reducer;