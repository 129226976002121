/*
 * billing Reports API 
 * Fintech billing reports
 *
 * OpenAPI spec version: 1.0.5
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.37
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {ReportsDetailsRecords} from './ReportsDetailsRecords';

/**
 * The BillingReportsDetailsResponse model module.
 * @module model/BillingReportsDetailsResponse
 * @version 1.0.5
 */
export class BillingReportsDetailsResponse {
  /**
   * Constructs a new <code>BillingReportsDetailsResponse</code>.
   * @alias module:model/BillingReportsDetailsResponse
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>BillingReportsDetailsResponse</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/BillingReportsDetailsResponse} obj Optional instance to populate.
   * @return {module:model/BillingReportsDetailsResponse} The populated <code>BillingReportsDetailsResponse</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new BillingReportsDetailsResponse();
      if (data.hasOwnProperty('reportsDetailsRecords'))
        obj.reportsDetailsRecords = ReportsDetailsRecords.constructFromObject(data['reportsDetailsRecords']);
      if (data.hasOwnProperty('pages'))
        obj.pages = ApiClient.convertToType(data['pages'], 'Number');
      if (data.hasOwnProperty('currentPage'))
        obj.currentPage = ApiClient.convertToType(data['currentPage'], 'Number');
      if (data.hasOwnProperty('totalItems'))
        obj.totalItems = ApiClient.convertToType(data['totalItems'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {module:model/ReportsDetailsRecords} reportsDetailsRecords
 */
BillingReportsDetailsResponse.prototype.reportsDetailsRecords = undefined;

/**
 * @member {Number} pages
 */
BillingReportsDetailsResponse.prototype.pages = undefined;

/**
 * @member {Number} currentPage
 */
BillingReportsDetailsResponse.prototype.currentPage = undefined;

/**
 * @member {Number} totalItems
 */
BillingReportsDetailsResponse.prototype.totalItems = undefined;

