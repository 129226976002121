/*
 * Consents API 
 * Manage consents for Fintech
 *
 * OpenAPI spec version: 1.0.7
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The ConsentActivationResponse model module.
 * @module model/ConsentActivationResponse
 * @version 1.0.7
 */
export class ConsentActivationResponse {
  /**
   * Constructs a new <code>ConsentActivationResponse</code>.
   * @alias module:model/ConsentActivationResponse
   * @class
   * @param fintechId {String} fintechId - fintech unique id
   * @param psuId {String} psuId string for fintech user ID number
   * @param bankId {String} bankId - fintech unique id
   * @param consentId {String} consentId of the consent that was established
   */
  constructor(fintechId, psuId, bankId, consentId) {
    this.fintechId = fintechId;
    this.psuId = psuId;
    this.bankId = bankId;
    this.consentId = consentId;
  }

  /**
   * Constructs a <code>ConsentActivationResponse</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ConsentActivationResponse} obj Optional instance to populate.
   * @return {module:model/ConsentActivationResponse} The populated <code>ConsentActivationResponse</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ConsentActivationResponse();
      if (data.hasOwnProperty('fintechId'))
        obj.fintechId = ApiClient.convertToType(data['fintechId'], 'String');
      if (data.hasOwnProperty('psuId'))
        obj.psuId = ApiClient.convertToType(data['psuId'], 'String');
      if (data.hasOwnProperty('bankId'))
        obj.bankId = ApiClient.convertToType(data['bankId'], 'String');
      if (data.hasOwnProperty('consentId'))
        obj.consentId = ApiClient.convertToType(data['consentId'], 'String');
    }
    return obj;
  }
}

/**
 * fintechId - fintech unique id
 * @member {String} fintechId
 */
ConsentActivationResponse.prototype.fintechId = undefined;

/**
 * psuId string for fintech user ID number
 * @member {String} psuId
 */
ConsentActivationResponse.prototype.psuId = undefined;

/**
 * bankId - fintech unique id
 * @member {String} bankId
 */
ConsentActivationResponse.prototype.bankId = undefined;

/**
 * consentId of the consent that was established
 * @member {String} consentId
 */
ConsentActivationResponse.prototype.consentId = undefined;

