import styled from 'styled-components';

export const ButtonContainer = styled.button`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border: 1px solid ${({ color }) => color} !important;
  border-radius: ${({ rounded }) => (rounded ? '50px' : '0')} !important;
  background-color: ${({ backgroundColor, disable }) => (disable ? '#f2f2f2' : backgroundColor)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${({ margin }) => margin};
  color: ${({ disable }) => (disable ? '#bebebe' : '#115DFC')};
  cursor: pointer;
  margin-top: ${({ marginTop }) => marginTop};
  padding-right: 20px !important;
  padding-left: 20px !important;

  font-family: var(--font-family-main);
  font-size: 18px;
  font-weight: 400;
  :hover {
      opacity: 0.85;
      cursor : ${({ disable }) => (disable ? 'not-allowed' : 'pointer')};
    }

`;
