import React from 'react';
import { useSelector } from 'react-redux';
import { Card01, PageContent, Row, InfoText, Button2 } from '../../../components/index';
import { useNavigate } from 'react-router';

const WelcomeUser = () => {
    const fintechId = useSelector((state) => state.registration.fintechId);
    const navigateTo = useNavigate();

    return (
        <div className='row'>

            <PageContent bgImage="https://res.cloudinary.com/dojmo7vcc/image/upload/v1671286848/OBH-BG_o2jm1r.jpg">
                <div className='h-center'>
                    <Card01 radius={24} minWidth="300px" padding="55px">
                        <Row>
                            <img src="https://res.cloudinary.com/dojmo7vcc/image/upload/v1668349284/check_qcgxjx.gif" alt="success" />
                        </Row>
                        <Row><h1>📧 Open your email...</h1></Row>
                        <p>Click the verification link in the email</p>
                        <InfoText step='Fintech ID:' infoText={fintechId} />

                        <Button2
                            marginTop='55px'
                            backgroundColor={"#3cb030"}
                            label={'Done, go to login'}
                            fontSize='18px'
                            rounded={true}
                            size='medium'
                            width='100%'
                            onClick={() => { navigateTo('/') }}
                        />
                    </ Card01 >
                </div>
            </PageContent>
        </div>
    );
};
export default WelcomeUser;