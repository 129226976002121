import styled from "styled-components";

export const SpinnerContainer = styled.div`
    display: grid;
    justify-content: center;
    align-items: center;
    height: 100px;
`;

export const SpinnerLoader = styled.div`
    @keyframes spinner {
        0% {
        transform: rotate(0deg);
        }
        100% {
        transform: rotate(360deg);
        }
    }

    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #3cb030; /* Blue */
    border-radius: 50%;
    animation: spinner .3s linear infinite;


`;