import React from 'react';
import PropTypes from "prop-types"
import { SelectContainer } from "./Select.style"

const Select = ({
    name,
    type,
    onChange,
    value,
    placeholder,
    label,
    disabled,
    children

}) => {
    return (
        <SelectContainer
            name={name}
            type={type}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            label={label}
            disabled={disabled}
        >
            {children}
        </SelectContainer>
    )
}

Select.propTypes = {
    name: PropTypes.string,
    type: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
}

export default Select