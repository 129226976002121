import React, { useEffect, useState } from 'react';

import { getOneYearFromNow } from '../../componentsTools/commonFunction/commonFunctions';
import { EstablishConsentService } from '../../services/consent/establishConsent/EstablishConsentService';
import { Button2, ButtonGroup, Row, Grid, SingleToggle, Col, Popup, Card01 } from '../index';
import DedicatedBuilder from '../DedicatedBuilder/DedicatedBuilder';
import { consentTypes } from '../../BusinessBlocks/Common/CommonHelper';
import { useNavigate } from 'react-router';
import { OrganizationDetails } from '../../swagger/codeGen/consent-routes/src/model/OrganizationDetails';
import { ConsentPickerHelper } from '../../BusinessBlocks/Components/ConsentPickerHelper';
import { restrictedTo } from '../../views/consent/CreateAndRedirectToBank/const';
const originalAccess = { accounts: true, transactions: true, balances: true, };

const ConsentPicker = ({ keyParam, setLinkToBank, setStep, bank, orgDetails, setOrgId }) => {
    const originalDedicatedAccess = { accounts: [], transactions: [], balances: [], };
    const [freezeButton, setFreezeButton] = useState(false);
    const navigate = useNavigate();
    const [showPopUp, setShowPopUp] = useState(false);
    const [consent, setConsent] = useState({
        validUntil: getOneYearFromNow('-'),
        orgId: parseInt(bank),
        key: keyParam
    });

    const [restrictedState, setRestrictedState] = useState({
        [restrictedTo.CASH]: true,
        [restrictedTo.SAVINGS]: true,
        [restrictedTo.CARD]: true,
        [restrictedTo.LOAN]: true
    });

    const buildAccesKey = () => {
        let accessObject = {};
        if (selectedType === consentTypes.DEDICATED) {
            for (const key in dedicatedAccess) {
                if (dedicatedAccess[key].length > 0) {
                    accessObject[key] = dedicatedAccess[key];
                }
            }
            return accessObject;
        }
        for (const key in access) {
            if (access[key]) {
                if (Object.keys(originalAccess).includes(key)) {
                    accessObject[key] = [];
                } else {
                    accessObject[key] = access[key];
                }
            }
        }

        let restrictedTo = [];
        for (const key in restrictedState) {
            if (restrictedState[key]) {
                restrictedTo.push(key);
            }
        }

        accessObject = { ...accessObject, restrictedTo };
        return accessObject;
    }

    const additional = { recurringIndicator: true, frequencyPerDay: 100 };
    const [dedicatedAccess, setDedicatedAccess] = useState({ ...originalDedicatedAccess });
    const [access, setAccess] = useState({ ...originalAccess });
    const [selectedType, setSelectedType] = useState(consentTypes.BANK_OFFER);
    const onChangeDate = (e) => { setConsent({ ...consent, validUntil: e.target.value }) }

    const onEstablishConsent = async (e) => {
        e.preventDefault();

        if (ConsentPickerHelper.approvedButton(selectedType, freezeButton, { dedicatedAccess, access })) {
            setFreezeButton(true);
            try {
                const establishConsentService = new EstablishConsentService();
                const response = await establishConsentService.establishConsent({
                    ...consent,
                    type: selectedType,
                    access: buildAccesKey(),
                    ...additional
                },
                    orgDetails.psdVersion);
                const data = JSON.parse(response.data);
                if (data.redirectUrl) {
                    setLinkToBank(data.redirectUrl); setStep(3);
                } else {
                    setShowPopUp(true)
                }

            } catch (error) {
                // setErrorMesage('Ooops... we have some problem. Please try again later.');
                // setTimeout(() => { setErrorMesage(null); navigate('/tpp'); }, 2000);
            } finally { setFreezeButton(false) }
        }
    }

    useEffect(() => {
        if ([consentTypes.AVAILABLE_ACCOUNTS, consentTypes.AVAILABLE_ACCOUNTS_WITH_BALANCE].includes(selectedType)) {
            const newAccess = Object.keys(access).reduce((accumulator, key) => {
                return { ...accumulator, [key]: false };
            }, {});
            setAccess({ ...newAccess, [selectedType]: consentTypes.AVAILABLE_ACCOUNTS });
        } else {
            setAccess({ ...originalAccess });

            //TODO: Ben remove cookie OrgID
        }
    }, [selectedType]);

    const onChangeAccess = (e) => { setAccess({ ...access, [e.target.value]: e.target.checked }) };

    const onChangeRestricted = (e) => { setRestrictedState({ ...restrictedState, [e.target.value]: e.target.checked }) };

    const renderer = () => {
        switch (selectedType) {
            case consentTypes.BANK_OFFER: // תמהיל
                return <ActionsTypes />;
            case consentTypes.DEDICATED: // ספציפי - חשבונות מוגדרים
                return <DedicatedBuilder accountInputPattern={orgDetails.accountInputPattern} orgInputName={orgDetails.organizationType === OrganizationDetails.OrganizationTypeEnum.CC ? "maskedPan" : 'iban'} dedicatedAccess={dedicatedAccess} setDedicatedAccess={setDedicatedAccess} />;
            default:
                return <></>;
        }
    }

    const ActionsTypes = () => {
        return <div className='ltr'>
            <Row className='ltr' backgroundColor='#eeeeee' marginTop='10px'>סלי מידע</Row>
            <Grid columns={2} marginBottom="20px" height="100px" backgroundColor={"#dddddd"} paddingRight="10px">
                <div></div>
                <Col columns={3} alignItems="flex-end">

                    <label>
                        <p className="consent-p">חשבונות</p>
                        <input value={"accounts"} onChange={onChangeAccess} checked={access.accounts} type="checkbox" />
                    </label>
                    <div>
                        <p className="consent-p">יתרות</p>
                        <input value={"balances"} onChange={onChangeAccess} checked={access.balances} type="checkbox" />
                    </div>
                    <div>
                        <p className="consent-p">תנועות</p>
                        <input value={"transactions"} onChange={onChangeAccess} checked={access.transactions} type="checkbox" />
                    </div>
                </Col>
            </Grid>
            {
                orgDetails.organizationType !== 'Bureau' &&
                <>
                    <Row className='ltr' backgroundColor='#eeeeee'>סוג חשבון</Row>
                    <Row className='ltr' backgroundColor='#dddddd' padding="10px">
                        <Col columns={3} alignItems="flex-end">
                            {orgDetails.organizationType === 'Bank' &&
                                <label>
                                    <p className="consent-p">עו״ש</p>
                                    <input value={restrictedTo.CASH} onChange={onChangeRestricted} checked={restrictedState[restrictedTo.CASH]} type="checkbox" />
                                </label>
                            }

                            {orgDetails.organizationType === 'Bank' &&
                                <div>
                                    <p className="consent-p">חסכונות</p>
                                    <input value={[restrictedTo.SAVINGS]} onChange={onChangeRestricted} checked={restrictedState[restrictedTo.SAVINGS]} type="checkbox" />
                                </div>
                            }

                            <div>
                                <p className="consent-p">כרטיסים</p>
                                <input value={[restrictedTo.CARD]} onChange={onChangeRestricted} checked={restrictedState[restrictedTo.CARD]} type="checkbox" />
                            </div>

                            <div>
                                <p className="consent-p">אשראי</p>
                                <input value={[restrictedTo.LOAN]} onChange={onChangeRestricted} checked={restrictedState[restrictedTo.LOAN]} type="checkbox" />
                            </div>
                        </Col>
                    </Row>
                </>
            }
        </div>
    }

    const consenTypeRenderer = () => {
        switch (orgDetails.organizationType) {
            case OrganizationDetails.OrganizationTypeEnum.bureau:
                return <Grid columns={1} columnGap="5px" >
                    <SingleToggle isSelect={selectedType === consentTypes.BANK_OFFER} text="תמהיל מידע לכל החשבונות" onClick={() => setSelectedType(consentTypes.BANK_OFFER)} />
                </Grid>;
            case OrganizationDetails.OrganizationTypeEnum.CC:
                return <Grid columns={2} columnGap="5px" >
                    <SingleToggle isSelect={selectedType === consentTypes.BANK_OFFER} text="תמהיל מידע לכל החשבונות" onClick={() => setSelectedType(consentTypes.BANK_OFFER)} />
                    <SingleToggle isSelect={selectedType === consentTypes.DEDICATED} text="חשבונות מוגדרים" onClick={() => setSelectedType(consentTypes.DEDICATED)} />
                </Grid>;
            case OrganizationDetails.OrganizationTypeEnum.bank:
                return <Grid columns={2} columnGap="5px" >
                    <SingleToggle isSelect={selectedType === consentTypes.BANK_OFFER} text="תמהיל מידע לכל החשבונות" onClick={() => setSelectedType(consentTypes.BANK_OFFER)} />
                    <SingleToggle isSelect={selectedType === consentTypes.DEDICATED} text="חשבונות מוגדרים" onClick={() => setSelectedType(consentTypes.DEDICATED)} />
                </Grid>
            default:
                return <></>;
        }
    }

    return (
        <>
            {consenTypeRenderer()}

            <Popup show={showPopUp}>
                <Card01 padding="20px" paddingTop='20px'>
                    <Col>
                        <h1 style={{ color: "var(--warning-red)" }}>שגיאה</h1>
                        <p>יש בעיה ביצירת ההסכמה, אנו נסו שוב מאוחר יותר</p>

                        <Button2
                            onClick={() => { setShowPopUp(false); navigate('/tpp') }}
                            label="סגור"
                            rounded
                            size="medium"
                        />
                    </Col>
                </Card01>
            </Popup>

            <form onSubmit={onEstablishConsent}>
                <div className="heb-font">
                    <Row marginTop='20px' backgroundColor='#eeeeee' padding="2px">
                        <Col alignItems="start"  paddingRight="4px">
                            <p className='p-no-margin'><b>עבור: </b></p>
                            <p className='p-no-margin'>{orgDetails.name}</p>
                        </Col>

                        <Col width="300px" alignItems="flex-start" backgroundColor='#ffffff' paddingRight="4px">
                            <p className='p-no-margin'>תוקף ההסכמה</p>
                            <input
                                Style={"border: 0px"}
                                id={"validUntil"}
                                min={ConsentPickerHelper.minConsentCalendarDate()}
                                max={ConsentPickerHelper.maxConsentCalendarDate()}
                                value={consent.validUntil}
                                onChange={onChangeDate}
                                name={"validUntil"}
                                type={"date"}
                            />
                        </Col>
                    </Row>

                    {renderer()}

                </div>

                <ButtonGroup>
                    <Button2
                        rounded
                        marginTop="10px"
                        size='medium'
                        disable={!ConsentPickerHelper.approvedButton(selectedType, freezeButton, { dedicatedAccess, access })}
                        onClick={onEstablishConsent}
                        label='המשך'
                    />
                    <Button2
                        marginTop="10px"
                        size='medium'
                        onClick={() => {
                            setOrgId(null);
                            setStep(1)
                        }
                        }
                        className='cancel'
                        label='אחורה'
                    />
                </ButtonGroup>
            </form>
        </>
    );
};

export default ConsentPicker;