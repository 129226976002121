import React from 'react';
import PropTypes from "prop-types"
import { GridContainer } from "./Grid.style"

const Grid = ({
    children,
    columns = 2,
    rows,
    columnGap = "40px",
    rowGap,
    backgroundColor,
    width = "100%",
    height,
    marginBottom,
    marginTop,
    paddingLeft,
    paddingRight,
    ...props

}) => {

    return (
        <GridContainer
            columns={columns}
            rows={rows}
            columnGap={columnGap}
            rowGap={rowGap}
            backgroundColor={backgroundColor}
            height={height}
            width={width}
            marginBottom={marginBottom}
            marginTop={marginTop}
            paddingLeft={paddingLeft}
            paddingRight={paddingRight}
            padding={props.padding}
        >
            {children}
        </GridContainer>
    )
}

Grid.propTypes = {
    children: PropTypes.node.isRequired,
    columns: PropTypes.number,
    rows: PropTypes.string,
    columnGap: PropTypes.string,
    rowGap: PropTypes.string,
    backgroundColor: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    marginBottom: PropTypes.string,
    marginTop: PropTypes.string,
    paddingLeft: PropTypes.string,
    paddingRight: PropTypes.string,
}

export default Grid