import styled from 'styled-components'


const ButtonGroupStyle = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0.7em 0em;
  button{
    padding: 10px;
    border-width: 0px;
    font-weight: 600;
    border-radius: 5px;
    font-size: 1.05em;
    cursor:pointer;
  }
  button.cancel{
    background-color: grey;
  }
  button.continue{
    background-color: green;
    color : white
  }
  button:disabled{
    background-color: lightgrey;
    cursor:auto;
  }
`;


export { ButtonGroupStyle};