export default class Logger {
    static mode = "errors";
    static getMode() { return Logger.mode }
    static setMode(mode) {
        Logger.mode = mode
    }

    /**
     * 
     * @param {*} envObj 
     */
    constructor(envObj) {
        const { env } = envObj;
        this.env = env;
    }

    /*
      *  debug function
      * @param {*} funcName       
      * @param {*} msg 
      * @returns 
   */
    debug(funcName, msg) {
        if (Logger.mode === "debug") {
            if (this.env == "prod") return;
            if (!msg) return;
            msg = `${funcName} - ${msg}`;
            console.log(msg)
        }
    }

    /*
      *  error function
      * @param {*} funcName 
      * @param {*} error_msg 
      */
    error(funcName, msg) {
        if (Logger.mode === "errors") {
            if (!msg) return;
            msg = `CRITICAL ERROR: ${funcName} - ${msg} `;
            console.error(msg)
        }
    }

    /*
    *  audit function
    * @param {*} funcName 
    * @param {*} dt 
    */
    audit(funcName, dt) {
        if (Logger.mode === "debug") {
            if (this.env == "prod") return;
            let now = new Date();
            let executionTime = now.getTime() - dt.getTime();
            let auditMsg = `Execution time ${funcName} - ${executionTime} Milliseconds `;
            console.log(auditMsg);
        }
    }
}
