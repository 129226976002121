import { configureStore } from '@reduxjs/toolkit';

import registrationSlice from './registration/registrationSlice';
import navigationSlice from './navigation/navigationSlice';
import managementSlice from './management/managementSlice';
import webNav from './webNavigation/webNavSlice';
import loggedUser from './loggedUser/loggedUserSlice';
import theme from './theme/themeSlice';
import filter from './filter/filterSlice';

const store = configureStore({
  reducer: {
    registration: registrationSlice.reducer,
    navigation: navigationSlice.reducer,
    management: managementSlice.reducer,
    webNav,
    loggedUser,
    theme,
    filter
  },
});

export default store;