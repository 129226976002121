import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const LineSeparatorStyle = styled.div`
    display: flex;
    flex-direction: row;
    margin: 15px 0px;
    margin-top: ${({ marginTop }) => marginTop};
    font-size: 1rem;
    color: var(--frame-border-color);
    font-size:11px;
    ::after, ::before{
        content: "";
        flex: 1 1;
        border-bottom: 1px solid var(--frame-border-color);
        margin: auto; 
    }
`;

const LineSeparator = props => {
    return (
        <LineSeparatorStyle
            marginTop={props.marginTop}
        >
            {props.text}
        </LineSeparatorStyle>
    );
};

LineSeparator.propTypes = {
    text: PropTypes.string,
};

export default LineSeparator;