import { API } from "aws-amplify";


export default class AmplifyConfig   {
    /**
     * 
     * @param {*} env 
     */
    constructor(envObj, transactionId, fintechId) {

        const { env, timeout } = envObj;
        
        this.timeout = timeout;
        this.logName = `${this.constructor.name} CLASS`;  
        this.transactionId = transactionId; 
        this.fintechId = fintechId;
    }

    /**
     * 
     * @param {*} body  ( only for post , put )
     * @param {*} response 
     * @returns 
     */
    setRequest ( body ='' , response = true  ) {

        return {

            response: response,

            body,

            headers: {
                "Accept": "application/json",
                'X-Transaction-Id':  this.transactionId, 'X-Fintech-Id': this.fintechId
            }
        }

    }

    /**
     * 
     * @param {*} apiName 
     * @returns 
     */
    async getApiGwEndpoint( apiName ) {

        let retVal = { error:true, errorMessage:"I'm not lucky" , url:''}

        try {
            
            const url =  await API.endpoint(apiName);      
            return { error:false, errorMessage:"" , url};

        } catch (error) {
            return { error:true, errorMessage: error  , url:''};
        }

      
    }

}