import React from 'react';
import PropTypes from 'prop-types';
import { Container, H1, Strip } from './SectionContainer.style';

const SectionContainer = ({
    width = "100%",
    marginTop = "0px",
    title,
    children
}) => {

    return (
        <Container width={width} marginTop={marginTop}>
            <Strip>
                <H1>{title}</H1>
            </Strip>
            {children}
        </Container>
    );
};

SectionContainer.propTypes = {
    width: PropTypes.string,
    marginTop: PropTypes.string
};

export default SectionContainer;