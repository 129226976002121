import styled from 'styled-components';

export const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    width: 100%;
    
    /* margin-top: 50px; */

    p {
        color: #888888;
        font-size: 13px;
    }

`;

export const TableHeader = styled.div`
    display: grid;
    grid-template-columns: 120px 1fr;
    grid-gap: 10px;
    width: 100%;
    margin-bottom: 20px;
    font-weight: 800;
    p {
        color: #444;
        font-size: 13px;
    }
`;

export const TableBody = styled.div`
    display: grid;
    grid-template-columns: 120px 1fr;
    grid-template-rows: 57px;
    grid-gap: 10px;
    width: 100%;
`